import { jobsType } from '../type';
import {
  getCurrentJobListAPI,
  getClosedJobListAPI,
  getJobAPI,
  updateJobAPI,
  createJobAPI,
  getJobsStatusList,
  getMustEducationList,
  getMustExperienceList,
} from '../../services/jobs';

const getCurrentList = () => {
  return (dispatch) => {
    return getCurrentJobListAPI()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOBS,
          jobs: data.data
        });
      }).catch(error => {})
  }
};

const getClosedJobList = () => {
  return (dispatch) => {
    return getClosedJobListAPI()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOBS,
          jobs: data.data
        });
      }).catch(error => {})
  }
};

// const getList = () => {
//   return (dispatch) => {
//     return getJobsAPI()
//       .then((data) => {
//         return dispatch({
//           type: jobsType.GET_JOBS,
//           jobs: data.data
//         });
//       }).catch(error => {})
//   }
// };

const getStatusList = () => {
  return (dispatch) => {
    return getJobsStatusList()
    .then((data) => {
      return dispatch({
        type: jobsType.GET_JOB_STATUS_LIST,
        data: data.data
      });
    }).catch(error => {})
  }
};

const getJob = (id) => {
  return getJobAPI(id)
    .then((data) => {
      return data.data
    }).catch(error => { })
}

const updateJob = (id, param) => {
  return (dispatch) => updateJobAPI(id, param)
    .then((data) => {
      return dispatch({
        type: jobsType.UPDATE_JOB,
        data: data.data
      });
    }).catch(error => {
      console.log('error', error)
    })
}

const createJob = (param) => {
  return createJobAPI(param)
    .then((data) => {
      return data.data
    }).catch(error => {
      console.error(error)
      throw error
    })
}

const getAdminMustHaveEducationList = () => {
  return (dispatch) => {
    return getMustEducationList()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOB_MUST_HAVE_EDUCATION_LIST,
          data: data.data
        });
      }).catch(error => {})
  }
};

const getAdminMustHaveExperienceList = () => {
  return (dispatch) => {
    return getMustExperienceList()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOB_MUST_HAVE_EXPERIENCE_LIST,
          data: data.data
        });
      }).catch(error => {})
  }
};

export {
  getCurrentList,
  getClosedJobList,
  getJob,
  updateJob,
  createJob,
  getStatusList,
  getAdminMustHaveEducationList,
  getAdminMustHaveExperienceList,
}
