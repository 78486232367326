import DashboardPage from '../views/dashboard/DashboardPage';
import UserProfilePage from '../views/dashboard/UserProfilePage';
import ApplicantNewPage from '../views/dashboard/applicants/ApplicantNewPage';
import ApplicantListPage from '../views/dashboard/applicants/ApplicantListPage';
import ApplicantViewPage from '../views/dashboard/applicants/ApplicantViewPage';
import ApplicantSavedPage from '../views/dashboard/applicants/ApplicantSavedPage';
import ApplicationListPage from '../views/dashboard/applications/ApplicationListPage';
import ApplicationArchivePage from '../views/dashboard/applications/ApplicationArchivePage';
import ApplicationViewPage from '../views/dashboard/applications/ApplicationViewPage';

import HeadHuntingPage from '../views/dashboard/headhunting/HeadHuntingPage'
import HeadHuntingListPage from '../views/dashboard/headhunting/HeadHuntingListPage'
import JobListPage from '../views/dashboard/jobs/JobListPage';
import JobNewPage from '../views/dashboard/jobs/JobNewPage';
import JobViewPage from '../views/dashboard/jobs/JobViewPage';
import FAQPage from '../views/dashboard/FAQPage';
import PlacingAPlatinumTickTMAdPage from '../views/dashboard/PlacingAPlatinumTickTMAdPage';
// import LegalPage from '../views/dashboard/LegalPage';
import LegalTermsConditionsPage from '../views/dashboard/LegalTermsConditionsPage';
import LegalPrivacyPolicyPage from '../views/dashboard/LegalPrivacyPolicyPage';
import JobClosedListPage from '../views/dashboard/jobs/JobClosedListPage';
import AccountViewPage from '../views/dashboard/account/AccountViewPage';
import AdvertiserViewPage from '../views/dashboard/advertisers/AdvertiserViewPage';
import WorkPlaceProfilePage from '../views/dashboard/workplace/WorkPlaceProfilePage';
import WorkPlaceProfileListPage from '../views/dashboard/workplace/WorkPlaceProfileListPage';
import RedApplicationListPage from '../views/dashboard/applications/RedApplicationListPage';

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    // name_es: languageObj_es_json["icon.dashboard"],
    // name_de: languageObj_de_json["icon.dashboard"],
    // name_zh: languageObj_zh_json["icon.dashboard"],
    // name_fr: languageObj_fr_json["icon.dashboard"],
    // name_it: languageObj_it_json["icon.dashboard"],
    // name_pt: languageObj_pt_json["icon.dashboard"],
    // icon: Accessibility,
    // invisible: true,
    component: DashboardPage,
  },
  {
    path: '/applicants/new',
    name: 'New Applicant',
    component: ApplicantNewPage,
  },
  {
    path: '/applicants/list',
    name: 'Applicant List',
    component: ApplicantListPage,
  },
  {
    path: '/applicants/view/:applicant_id',
    name: 'View Applicant',
    component: ApplicantViewPage,
  },
  {
    path: '/applicants/saved-list',
    name: 'Applicants Saved List',
    component: ApplicantSavedPage,
  },
  {
    path: '/applications/current',
    name: 'Current Application List',
    component: ApplicationListPage,
  },
  {
    path: '/applications/red',
    name: 'Red Application List',
    component: RedApplicationListPage,
  },
  {
    path: '/applications/archived',
    name: 'Application Archive List',
    component: ApplicationArchivePage,
  },
  {
    path: '/applications/view/:application_id',
    name: 'View Application',
    component: ApplicationViewPage,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQPage,
  },
  {
    path: '/jobs/new',
    name: 'Job New',
    component: JobNewPage,
  },
  {
    path: '/jobs/current',
    name: 'Job List',
    component: JobListPage,
  },
  {
    path: '/jobs/view/:job_id',
    name: 'Job View',
    component: JobViewPage,
  },
  {
    path: '/jobs/closed',
    name: 'Job Closed List',
    component: JobClosedListPage,
  },
  {
    path: '/headhunting/list',
    name: 'Headhunting List',
    component: HeadHuntingListPage,
  },
  {
    path: '/headhunting/profile/:id',
    name: 'Headhunting Page',
    component: HeadHuntingPage,
  },
  {
    path: '/workplace-profiles/profile/:id',
    name: 'Workplace Profile View',
    component: WorkPlaceProfilePage,
  },
  {
    path: '/workplace-profiles/list',
    name: 'Workplace Profiles',
    component: WorkPlaceProfileListPage,
  },
  {
    path: '/workplace-profiles/new',
    name: 'New Workplace Profile',
    component: WorkPlaceProfilePage,
  },
  // {
  //   path: '/legal',
  //   name: 'Legal',
  //   component: LegalPage
  // },
  {
    path: '/placing-a-platinumtick-ad',
    name: 'Placing A Platinum Tick™ Ad',
    component: PlacingAPlatinumTickTMAdPage,
  },
  {
    path: '/legal/terms-conditions',
    name: 'Legal Terms Conditions',
    component: LegalTermsConditionsPage,
  },
  {
    path: '/legal/privacy-policy',
    name: 'Legal Privacy Policy',
    component: LegalPrivacyPolicyPage,
  },
  {
    path: '/settings/advertiser',
    name: 'Advertiser View',
    component: AdvertiserViewPage,
  },
  {
    path: '/settings/account',
    name: 'Account View',
    component: AccountViewPage,
  },
  {
    path: '/settings/user-profile',
    name: 'User Profile',
    component: UserProfilePage,
  },

  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard' },
];

export default dashRoutes;
