import React from 'react';

import {
  Card, PageHeader, Button
} from 'antd';
import ApplicantViewPageComponentContainer from '../../../components/pages/dashboard/applicants/ApplicantViewPageComponent';

function ApplicantViewPage(props) {
  const gotoListPage = () => {
    props.history.push('/applicants/list/');
  };

  const gotoCreatePage = () => {
    props.history.push('/applicants/new');
  };

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="Applicant Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="View and update your Applicant Details"
        extra={[
          <Button key="3" onClick={gotoCreatePage}>Create New</Button>,
          <Button key="2" disabled>View Record</Button>,
          <Button key="1" onClick={gotoListPage} type="primary">
            Show All
          </Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <ApplicantViewPageComponentContainer
            client_id={props.match.params.client_id}
          />
        </div>
      </Card>
    </div>
  );
}

export default ApplicantViewPage;
