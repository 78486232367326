import { applicantProfileType, authActionType } from '../type';
import {
  getApplicantProfileAPI,
  getApplicantProfileListAPI,
  getHeadhuntingAPI
} from '../../services/applicant_profiles';

const getApplicantProfileList = () => {
  return (dispatch) => {
    return getApplicantProfileListAPI()
      .then((data) => {
        dispatch({
          type: applicantProfileType.GET_APPLICANTPROFILES_LIST,
          applicantProfileList: data.data
        });
      }).catch(error => { });
  }
}

const getHeadhuntingList = (params) => {
  return (dispatch) => {
    return getHeadhuntingAPI(params)
      .then((data) => {
        console.log('data', data)
        if (data.data.length > 0){
          return dispatch({
            type: applicantProfileType.GET_APPLICANTPROFILES_LIST,
            applicantProfileList: data.data
          });
        }
        //else
        return dispatch({
          type: applicantProfileType.GET_APPLICANTPROFILES_LIST,
          applicantProfileList: []
        });
      }).catch(error => {})
  }
};

const getApplicantProfile = (id) => {
  return (dispatch) => {
    return getApplicantProfileAPI(id)
      .then((data) => {
        dispatch({
          type: applicantProfileType.GET_APPLICANTPROFILES,
          applicantProfile: data.data
        });
      }).catch(error => { });
  }
}

export {
  getApplicantProfile,
  getApplicantProfileList,
  getHeadhuntingList
}
