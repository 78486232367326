import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';

import { API } from 'aws-amplify';
import {
  Row, Col, notification,
} from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = theme => ({
  form_label: {
    fontWeight: theme.label_font_weight
  },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

class Advertisement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      short_description: '',
      advertisement_text: '',
      job_tasks: '',
      job_responsibilities: '',
      job_benefits: ''
    }
  }

  async componentDidMount() {
    const user = await this.getUserProfile(
      this.props.app_state.current_client_id
    );
    this.setState({
      ...user.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.app_state.current_client_id && nextProps.app_state.current_user_aws_cognito_id) {
      const user = await this.getUserProfile(
        nextProps.app_state.current_client_id
      );
      this.setState({
        ...user.payload
      })
    }
  }

  async getUserProfile(advertiser_id) {
    return API.get('advertisers', `/get/id/${advertiser_id}`);
  }

  async saveUserProfile(user) {
    await API.put('advertisers', `/update/${this.state.id}`, {
      body: user
    });
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      [`${[event.target.id]}_state`]: '',
      [`${[event.target.id]}_help`]: '',
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const {
      short_description,
      advertisement_text,
      job_tasks,
      job_responsibilities,
      job_benefits
    } = this.state;
    try {
      await this.saveUserProfile({
        short_description,
        advertisement_text,
        job_tasks,
        job_responsibilities,
        job_benefits
      });
    } catch (e) {
      try {
        await this.saveUserProfile({
          short_description,
          advertisement_text,
          job_tasks,
          job_responsibilities,
          job_benefits
        });
      } catch (e) {
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row gutter={[96, 24]} >
          <Col xs={12}>
            <div dangerouslySetInnerHTML={{ __html: this.props.currentJob.advertisement_text }} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const AdvertisementContainer = connect(
  mapStateToProps,
)(Advertisement);

export default withStyles(styles, {injectTheme: true})(AdvertisementContainer);
