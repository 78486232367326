import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Advertiser Endpoint */
const getApplicationsAPI = (params) => {
  const path = '/advertiser/v1/application';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getApplicationAPI = (id) => {
  const path = `/advertiser/v1/application/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateApplicationAPI = (id, params) => {
  const path = `/advertiser/v1/application/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const viewApplicationAPI = (params) => {
  const path = '/advertiser/v1/application/view';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export {
  getApplicationsAPI,
  getApplicationAPI,
  updateApplicationAPI,
  viewApplicationAPI
};
