import Amplify, {API, Auth} from 'aws-amplify';

import config from '../config/aws_config';

export async function create_client_cognito_account(id, username, password) {
  try {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_clients.REGION,
        userPoolId: config.cognito_clients.USER_POOL_ID,
        identityPoolId: config.cognito_clients.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_clients.APP_CLIENT_ID
      }
    });

    const newUser = await Auth.signUp({
      username: username,
      password: password
    });

    console.log(newUser);

    await update_client_in_database(id, {
      aws_cognito_id: newUser.userSub
    });

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_staff.REGION,
        userPoolId: config.cognito_staff.USER_POOL_ID,
        identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
      }
    });

    API.post('email', '/client/new-client-created', {
      body: {
        id: id,
        username: username,
        password: password
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    API.post('email', '/interaction', {
      body: {
        interaction: 'Create New Client Portal Record',
        data: {
          id: id,
          username: username,
          password: password,
          aws_cognito_id: newUser.userSub
        },
        result: 'portal record created successfully'
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    // if (e.code === "UsernameExistsException") {
    //   await Auth.resendSignUp(data.email);
    // }
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_staff.REGION,
        userPoolId: config.cognito_staff.USER_POOL_ID,
        identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
      }
    });
  }
}

async function update_client_in_database(id, client_record) {
  return API.put('applicants', `/update/${id}`, {
    body: client_record
  });
}
