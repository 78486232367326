import { countryType } from '../type';
import { getListAPI, getCountryAPI } from '../../services/countries';

/* Define New action  */
const getCountries = () => {
  return (dispatch) => {
    return getListAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: countryType.GET_COUNTRIES,
            countries: data.data
          });
        } // TODO Error Action Calling
				
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

const getCountry = (id) => {
  return getCountryAPI(id)
    .then((data) => {
      if (data) {
        return data.data
      } // TODO Error Action Calling
			
    })
    .catch(error => {
      // TODO Error Action Calling
    })
}

export {
  getCountries,
  getCountry
}
