import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { Table, Skeleton } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import listHOC from '../../../common/listHOC';
import { EyeOutlined } from '@ant-design/icons'

import { getCurrentList } from '../../../../redux/actions/jobs';

const JobListPageComponent = ({
  getColumnSearchProps,
  getJobListHandler,
  jobs,
  countries
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true)
    getJobListHandler();
    setLoading(false)
  }, []);

  const getCountryName = (countryId) => {
    return countries?.find(
      (item) => item.id === countryId,
    )?.full_name;
  };

  let data = []
  try {
    data = jobs.map((job) => ({
      ...job,
      platinum_tick_job: job.platinum_tick_job ? <img src={require('../../../../assets/img/GreenTick_20px.png')} /> : <img src={require('../../../../assets/img/RedCrossIcon_20px.png')} />,
      country: getCountryName(job.location_country_id),
      advertiser_contact: `${job.contactName || ''}[${job.advertiser_reference || ''}]`,
    }));
  } catch (e) {
    console.log(e)
  }

  const columns = [
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      sorter: (a, b) => (a.job_title ?? '').localeCompare(b.job_title ?? ''),
      ...getColumnSearchProps('job_title', 'Job Title'),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => (a.createdAt ?? '').localeCompare(b.createdAt ?? ''),
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('createdAt', 'Account ID'),
      render: (text) => <div>{moment(text).format('DD-MM-YYYY')}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      filters: [
        {
          text: 'Draft',
          value: 1,
        },
        {
          text: 'Open',
          value:  2,
        }
      ],
      onFilter: (value, record) =>
        record.status_id === (value)
      ,
      render: (text, record) => (
        <>
          {record.status?.file_by}
        </>
      ),
    },
    {
      title: 'Location Region',
      dataIndex: 'location_region',
      sorter: (a, b) =>
        (a.location_region ?? '').localeCompare(b.location_region ?? ''),
      ...getColumnSearchProps('location_region', 'Location Region'),
    },
    {
      title: 'Location Country',
      dataIndex: 'country',
      sorter: (a, b) => a.country - b.country,
      ...getColumnSearchProps('country', 'Location Country'),
    },
    {
      title: 'Advertiser Contact',
      dataIndex: 'advertiser_contact',
      sorter: (a, b) =>
        (a.advertiser_contact ?? '').localeCompare(
          b.advertiser_contact ?? '',
        ),
      ...getColumnSearchProps('advertiser_contact', 'Advertiser Contact'),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/jobs/view/${record.id}?tab=job_summary`} ><EyeOutlined /></NavLink>
        </span>
      ),
    }
  ];
  return (
    <React.Fragment>
      <Skeleton loading={loading}>
        <Table loading={loading}columns={columns} dataSource={data} rowKey="id" />
      </Skeleton>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    jobs: state.jobs.jobs,
    language: state.language,
    countries: state.countries,
  };
};

const mapDispatchToProps = {
  getJobListHandler: getCurrentList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(listHOC(JobListPageComponent));
