import React from 'react';
import {
  Card, PageHeader, Collapse, Space
} from 'antd';
import FAQPicture_1 from '../../assets/img/FAQPicture_1.png';

const { Panel } = Collapse;

const panelContent = {
  'How do I define a job description?': [
    'A job description lists the requirements, responsibilities, and skills needed from an ideal jobseeker, to perform a specific job. A good job description should be simple to understand, list all the necessary application duties and outline the job requirements.',
    `Download our free <a href='https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx' target='_blank'>job ad template</a>.`,
  ],
  'What is a job description and example?': [
    'A job description is an official public or internal job advertisement that states the specific requirements, skills, job duties and job responsibilities which the jobseeker will be required to perform in doing the advertised job.',
    `See our <a href='https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx' target='_blank'>job description example</a>. `,
    `<img src=${FAQPicture_1}></img>`
  ],
  'How do I write a good job description?': [
    '1.	Use an accurate job title.',
    '2.	Write a brief summary paragraph that gives jobseekers an overview of the job.',
    '3.	Define for the jobseeker what success looks like in the position after 30 days, the first quarter, and the first year. These are key deliverables. ',
    '4.	Write only the job responsibilities that are necessary for this job, not every job',
    '5.	List essential qualifications if any – for example the number of years of prior experience that jobseeker must have in the same position, or the education qualifications (if any). If your ideal employee must have a degree or diploma, state what that degree must be..',
    '6.	Have someone proofread it. Spelling and syntactical errors are an indication to the jobseeker that you don’t care enough about the job to get the facts straight and elucidate them.',
    '7.	Get HR and the hiring manager to sign off on it for accuracy and appropriateness.',
    '8.	Publish it on PlatinumJobSeeker™ where jobseekers can get easy access to it.',
    `View our <a href='https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx' target='_blank'>job description example</a>. `
  ],
  'What should I write in a job description?': [
    '• Job title',
    '• Job brief',
    '• Responsibilities and required tasks',
    '• Qualifications and abilities',
    `Check out this <a href='https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx' target='_blank'>job description example</a>.`
  ],
  'What is the purpose of a job description?': [
    '• To advertise an open job position.',
    '• To establish a summary of the job the jobseeker is expected to perform.',
    '• To list the responsibilities of the job.',
    '• To list the required expertise and qualifications for the job.',
  ],
  'How can I improve my job description?': [
    'Clearly define what is expected of the employee at different intervals, such as first 30 days, 90 days, 180 days, and the first year. Make this part of your sample job description template across your company to get everyone creating job descriptions that set agreed and achievable expectations.'
  ],
  'How do I write job roles and responsibilities?': [
    '• Do your research on the role and list all key responsibilities.',
    '• Make use of action words like “liaise with”, “report to”, advise stakeholders monthly” and similar action terms.',
    '• Provide sufficient details and clarify the role to the extent that the jobseeker has a firm preliminary understanding about what is being offered and expected.',
    '• Thoroughly communicate your expectations of the incumbent to the role.',
    `• Explain your company's standards.`
  ],
  'What makes an effective job description?': [
    `An effective job description is concise, short, and detailed. To ensure that jobseekers understand the role being offered and feel compelled to apply for it, the job description should leave no or little room for further questions or confusion on the job’s fundamental KPIs and requirements.`,
  ],
  'How do I write an appealing job description?': [
    '• Write up a short and engaging summary of the job.',
    '• Avoid superlatives.',
    '• Ensure the focus is on the responsibilities and development requirements.',
    '• Ask current employees advice on writing job descriptions.',
    '• Establish an urgency for the job position by advising the date by which the role must be filled, and work to that schedule.',
    '• Use your company logo.',
    '• Load your company pitch video. Make it generic but positive in terms of culture, interactivity, and any job prospects and growth.',
    '• Understand that many jobseekers are attracted to the role first, and the company second. If the jobseeker does not believe the company to be the right fit, or if the company does not have an attractive reputation or corporate attitude, some candidates will not even apply.',
    `Download this free <a href='https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx' target='_blank'>job description example</a>. `
  ],
  'Any advice for writing a job description for a job I have never done?': [
    `Writing job descriptions when you've never done the job can be intimidating.`,
    `You will want to be sure to talk to anyone at your company or agency who is more familiar with the job, especially for help writing the description of responsibilities to get the key terms right (but avoid too much “jargon”.`,
    `As a direct employer, you will have within your company individuals who are familiar with the key aspects of the role – involve them!`,
    'As an employment agent, review the specifications of the role that you receive from the direct employer, and look to your database of known applicants already on your books. You may already have the right person for the role known to you. If not, then PlatinumJobSeeker™ will spread the word for you to committed jobseeker members keen to match their skills to your stated requirements.',
  ]
};
const FAQPage = () => {
  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='FAQ'
      />
      <Card style={{ minHeight: 360 }} title='Frequently Asked Questions'>
        <div style={{ paddingTop: 24 }}>
          <p>
            PlatinumJobSeeker™ is a unique site where committed and capable jobseekers can search out their next job
            opportunity with both traditional and innovative tools. Whether you are an experienced candidate, or new to
            the job market, PlatinumJobSeeker™ is committed to providing our jobseekers with ethical services, and our
            job advertisers with cost effective, powerful tools to attract the right people.
          </p>
          <Collapse defaultActiveKey={['0']} onChange={() => { }}>
            {
              Object.keys(panelContent).map((item, index) => (
                <Panel header={<span style={{fontWeight: 600}}>{item}</span>} key={index}>
                  <Space size={10} direction="vertical">
                    {panelContent[item].map(content => (
                      <div key={content} dangerouslySetInnerHTML={{ __html: content }} />
                    ))}
                  </Space>
                </Panel>
              ))
            }
          </Collapse>
        </div>
      </Card>
    </div>
  );
};

export default FAQPage;
