import React, {
  useState, useEffect, useMemo
} from 'react';
import {connect} from 'react-redux';
import {
  Row, Col, Button, Form, Input, Typography, InputNumber, Upload, Spin, message,
} from 'antd';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import {
  LoadingOutlined, PlusOutlined, InboxOutlined
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import {Utils} from '../../../../helpers';
import APIUtils from '../../../../helpers/api/utils';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import {
  getWorkplaceProfile, updateWorkplaceProfile, createWorkplaceProfile, getWorkplaceProfileList
} from '../../../../redux/actions/workplace';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { validateToken } from '../../../../redux/actions/auth';

const { Dragger } = Upload;
const { Title } = Typography;

const WorkPlaceProfilePageComponent = props => {
  const {
    getWorkplaceProfileListHandler,
    workplaceProfile,
    getWorkplaceProfileHandler,
    updateAdvertiserHandler,
    createWorkplaceProfileHandler,
    validateTokenHandler,
    id,
    countries,
  } = props;
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [videoList, setVideoList] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setProfile(prev => ({
        ...prev,
        ...workplaceProfile
      }));
    }
    if (id && workplaceProfile && workplaceProfile.long_description) {
      const contentBlock = htmlToDraft(workplaceProfile.long_description);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
    if (id && workplaceProfile && workplaceProfile.pitch_video_file_id) {
      setVideoList([
        {
          ...JSON.parse(workplaceProfile.pitch_video_file_id),
          uid: 'advertiser-vu-1'
        }
      ]);
    }
    if (id && workplaceProfile && workplaceProfile.logoImageFileId) {
      setImageUrl(workplaceProfile.logoImageFileId);
    }
  }, [workplaceProfile]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getWorkplaceProfileHandler(id)
        .then(data => {
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleSubmit = async event => {
    setIsLoading(true);
    if (id) {
      await updateAdvertiserHandler(id, { ...profile, profileHtml: previewHtml });
      await getWorkplaceProfileListHandler();
    } else {
      createWorkplaceProfileHandler({ ...profile, profileHtml: previewHtml }).then(data => {
        validateTokenHandler();
        history.push('/workplace-profiles/list');
      });
      await getWorkplaceProfileListHandler();
    }
    setIsLoading(false);
    await getWorkplaceProfileListHandler();
    history.push('/workplace-profiles/list');
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = event => {
    const {id, value} = event.target;
    setProfile(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const beforeUploadLogoImage = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeLogoImage = info => {
    if (info.file.status === 'uploading') {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setImageUrl(info.file.response.Location);
        setProfile(prev => ({
          ...prev,
          logoImageFileId: info.file.response.Location
        }));
        setLoadingImage(false);
      });
    }
  };

  const handleChangeCountryId = value => {
    setProfile(prev => ({
      ...prev,
      country_id: value
    }));
  };

  const handleChangeEmployeeCount = value => {
    setProfile(prev => ({
      ...prev,
      employee_count: value
    }));
  };

  const handleRemoveLogoImage = () => {
    setImageUrl('');
  };

  const token = APIUtils.getAccessToken();

  const uploadVideoProps = {
    name: 'file',
    multiple: false,
    action: `${Utils.serverUrl.API_BASE_URL}/advertiser/v1/upload`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    fileList: videoList,
    beforeUpload: file => {
      if (file.size > 1024 * 1024 * 10) {
        message.error('Video must smaller than 10MB!');
        return false;
      }

      if (
        file.type === 'video/webm' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/mp2' ||
        file.type === 'video/mpg' ||
        file.type === 'video/mpv' ||
        file.type === 'video/mkv' ||
        file.type === 'video/ogg' ||
        file.type === 'video/mp4' ||
        file.type === 'video/m4p' ||
        file.type === 'video/m4v' ||
        file.type === 'video/avi' ||
        file.type === 'video/wmv' ||
        file.type === 'video/mov' ||
        file.type === 'video/qt' ||
        file.type === 'video/flv' ||
        file.type === 'video/swf'
      ) {
        return true;
      }
      message.error('Invalid file type! Please upload only Video file.');
      return false;
    },
    onChange(info) {
      const {status} = info.file;
      setVideoList([info.file]);
      if (status !== 'uploading') {
        if (info.file.response) {
          setProfile(prev => ({
            ...prev,
            pitch_video_file_id: JSON.stringify({
              name: info.file.name,
              path: info.file.response.Location
            })
          }));
        } else {
          setVideoList([]);
        }
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        if (workplaceProfile.pitch_video_file_id) {
          setVideoList([JSON.parse(workplaceProfile.pitch_video_file_id)]);
        }
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleRemoveVideo = () => {
    setProfile(prev => ({
      ...prev,
      pitch_video_file_id: null
    }));
    setVideoList([]);
  };

  const videoUrl = profile.pitch_video_file_id ? JSON.parse(profile.pitch_video_file_id).path : null;
  const uploadButton = (
    <div>
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  const country = countries.find(item => item.id === profile?.country_id)?.full_name;

  const previewHtml = useMemo(() => {
    return `
      <div>
        <div style="font-size: 18px; margin-right: 15px; display: flex; justify-content: space-between">
          <strong>${profile?.workplace_name ? profile?.workplace_name : ''}</strong>
          ${imageUrl ? `<img src="${imageUrl}" alt="logo" width="100" height="100" />` : ''}
        </div>
        <div>${profile?.region ? profile?.region : ''} ${country ? country : ''}</div>
        <div style="margin-top: 24px;">${profile?.long_description ? profile?.long_description : ''}</div>
        ${videoUrl ? `<video style="margin-top: 24px;" src="${videoUrl}" controls="true" />` : ''}
      </div>
    `;
  }, [profile]);

  const onEditorStateChange = (edit) => {
    setEditorState(edit);

    const htmlText = draftToHtml(convertToRaw(edit.getCurrentContent()));
    setProfile((prev) => ({
      ...prev,
      long_description: htmlText,
    }));
  };

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Form layout='vertical' onFinish={handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col span={12}>
              <Title level={4}>Enter Workplace Profile Details</Title>
              <Form.Item label='Workplace Name'>
                <Input id='workplace_name' value={profile.workplace_name} onChange={handleChange} />
              </Form.Item>

              <Form.Item label='Internal Profile Name (not-public)'>
                <Input id='private_fileBy' value={profile.private_fileBy} onChange={handleChange} />
              </Form.Item>

              <Form.Item label='Workplace Description'>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    }
                  }}
                />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label='Region'>
                    <Input id='region' value={profile.region} onChange={handleChange} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='Country'>
                    <CountrySelectComponent
                      id='country_id'
                      value={profile.country_id}
                      handleChange={handleChangeCountryId}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label='Employee Count'>
                <InputNumber
                  id='employee_count'
                  style={{width: '100%'}}
                  onChange={handleChangeEmployeeCount}
                  value={profile.employee_count}
                  min={0}
                  max={9999}
                />
              </Form.Item>

              <Form.Item label='Workplace / agent pitch video upload'>
                {videoUrl ? (
                  <Col>
                    <ReactPlayer
                      url={videoUrl}
                      controls={true}
                      width='100%'
                      height='100%'
                      style={{maxWidth: 500}}
                      playing={true}
                    />
                    <Button type='primary' danger style={{marginTop: 10}} onClick={handleRemoveVideo}>
                      Remove
                    </Button>
                  </Col>
                ) : (
                  <Dragger {...uploadVideoProps}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ fontStyle: 'italic', padding: 10 }}
                    >
                      Your video will be screened by the site for allowable
                      content before being posted to the site. You may change your
                      video any number of times, but a replacement will
                      permanently delete any prior content.
                      <br/>
                      <strong>File limit 10Mb size, 20 second length</strong>
                    </p>
                  </Dragger>
                )}
              </Form.Item>
              <Form.Item label='Logo Upload'>
                <ImgCrop>
                  <Upload
                    name='file'
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    action={`${Utils.serverUrl.API_BASE_URL}/advertiser/v1/upload`}
                    headers={{
                      Authorization: `Bearer ${token}`
                    }}
                    beforeUpload={beforeUploadLogoImage}
                    onChange={handleChangeLogoImage}
                  >
                    {imageUrl ? <img src={imageUrl} alt='avatar' style={{width: '100%'}} /> : uploadButton}
                  </Upload>
                </ImgCrop>
                <div>Suggest uploading 100x100px image for best results</div>
                {imageUrl && (
                  <Button type='primary' danger style={{marginTop: 10}} onClick={handleRemoveLogoImage}>
                    Remove
                  </Button>
                )}
              </Form.Item>

              <Col span={24} style={{textAlign: 'left', paddingLeft: 0}}>
                <Button type='primary' htmlType='submit'>
                  Save Record
                </Button>
              </Col>
            </Col>
            <Col span={12}>
              <Title level={4}>Profile Preview</Title>
              <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    workplaceProfile: state.workplaceProfile,
    countries: state.countries,
  };
};

const mapDispatchToProps = {
  getWorkplaceProfileHandler: getWorkplaceProfile,
  updateAdvertiserHandler: updateWorkplaceProfile,
  createWorkplaceProfileHandler: createWorkplaceProfile,
  getWorkplaceProfileListHandler: getWorkplaceProfileList,
  validateTokenHandler: validateToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkPlaceProfilePageComponent);
