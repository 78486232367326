import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const loginAPI = (userData) => {
  // userData = {email, password}
  const path = '/advertiser/auth/v1/user-advertiser/login';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const params = userData;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
};

const confirmEmailAPI = (token) => {
  const path = '/advertiser/auth/v1/user-advertiser/verify-email';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const params = token;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
}

const resetPasswordAPI = (email) => {
  const path = '/advertiser/auth/v1/user-advertiser/reset-password';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const params = email;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
}

const confirmPasswordAPI = (params) => {
  const path = '/advertiser/auth/v1/user-advertiser/confirm-password';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
}

const signupAPI = (userData) => {
  // userData = {email, username, password, first_name, last_name, dob, (external_id)}
  const path = '/advertiser/auth/v1/user-advertiser/register';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const params = userData;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
};

const validateTokenAPI = (token) => {
  const path = '/advertiser/auth/v1/user-advertiser/validate';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const params = token;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendRequest(params);
}

export {
  loginAPI,
  signupAPI,
  validateTokenAPI,
  confirmEmailAPI,
  resetPasswordAPI,
  confirmPasswordAPI
};