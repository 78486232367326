import React, { Component } from 'react';
import { Card, PageHeader } from 'antd';

import AccountViewPageComponentContainer from '../../../components/pages/dashboard/account/AccountViewPageComponent';

class AccountViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.props.match.params.subscription_id)
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Account Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Account Details"
        >
          <div style={{ paddingTop: 24 }}>
            <AccountViewPageComponentContainer/>
          </div>
        </Card>
      </div>

    );
  }
}

export default AccountViewPage;
