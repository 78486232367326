const GET_JOBS = 'GET_JOBS';
const GET_JOB = 'GET_JOB';
const GET_JOB_STATUS_LIST = 'GET_JOB_STATUS_LIST';
const GET_JOB_MUST_HAVE_EDUCATION_LIST = 'GET_JOB_MUST_HAVE_EDUCATION_LIST';
const GET_JOB_MUST_HAVE_EXPERIENCE_LIST = 'GET_JOB_MUST_HAVE_EXPERIENCE_LIST';
const UPDATE_JOB = 'UPDATE_JOB';

export default {
  GET_JOBS,
  GET_JOB,
  GET_JOB_STATUS_LIST,
  GET_JOB_MUST_HAVE_EDUCATION_LIST,
  GET_JOB_MUST_HAVE_EXPERIENCE_LIST,
  UPDATE_JOB,
}
