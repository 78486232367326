import { paymentType } from '../type';
import { getBalancelistAPI, getBillingHistoryListAPI } from '../../services/payment';

const getBalancelist = (id) => {
  return (dispatch) => {
    return getBalancelistAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: paymentType.GET_BALANCE_LIST,
            balanceList: data.data
          });
        } // TODO Error Action Calling
				
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

const getBillingHistoryList = (id) => {
  return (dispatch) => {
    return getBillingHistoryListAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: paymentType.GET_BILLING_HISTORY_LIST,
            billingHistory: data.data
          });
        } // TODO Error Action Calling
				
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

export {
  getBalancelist,
  getBillingHistoryList
}