import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin } from 'antd';
import { NavLink } from 'react-router-dom'
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons'
import { getApplications } from '../../../../redux/actions/application';

const ApplicationListPageComponent = ({ trafficLightId, jobId }) => {
  const [loading, setLoading] = useState(false);
  const applications = useSelector(state => state.application);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = trafficLightId ? { trafficLightId } : {};

    if (jobId) {
      params.jobId = jobId;
    }

    dispatch(getApplications(params))
  }, [dispatch])

  const columns = [
    {
      title: 'Jobseeker',
      render: (text, record) => (
        <span>
          {`${record.applicantId.id} - ${record.applicantId.first_name} ${record.applicantId.last_name}`}
        </span>
      ),
    },
    {
      title: 'Application Made',
      dataIndex: 'application_date_time',
      render: (text) => <div>{moment(text).format('DD-MM-YYYY')}</div>
    },
    {
      title: 'Job',
      render: (text, record) => (
        <span>
          <NavLink to={`/jobs/view/${record.job_id}?tab=job_summary`} >{record.jobId?.job_title}</NavLink>
        </span>
      ),
    },
    {
      title: 'Application Viewed',
      dataIndex: 'viewed_by_advertiser_datetime',
      render: (text) => text ? (<div>{ moment(text).format('DD-MM-YYYY') }</div>) : <div />
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/applications/view/${record.id}`} ><EyeOutlined /></NavLink>
        </span>
      ),
    },
  ];

  const dataValid = useMemo(() => {
    return applications && Array.isArray(applications);
  }, [applications]);

  return (
    <React.Fragment>
      <Spin spinning={loading || !dataValid}>
        <Table
          columns={columns}
          dataSource={applications}
          rowKey="id"
        />
      </Spin>
    </React.Fragment>
  );
};

export default ApplicationListPageComponent;
