import { getUserAPI, updateUserAPI } from '../../services/advertiser';
import { advertiserType } from '../type';

const getAdvertiser = (id) => {
  return (dispatch) => {
    return getUserAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: advertiserType.GET_ADVERTISER,
            advertiser: data.data
          });
        } else console.log(data.msg);
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

const updateAdvertiser = (id, params) => {
  return (dispatch) => {
    return updateUserAPI(id, params)
      .then((data) => {
        if (data) {
          return dispatch({
            type: advertiserType.UPDATE_ADVERTISER,
            advertiser: data.data
          });
        } else console.log(data.msg);
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

export {
  getAdvertiser,
  updateAdvertiser
}