import React from 'react';
import { Card, PageHeader, Collapse } from 'antd';

const { Panel } = Collapse;

const LegalTermsConditionsPage = () => {
  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360,
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16,
        }}
        title="Terms and Conditions"
      />

      <Card
        style={{ minHeight: 360 }}
        title="Terms and Conditions Platinumjobseeker.com"
      >
        <p>
          By using any one or more of www.platinumjobseeker.com , the PJS Site
          or the PJS services, You agree that:
        </p>
        <div style={{ paddingTop: 24 }}>
          <Collapse defaultActiveKey={['1']} onChange={() => {}}>
            <Panel header="1. General" key="1">
              <li>
                a. You have read this agreement completely and carefully before
                accessing or using the information and services available
                through the Platnumjobseeker™ website (“PJS Site”) and agree to
                be bound by these terms and conditions.
              </li>
              <br />
              <li>
                b. whether You are an advertiser, a Platnumjobseeker™ member or
                a browser, each and every one of these Terms and Conditions is
                important because each and every term and condition is accepted
                by You whenever You use or access Our services in whatever
                capacity You use or access the PJS Site; and
              </li>
              <br />
              <li>
                c. Platnumjobseeker™ may modify, amend, alter or add to this
                agreement at any time with immediate effect after We post the
                modification, amendment, alteration or addition to the PJS Site.
                We will notify all persons who access or use the PJS Site when
                any such modification, amendment, alteration or addition to the
                PJS Site is made.{' '}
              </li>
            </Panel>
            <Panel header="2.	For advertisers" key="2">
              <p>
                Direct advertisers and job agencies representing businesses with
                job opportunities must separately read and agree to enter into
                the advertiser Ts and Cs available here on the Advertiser
                dashboard. The Advertiser Ts and Cs must be accepted and adhered
                to in full by all direct advertisers who use the PJS Site and
                any associated services including the placing of Platinum Tick™™
                job ads. In general, those Ts ad Cs requiring direct advertisers
                to be honest, genuine, accurate and respectful of the PJS Site
                terms and conditions, and of the requirements imposed on
                jobseeker shortlisting, rejection and the communication of
                decisions which are and always will be a fundamental part of the
                PJS Site ethos and a further requirement of the PJS Site
                guarantee to jobseekers in respect of Platinum Tick™™ job ads.{' '}
              </p>
            </Panel>
            <Panel header="3.	Privacy Policy" key="3">
              <p>You acknowledge and agree that:</p>
              <li>
                3.1 You will adhere to the Platinumjobseeker™ Privacy Policy in
                respect of the confidential or sensitive information You
                provide, supply or send to Us whether You are a jobseeker
                member, user or advertiser.
              </li>
              <br />
              <li>
                3.2 You acknowledge Our recommendation that every party or
                person who deals with Us and takes advantage of Our Services
                will read the Privacy Policy thoroughly and accept Our Privacy
                Policy before dealing with Us.{' '}
              </li>
              <br />
              <li>
                3.3 You also acknowledge Our points 10. and 11. below which deal
                respectively with:
              </li>
              <br />
              <li>
                i. Our rights to use the information which You provide to Us by
                any means; and
              </li>
              <br />
              <li>
                ii. Our disclaimer that We can not guarantee absolute and total
                unbreachable security of Your private information because, by
                its nature, such information is susceptible to data breaches
                from determined cyber criminals and hackers – as well as under
                Court Order in certain jurisdictions by duly appointed
                authorities.{' '}
              </li>
            </Panel>
            <Panel header="4. Copyright © and trademarks ™" key="4">
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                4.1 Platinum Job Seeker Pty Ltd ABN 14 630 089 940 (“PJS”) owns
                and controls all Copyright in the material and trademarks on,
                and in respect of, the PJS Site and the intellectual property
                rights to the use of the term “Platinum Tick™™”, the latter in
                relation to job ads placed directly with Us and in respect of
                which, We make certain commitments to jobseekers and job
                advertisers.
              </li>
              <br />
              <li>
                4.2 We commit that where PJS does not own or control any
                Copyright in the materials and trademarks referred to on the PJS
                Site, PJS will at all times acknowledge the Copyright owner,
                where that owner is known or source can be reasonably
                identified.{' '}
              </li>
              <br />
              <li>
                4.3 You will, by using or accessing the PJS Site and PJS
                Services, hereby agree as a fundamental term of Your use of or
                access to the PJS Site that You will not intentionally or
                unintentionally infringe, or knowingly or contributorily allow
                any other party to infringe, any intellectual property rights
                owned by PJS.{' '}
              </li>
            </Panel>
            <Panel
              header="5.	Information for personal, non-commercial use only"
              key="5"
            >
              <p>You acknowledge and agree that:</p>
              <li>
                5.1 information including code and software contained on the PJS
                Site and its associated sub-domains:
              </li>
              <br />
              <li>i. is for personal use only;</li>
              <br />
              <li>
                ii. may not in any way be modified, reproduced, republished,
                uploaded, posted, copied, transmitted or distributed by You in
                any way or by any means;
              </li>
              <br />
              <li>
                iii. may not be sold, redistributed or used for any commercial
                purpose (including but not limited to use of Advertiser contact
                details for unsolicited commercial correspondence and the
                information available via our sub-domains;
              </li>
              <br />
              <li>
                iv. includes material downloaded from the PJS Site and its
                associated sub-domains which is for your personal,
                non-commercial use only – and which We permit only if You keep
                intact all copyright and other proprietary notices;
              </li>
              <br />
              <li>
                v. must not be used for any unlawful or forbidden purpose which
                is prohibited by these terms and conditions. For clarity, such
                unlawful or forbidden purposes include but are not limited to
                the spreading of propaganda, political views, or views which are
                disrespectful, racist, discriminatory or inciteful, and also
                forbid You using links You insert into any article or
                information We publish on the PJS Site or illegally copied by
                You and posted , promoted or published elsewhere.
              </li>
            </Panel>
            <Panel
              header="6.	Information does not represent professional advice"
              key="6"
            >
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                {' '}
                6.1 no information provided on the site is, or is intended to
                be, specific to Your circumstances;{' '}
              </li>
              <br />
              <li>
                {' '}
                6.2 all information provided on the PJS Site is general in
                nature and is not, and should not be considered to be, legal
                advice;
              </li>
              <br />
              <li>
                {' '}
                6.3 the information published on the PJS Site is and is intended
                to be, solely general information, provided in summary form, on
                any employment related issues with general readership intended;
              </li>
              <br />
              <li>
                {' '}
                6.4 PJS strongly suggests that You obtain independent legal
                advice on any employment matter related to You and also before
                You enter into any verbal or written agreement with any of the
                Advertisers on the PJS Site. We also highly recommend that You
                obtain your own independent accounting, financial and taxation
                advice as appropriate to Your own circumstances;
              </li>
              <br />
              <li>
                {' '}
                6.5 PJS does not expressly or impliedly warrant the accuracy or
                completeness of the information on the PJS Site. It is solely
                your responsibility to evaluate the accuracy, completeness and
                usefulness of all opinions, advice, services, merchandise and
                other information provided through the PJS Site or on any other
                internet site.{' '}
              </li>
              <br />
              <li>
                {' '}
                6.6 PJS does not act as a jobs broker or agent and earns no
                commission from job advertisers for a successful application
                lodged through the PJS Site.
              </li>
              <br />
              <li>
                {' '}
                6.7 PJS does not warrant, endorse or recommend any of the jobs,
                education or self-employment opportunities which are advertised
                on the PJS Site
              </li>
              <br />
              <li>
                {' '}
                6.8 In no event will PJS be liable to You or anyone else for any
                decision You make, or action You take or anyone else takes in
                reliance upon any information contained on or omitted from the
                Site.
              </li>
            </Panel>
            <Panel header="7.	No guarantees of job vacancy" key="7">
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                7.1 PJS does not guarantee to You that every job ad You see
                advertised on the PJS Site is for a job opportunity that remains
                available when You apply for it.
              </li>
              <br />
              <li>
                7.2 PJS takes all reasonable efforts to ensure that the jobs
                advertised on the PJS Site are for open (not already filled and
                closed) actual job vacancies, however We can not guarantee You
                that every job advertisement represents an actual job vacancy.
              </li>
              <br />
              <li>
                7.3 PJS will not accept liability of any kind whatsoever to You
                if an advertiser has filled the advertised job opportunity prior
                to removal of the job ad from the PJS Site.
              </li>
            </Panel>
            <Panel
              header="8.	No guarantees or endorsements of sites or services we link to or that link to us"
              key="8"
            >
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                8.1 The PJS Site provides links and pointers to some internet
                sites which are maintained by third parties.{' '}
              </li>
              <br />
              <li>
                8.2 Third-party-maintained sites to which We provide links and
                pointers are not controlled by Us.
              </li>
              <br />
              <li>
                8.3 PJS is not, and can not be held, responsible for the
                contents, privacy policies, decency, morality, decency, or
                operations and consequences of Your use of, such third party
                sites.{' '}
              </li>
              <br />
              <li>
                8.4 By clicking on any such link from the PJS Site, You agree to
                bind yourself to the terms and conditions of those websites, and
                to hold Us harmless. For clarity, binding yourself to the terms
                and conditions of those websites includes but is not limited to
                being subject to their privacy policy and their standards or
                legality, decency, accuracy, legitimacy, and fitness for
                purpose.{' '}
              </li>
              <br />
              <li>
                8.5 Any such link will be provided by Us to You as a
                convenience, and is not, and is not to be construed as being, a
                link in which We have any financial or other interest, or
                reflective of Our endorsement of that product or service, or
                that We ae responsible for the copyright of any such linked
                site.{' '}
              </li>
              <br />
              <li>
                8.6 Where damages, costs or losses of any kind whatsoever occur
                to a party arising directly or indirectly from or connected with
                the information, goods or services provided to You by that third
                party link, PJS expressly denies liability of any nature
                whatsoever.
              </li>
            </Panel>
            <Panel header="9.	No guarantees as to the service" key="9">
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                9.1 PJS does not warrant to You that the PJS Services generally
                available through the PJS Site will be uninterrupted or
                error-free or that every defect in the PJS Services will be
                corrected.{' '}
              </li>
              <br />
              <li>
                9.2 You understand and agree that PJS cannot and does not
                guarantee or warrant to you that files, text, pictures or
                materials of any kind whatsoever where they are available for
                downloading through the Site or delivered via electronic mail
                through the Site will be free of damaging codes or viruses which
                may be uploaded by Your computer or handheld device and in doing
                so cause damage to Your device, programmes or data.
              </li>
              <br />
              <li>
                9.3 You bear sole responsibility for initiating procedures,
                external storage and anti-virus programmes on your computer and
                handheld device which are sufficiently robust as to enable You
                to recover Your lost data to the level of restoration You
                require should there be a catastrophic system failure.
              </li>
            </Panel>
            <Panel
              header="10. Our rights to use the information you send or supply or provide to us"
              key="10"
            >
              <p>You acknowledge and agree that:</p>
              <br />
              <li>
                10.1 PJS welcomes constructive feedback from all jobseekers and
                advertisers about the Services We offer, and Your experiences
                on, the PJS Site.
              </li>
              <br />
              <li>
                10.2 PJS may use, reproduce, distribute, transmit, create
                derivative works of, and publicly display any materials and
                other information (including ideas for new or improved products
                and services) that you submit to any public areas of the Site
                (such as bulletin boards, forums and newsgroups) or by email to
                PJS.{' '}
              </li>
              <br />
              <li>
                10.3 Any intellectual property in work derive from Your ideas
                and suggestions will be and at all time remain the intellectual
                property of PJS. (Please read our Privacy Policy for more
                information as to how we deal with information you send to us.)
              </li>
            </Panel>
            <Panel
              header="11. No guarantees as to security of your information"
              key="11"
            >
              <p>
                While PJS takes all due care in ensuring the privacy and
                integrity of the information you provide, the possibility exists
                that this information could be unlawfully observed by a third
                party while in transit over the Internet or while stored on PJS
                systems or on the PJS Site. PJS disclaims all liability to you
                to the greatest extent possible pursuant to law should this
                occur.
              </p>
            </Panel>
            <Panel
              header="12.	Your responsibility for your password and login"
              key="12"
            >
              <p>
                To become a registered user, you must provide a password and a
                login name. You are entirely responsible if you do not maintain
                the confidentiality of your password and login name. You are
                entirely responsible for any and all activities that occur under
                your login name. You may change your password at any time by
                following the instructions We provide You. You may also change
                your login by contacting Our customer service. You may also
                delete services attached to your registration at your
                convenience. You agree to immediately notify PJS of any
                unauthorized use of your login name or any other breach of
                security known to you.
              </p>
            </Panel>
            <Panel header="13. No misrepresentations allowed" key="13">
              <p>
                You agree that it is a condition on your use of the PJS Site and
                of any other services provided by PJS or through the Site that
                you will not through any act or omission (including but not
                limited to creating a profile on the PJS Site) intentionally or
                unintentionally mislead or deceive others.
              </p>
            </Panel>
            <Panel header="14. Data Mining and site scraping" key="14">
              <p>
                You agree that the information provided on the PJS Site is
                private and commercial in nature, any breach of the use of which
                would cause commercial, social, reputational and corporate
                damage to PJS, which can not be compensated by damages alone.
              </p>
              <br />
              <p>
                No person or entity may directly or indirectly use data mining,
                screen scraping, bots, or similar automated data extraction,
                data gathering or data publication tools on this Site without
                Our prior written consent. Such consent will not be unreasonably
                withheld by PJS. Our decision in respect of such consent is
                final and will not be subject to discussion or review once
                issued on Our letterhead.
              </p>
              <br />
              <p>
                For clarity, unauthorised actions of the type mentioned above in
                this paragraph include but are not limited to such actions You
                take without Our written authority to establish, reproduce in
                whole or part, maintain, or advance information on our Site to
                Your own website or in any other public document in any form.
              </p>
            </Panel>
            <Panel header="15. General provisions" key="15">
              <p>
                If any part of this agreement is held invalid that part shall be
                severed from this agreement and the remainder of this agreement
                will continue to be valid and enforceable.
              </p>
              <br />
              <p>
                Termination of this agreement will not end those provisions that
                are capable of surviving the ending of this agreement.
              </p>
              <br />
              <p>
                The laws governing this agreement will be the laws in Victoria,
                Australia and you irrevocably submit to the exclusive
                jurisdiction of its Courts and its Appellate Courts and the
                jurisdiction of the Federal Court of Australia sitting in
                Victoria.
              </p>
              <br />
              <p>
                For the purposes of this agreement the words “PJS”, “PJS
                Limited”, “We”, “Our” and “Us” refer to PJS Limited and “Site”
                to materials delivered on{' '}
                <a href={'http://www.platinumjobseeker.com'}>
                  www.platinumjobseeker.com
                </a>{' '}
                and other co-branded versions of the site.
              </p>
              <br />
              <li> a. Invalidity </li>
              <li>
                If any part or parts of this agreement is held to be invalid or
                unenforceable by a Court of competent jurisdiction then that
                part or those parts shall be read down or severed from this
                agreement to the extent that the remaining parts of this
                agreement remain on foot and continue to be valid and
                enforceable.
              </li>
              <br />
              <li> b. Termination and survival </li>
              <li>
                Unilateral or bilateral termination of this agreement will not
                end those provisions that are capable of surviving the ending or
                termination of this agreement.
              </li>
              <br />
              <li> c. Jurisdiction </li>
              <li>
                The laws governing this agreement are the laws in Victoria,
                Australia and where necessary to the laws of the Commonwealth of
                Australia. You irrevocably submit to the exclusive jurisdiction
                of the Australian Courts in matters concerning the
                interpretation and application of those laws irrespective of the
                jurisdiction from which You access the PJS Site.
              </li>
              <br />
              <li>d. Whole agreement </li>
              <li>
                This agreement forms the whole of the understanding between PJS
                and You for the purposes of this agreement, and neither You nor
                We rely on any pre-existing discussions or agreements in
                relation to this subject matter.
              </li>
              <br />
              <li>e. Headings </li>
              <li>
                Headings used in this agreement are for convenience only and are
                not to be used in interpreting this agreement
              </li>
              <br />
              <li>f. Terms used </li>
              <li>
                For the purposes of this agreement the words
                “Platinumjobseeker™”, “PJS”, “PlatinumJobSeeker Pty Ltd”, “We”,
                “Us” and “Our” refer to Platinum Job Seeker Pty Ltd ABN 14 630
                089 940, and “PJS Services” and “PJS Site” and “PJS Services”
                refer to the official website of Platinum Job Seeker Pty Ltd and
                the information and Services offered and provided on{' '}
                <a href={'http://www.platinumjobseeker.com'}>
                  www.platinumjobseeker.com
                </a>{' '}
                and associated sites.
              </li>
              <br />
              <small>
                {' '}
                Date and version: Version 1.0 dated October 25th, 2020
              </small>
            </Panel>
          </Collapse>
        </div>
      </Card>
    </div>
  );
};

export default LegalTermsConditionsPage;
