import { authActionType } from '../type';

const initialState = {
  loggedIn: false,
	currentUser: null,
	isAuthenticated: false
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case authActionType.SIGN_UP:
			return {
				...state,
				currentUser: action.user,
				loggedIn: true,
				isAuthenticated: true
			}

		case authActionType.SIGN_IN:
			return {
				...state,
				currentUser: action.user,
				loggedIn: true,
				isAuthenticated: true
			}

		case authActionType.SIGN_OUT:
			return {
				loggedIn: false,
				isAuthenticated: false
			}
		case authActionType.AUTH_VALID:
			return {
				isAuthenticated: action.isAuthenticated,
				currentUser: action.user,
			}
		case authActionType.UPDATE_WORKPLACE_ID:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					workplace_profile_id: action.workplaceProfileId
				}
			}

		default:
			return state;
	}
}