import { countryType } from '../type';

const initialState = [];

function countries(state = initialState, action) {
  switch (action.type) {
    case countryType.GET_COUNTRIES:
      return action.countries
    default:
      return state;
  }
}

export default countries;
