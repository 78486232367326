import PagesContainer from '../layouts/Pages.jsx';
import DashboardContainer from '../layouts/Dashboard.jsx';
import ConfirmEmailAddress from '../views/pages/ConfirmEmailAddressPage';
import ResetPassword from '../views/pages/ResetPassword';

var indexRoutes = [
  {
    path: '/confirm-email',
    name: 'ConfirmEmailAddress Page',
    component: ConfirmEmailAddress
  },
  {
    path: '/reset-password',
    name: 'Reset Password Page',
    component: ResetPassword
  },
  {
    path: '/pages',
    name: 'Pages',
    component: PagesContainer
    //component: PagesContainer
  },
  {
    path: '/',
    name: 'Home',
    component: DashboardContainer
    //component: Dashboard
  }
];

export default indexRoutes;
