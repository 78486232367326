
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateNewClientCreation

} from '../../../../../redux/actions/applicants';

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewClientWizard03 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  async componentDidMount() {

  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
    this.props.updateNewClientCreation(event.target.id, event.target.value);

  };

  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        {this.props.clients.new_client_data.first_name}
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewClientWizard03Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClientWizard03);

export default NewClientWizard03Container;
