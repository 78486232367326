import { advertiserType } from '../type';

const initialState = {
}

export default function advertiserReducer(state = initialState, action) {
	switch (action.type) {
		case advertiserType.GET_ADVERTISER:
			return {
				...state,
        ...action.advertiser
			}

		case advertiserType.UPDATE_ADVERTISER:
			return {
				...state,
        ...action.advertiser
			}

		default:
			return state;
	}
}