import React, { useState } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { useHistory } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { Row, Col, Card, Button, Select, Typography, Spin, Modal } from 'antd';

import { checkoutProductAPI } from '../../../../../services/payment';
import { getBalancelist } from '../../../../../redux/actions/payment';
import { validateToken } from '../../../../../redux/actions/auth';

const { Title, Text } = Typography;
const { Option } = Select;

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

export const types = [
  {
    name: 'Platinum Tick™ Ads',
    description: 'Formatted template ad, no advertiser logo, shows as Platinum Tick™ ad in general search results, no priority ranking, unfiltered applications, up to 60 day run time, stop lighting of applications by advertiser applies as does site guarantee of response to unsuccessful candidates with standard email.',
    price: 230,
    id: 1,
  },
  {
    name: 'Platinum Tick™ Plus Ads',
    description: 'Formatted template ad, advertiser logo, shows as Platinum Tick™ ad in general search results, no priority ranking, applications filtered by keyword, up to 90 day run time, stop lighting of applications by advertiser applies as does site guarantee of response to unsuccessful candidates with standard email.',
    price: 270,
    id: 2,
  },
  {
    name: 'Platinum Tick™ Premium Ad',
    description: 'Formatted template ad, advertiser logo, shows as Platinum Tick™ ad in general search results at top of page with featured priority ranking, fuller description of job detail, applications filtered by keyword, stop lighting of applications by the advertiser applies as does site guarantee of response to unsuccessful candidates through advertiser’s choice of template emails, and unlimited run time until job filled.',
    price: 350,
    id: 3,
  },
];

const credits = [1, 5, 10, 25, 50];

function PurchaseAdverts(props) {
  const history = useHistory();
  const { user, validateTokenHandler } = props;
  const [currentCredits, setCurrentCredits] = useState({ "0": 1, "1": 1, "2": 1});
  const [loading, setLoading] = useState(false);
  const gridStyle = {
    width: '100%',
    textAlign: 'center',
  };
  const successModal = () => {
    Modal.success({
      title: 'Purchase Adverts Success',
      content: 'Payment Successful!',
      onOk() {
        history.push(`/settings/account/${1}?tab=my_account`);
      },
    });
  };
  const failedModal = () => {
    Modal.error({
      title: 'Purchase Adverts Failed',
      content: 'Sorry, Payment failed!',
      onOk() {},
    });
  };

  const handleChangeCredit = (value, index) => {
    setCurrentCredits((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleCalculatePrice = (index) => {
    const calPrice =
      parseInt(types[index].price, 10) * parseInt(currentCredits[index], 10);
    switch (parseInt(currentCredits[index], 10)) {
      case 1:
        return calPrice;
      case 5:
        return calPrice * 0.925;
      case 10:
        return calPrice * 0.9;
      case 25:
        return calPrice * 0.875;
      case 50:
        return calPrice * 0.8;
      default:
        return 0;
    }
  };

  const handleToken = (index) => (token) => {
    setLoading(true);
    const params = {
      description: types[index].name,
      source: token.id,
      currency: 'AUD',
      amount: handleCalculatePrice(index) * 100,
      info: {
        advertiser_id: user.advertiser_id,
        advertisement_product_id: types[index].id,
        balance: parseInt(currentCredits[index], 10),
      },
    };
    checkoutProductAPI(params)
      .then((data) => {
        successModal();
        validateTokenHandler();
        setLoading(false);
      })
      .catch((error) => {
        failedModal();
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Title level={4}>Purchase Advertisement Packages</Title>
        <Card>
          <Row gutter={[24, 0]}>
            {types.map((item, index) => (
              <Col xs={8} key={item.name}>
                <Card
                  title={item.name}
                  style={gridStyle}
                  actions={[
                    <React.Fragment>
                      <Select
                        placeholder="Select Item"
                        defaultValue={1}
                        value={currentCredits[index]}
                        style={{ minWidth: 100 }}
                        onChange={(value) => handleChangeCredit(value, index)}
                      >
                        {credits.map((credit) => (
                          <Option key={`${credit}`}>{credit}</Option>
                        ))}
                      </Select>
                      <StripeCheckout
                        stripeKey="pk_live_51HbkmJKjq9qvI530AaTruUGawufsfjxTMicZtbLvyXkiH8by5xWivNle64yzWUyYqDtpSHkPEYAVxN6kULKG3YeK00uEEBFqWk"
                        token={handleToken(index)}
                        amount={handleCalculatePrice(index) * 100}
                        description={item.name}
                        currency="AUD"
                      >
                        <Button
                          style={{ marginLeft: 20 }}
                          disabled={handleCalculatePrice(index) === 0}
                          type="primary"
                        >
                          Purchase Now
                        </Button>
                      </StripeCheckout>
                    </React.Fragment>,
                  ]}
                >
                  <Text style={{ textAlign: 'left', display: 'block' }}>{item.description}</Text>
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginTop: 20,
                    }}
                  >
                    <li>{`Purchase 1 for $${item.price}`}</li>
                    <li>Purchase 5 for a 7.5% discount</li>
                    <li>Purchase 10 for a 10% discount</li>
                    <li>Purchase 25 for a 12.5% discount</li>
                    <li>Purchase 50 for a 20% discount</li>
                  </ul>
                  <Title level={4}>${handleCalculatePrice(index)}</Title>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    user: state.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getBalanceListHandler: getBalancelist,
  validateTokenHandler: validateToken,
};

const PurchaseAdvertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseAdverts);

export default withStyles(styles, { injectTheme: true })(
  PurchaseAdvertsContainer,
);
