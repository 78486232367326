import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getDocumentsAPI = () => {
  const path = '/advertiser/v1/application-document';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const getDocumentById = (id) => {
  const path = `/advertiser/v1/application-document/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

export { getDocumentsAPI, getDocumentById };
