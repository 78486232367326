import React, { Component } from 'react';
import {
  Card, PageHeader, Button
} from 'antd';

import ApplicantSavedPageComponent from '../../../components/pages/dashboard/applicants/ApplicantSavedPageComponent';

class ApplicantSavedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/applicants/list/');
  }

  onClickApplicantSaved = () => {
    this.props.history.push('/applicants/saved-list');
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Applicant Management'
        />
        <Card
          style={{ minHeight: 360 }}
          title='Saved Applicant List'
          extra={[
            <Button key="3" onClick={this.onClickApplicantSaved} type="primary">Saved List</Button>,
            <Button key="1" onClick={this.onClickShowAll}>Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <ApplicantSavedPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default ApplicantSavedPage;
