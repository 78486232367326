import { workplaceType, authActionType } from '../type';
import {
  getWorkplaceProfileAPI,
  updateWorkplaceProfileAPI,
  createWorkplaceProfileAPI,
  getWorkplaceProfileListAPI
} from '../../services/workplace';

const getWorkplaceProfileList = () => {
  return (dispatch) => {
    return getWorkplaceProfileListAPI()
      .then((data) => {
        dispatch({
          type: workplaceType.GET_WORKPLACE_LIST,
          workplaceProfileList: data.data
        });
      }).catch(error => { });
  }
}

const getWorkplaceProfile = (id) => {
  return (dispatch) => {
    return getWorkplaceProfileAPI(id)
      .then((data) => {
        dispatch({
          type: workplaceType.GET_WORKPLACE,
          workplaceProfile: data.data
        });
      }).catch(error => { });
  }
}

const updateWorkplaceProfile = (id, param) => {
  return (dispatch) => {
    return updateWorkplaceProfileAPI(id, param)
      .then((data) => {
        dispatch({
          type: workplaceType.UPDATE_WORKPLACE,
          workplaceProfile: data.data
        });
      }).catch(error => { });
  }
}

const createWorkplaceProfile = (param) => {
  return (dispatch) => {
    return createWorkplaceProfileAPI(param)
      .then((data) => {
        dispatch({
          type: workplaceType.CREATE_WORKPLACE,
          workplaceProfile: data.data
        });
        dispatch({
          type: authActionType.UPDATE_WORKPLACE_ID,
          workplaceProfileId: data.data.id
        });
      }).catch(error => { });
  }
}

export {
  getWorkplaceProfile,
  updateWorkplaceProfile,
  createWorkplaceProfile,
  getWorkplaceProfileList
}