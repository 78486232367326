import {
  // UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_USER,
  UPDATE_NEW_CLIENT,
  DATA_COUNTRIES,
  DATA_CLIENT_STATUS
  // UPDATE_CURRENT_USER_ID,
  // UPDATE_CURRENT_USER_LOG_OUT, UPDATE_ECONOMIC_CALENDAR
} from '../actions/app_state';

const initialState = {
  current_user_id: null,
  current_client_id: null,
  current_client_record: {},
  current_user_record: {},
  client_access: [],
};

function app_state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return { ...state, current_user_id: action.user_id,
        current_client_id: action.client_id,
        // current_user_aws_cognito_id: action.aws_cognito_id,
        current_client_record: action.current_client_record,
        current_user_record: action.current_user_record,
        client_access: action.client_access,};
    case UPDATE_NEW_CLIENT:
      return { ...state, // current_user_id: action.user_id,
        current_client_id: action.client_id,
        // current_user_aws_cognito_id: action.aws_cognito_id,
        current_client_record: action.current_client_record,
        // current_user_record: action.current_user_record,
        // client_access: action.client_access,
      };
    case DATA_COUNTRIES:
      return { ...state, data_countries_list: action.countries,};
    case DATA_CLIENT_STATUS:
      return { ...state, data_client_status_list: action.data_client_status_list,};

    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default app_state;
