import React from 'react';
import {
  Card, PageHeader, Button
} from 'antd';
import { useHistory } from 'react-router-dom';

import JobListPageComponent from '../../../components/pages/dashboard/jobs/JobListPageComponent';

const JobListPage = () => {
  const history = useHistory();

  const onClickShowAll = () => {
    history.push('/jobs/current/');
  }

  const onClickClosedJobs = () => {
    history.push('/jobs/closed');
  }

  const onClickNewJobs = () => {
    history.push('/jobs/new');
  }

  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Jobs Management'
      />
      <Card
        style={{ minHeight: 360 }}
        title='Current Job List'
        extra={[
          <Button key="3" onClick={onClickNewJobs}>Create New</Button>,
          <Button key="2" onClick={onClickClosedJobs}>Closed Jobs</Button>,
          <Button key="1" onClick={onClickShowAll} type="primary">Show All</Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <JobListPageComponent />
        </div>
      </Card>
    </div>

  );
}

export default JobListPage;
