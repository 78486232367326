
import React, {useMemo} from 'react';
import {
  Row, Col, Form, Input, Slider, Switch, Typography, Space,
} from 'antd';
const { Title, Paragraph, Text } = Typography;

function JobDetails ({ currentJob }) {
  
  const marks = useMemo(() => {
    const result = {};
    for (let i = 0; i <= 40; i ++) {
      result[i * 5000] = i % 5 === 0 ? `${5 * i}${i > 0 ? ',000' : ''}` : '';
    }

    return result;
  }, []);

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Part Time work available">
              <Space size={8}>
                <Switch defaultChecked checked={currentJob.work_option_part_time} />
                <Text>{currentJob.work_option_part_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Full Time work available">
              <Space size={8}>
                <Switch defaultChecked checked={currentJob.work_option_full_time} />
                <Text>{currentJob.work_option_full_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Casual work available">
              <Space size={8}>
                <Switch defaultChecked checked={currentJob.work_option_casual} />
                <Text>{currentJob.work_option_casual ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Work from home option available">
              <Space size={8}>
                <Switch defaultChecked checked={currentJob.remote_work} />
                <Text>{currentJob.remote_work ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>

            <Form.Item
              label="Salary range"
              style={{ marginBottom: 0 }}
            >
              <Slider
                marks={marks}
                step={5000}
                range
                max={200000}
                defaultValue={[0, 0]}
                value={[currentJob?.salary_low, currentJob?.salary_high]}
              />

            </Form.Item>

            <Form.Item
              label="Salary low"
              style={{ marginBottom: 0 }}
            >
              <Input
                defaultValue={0}
                id="salary_low"
                value={currentJob.salary_low}
              />
            </Form.Item>
            <Form.Item
              label="Salary high"
              style={{ marginBottom: 0 }}
            >
              <Input
                id="salary_high"
                defaultValue={10000}
                value={currentJob.salary_high}
              />
            </Form.Item>

          </Col>
          <Col xs={12}>
            <Title level={5}>Job Tasks</Title>
            {
              currentJob.tasks && currentJob.tasks.map(item => (
                <Paragraph>{item.task}</Paragraph>
              ))
            }
            <Title level={5}>Job Responsibilities</Title>
            {
              currentJob.responsibilities && currentJob.responsibilities.map(item => (
                <Paragraph>{item.responsibility}</Paragraph>
              ))
            }
            <Title level={5}>Job Benefits</Title>
            {
              currentJob.benefits && currentJob.benefits.map(item => (
                <Paragraph>{item.benefit}</Paragraph>
              ))
            }
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default JobDetails;
