import React, { useMemo } from 'react';

function ApplicantProfileTap(props) {
  const { applicantProfile } = props;
  const { profileHtml } = applicantProfile;

  const html = useMemo(() => {
    const address = `
      ${applicantProfile?.street_address_line_1 ? `<div>${applicantProfile?.street_address_line_1}</div>` : ''}
      ${applicantProfile?.street_address_line_2 ? `<div>${applicantProfile?.street_address_line_2}</div>` : ''}
      ${applicantProfile?.street_address_suburb ? applicantProfile?.street_address_suburb + '. ' : ''}
      ${applicantProfile?.street_address_state ? applicantProfile?.street_address_state + '. ' : ''}
      ${applicantProfile?.street_address_postcode ? applicantProfile?.street_address_postcode : ''}
      ${applicantProfile?.street_address_country_id ? `<div>${applicantProfile?.street_address_country_id}</div>` : ''}
    `;

    return `
      <div>
        <span style="font-size: 18px; margin-right: 15px;">
          <strong>${applicantProfile?.public_fileBy}</strong>
        </span>  
      </div>
      <table style="margin-top: 15px;">
        <tr>
          <td>Name</td>
          <td style="padding: 5px 20px;">${applicantProfile?.first_name} ${applicantProfile?.last_name}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td style="padding: 5px 20px;">${applicantProfile?.email}</td>
        </tr>
        <tr>
          <td style="padding: 5px 0px; vertical-align: baseline">Address</td>
          <td style="padding: 5px 20px;">
            ${address.trim() ? address : 'None Provided'}
          </td>
        </tr>
        <tr>
          <td>Telephone Mobile</td>
          <td style="padding: 5px 20px;">${applicantProfile?.telephone_mobile || ''}</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my profile visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${applicantProfile?.visible_search ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${applicantProfile?.visible_applications ? 'YES' : 'NO'}</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my Pitch Video visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${applicantProfile?.video_visible_search ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${applicantProfile?.video_visible_applications ? 'YES' : 'NO'}</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Preferred Work Options</h3>
      <table>
        <tr>
          <td>Work from home</td>
          <td style="padding: 5px 20px;">${applicantProfile?.available_from_home ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>Full time</td>
          <td style="padding: 5px 20px;">${applicantProfile?.available_full_time ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>Part time</td>
          <td style="padding: 5px 20px;">${applicantProfile?.available_part_time ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>Flexible hours</td>
          <td style="padding: 5px 20px;">${applicantProfile?.available_flexible ? 'YES' : 'NO'}</td>
        </tr>
        <tr>
          <td>Willing to Relocate</td>
          <td style="padding: 5px 20px;">${applicantProfile?.available_willing_relocate ? 'YES' : 'NO'}</td>
        </tr>
      </table>
      <table style="margin-top: 15px;">
        <tr>
          <td>Salary Range:</td>
          <td style="padding: 5px 20px;">${(applicantProfile?.target_salary_min * 1000).toLocaleString()}-${(applicantProfile?.target_salary_max * 1000).toLocaleString()} p/a</td>
        </tr>      
        <tr>
          <td>Platinum Tick™Jobs Only</td>
          <td style="padding: 5px 20px;">${applicantProfile?.platinum_tick_only ? 'YES' : 'NO'}</td>
        </tr>
      </table>
      <div style="margin-top: 15px;">
        <video src="${applicantProfile.pitch_video_file_id?.name}" controls="true"></video>
      </div>
    `;
  }, [applicantProfile]);

  return (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );
}

export default ApplicantProfileTap;
