import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const ApplicationArchivePageComponent = (props) => {
  const { applications } = props;
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (applications.length > 0) {
      setApplicationList(applications.filter(item => item.archived === 1));
    }
  }, [applications]);

  const columns = [
    {
      title: 'Applicant',
      render: (text, record) => (
        <span>
          {`${record.applicantId.id} - ${record.applicantId.first_name} ${record.applicantId.last_name}`}
        </span>
      ),
    },
    {
      title: 'Job',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/jobs/view/${record.job_id}?tab=job_summary`} >{record.jobId?.job_title}</NavLink>
        </span>
      ),
    },
    {
      title: 'Date/Time',
      dataIndex: 'application_date_time',
      render: (text) => <div>{moment(text).format('DD-MM-YYYY')}</div>
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/applications/view/${record.id}`} >View</NavLink>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={applicationList}
          rowKey="id"
        />
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    applications: state.application,
  }
};

const mapDispatchToProps = ({});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationArchivePageComponent);
