import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const checkoutProductAPI = (params) => {
  const path = `/advertiser/v1/payment/checkout`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getBalancelistAPI = (id) => {
  const path = `/advertiser/v1/payment/balances/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getBillingHistoryListAPI = (id) => {
  const path = `/advertiser/v1/payment/billing-history/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  checkoutProductAPI,
  getBalancelistAPI,
  getBillingHistoryListAPI
};