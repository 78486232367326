import React, {
  useState,
  useEffect
} from 'react';

import { Tabs, notification } from 'antd';
import { connect } from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';

import { getAdvertiser, updateAdvertiser } from '../../../../redux/actions/advertiser';

import UserSummary from './userprofile_view/UserSummary';
import UserContact from './userprofile_view/UserContact';
import UserAddress from './userprofile_view/UserAddress';

import queryString from 'query-string';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

function UserProfilePageComponent(props) {
  const { getAdvertiserHandler, advertiser, user, updateAdvertiserHandler, countries } = props;
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setState] = useState('');

  useEffect(() => {
    if (user && user.id) {
      getAdvertiserHandler(user.advertiser_id);
    }
  }, []);

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search);
    setState(activeTab);
  }, [location.search]);
  
  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message
    });
  };

  const updateAdvertiserInfo = (updateData) => {
    updateAdvertiserHandler(user.id, updateData)
      .then(data => {
        if (data) {
          openNotificationWithIcon('success', 'Save Success', 'User Info Updated.');
        } else {
          openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
        }
      }).catch(error => {
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
      })
  };

  const onTabClick = e => {
    history.push(`/settings/user-profile/?tab=${e}`);
  };

  return (
    <React.Fragment>
      <Tabs activeKey={activeTab} onTabClick={onTabClick} defaultActiveKey="1" onChange={callback}>
        <TabPane tab="User Summary" key="summary">
          <UserSummary
            advertiser={advertiser}
            updateAdvertiserHandler={updateAdvertiserInfo} />
        </TabPane>
        <TabPane tab="Contact" key="contact">
          <UserContact
            advertiser={advertiser}
            updateAdvertiserHandler={updateAdvertiserInfo} />
        </TabPane>
        <TabPane tab="Address" key="address">
          <UserAddress
            advertiser={advertiser}
            countries={countries}
            updateAdvertiserHandler={updateAdvertiserInfo} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    advertiser: state.advertiser,
    user: state.auth.currentUser,
    countries: state.countries
  }
}

const mapDispatchToProps = ({
  getAdvertiserHandler: getAdvertiser,
  updateAdvertiserHandler: updateAdvertiser
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePageComponent);
