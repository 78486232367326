
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';

// import { API, Auth } from "aws-amplify";
import { API } from 'aws-amplify';

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

import { Typography } from 'antd';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = theme => ({
  form_label: {fontWeight: theme.label_font_weight},
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
})

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const user = await this.getUserProfile(
      this.props.app_state.current_client_id
    );
    console.log(user)
    this.setState({...user.payload})
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps)
    if (nextProps.app_state.current_client_id && nextProps.app_state.current_user_aws_cognito_id) {
      const user = await this.getUserProfile(
        nextProps.app_state.current_client_id
      );
      console.log(user)
      this.setState({...user.payload})
    }
  }

  async getUserProfile(advertiser_id) {
    return API.get('advertisers', `/get/id/${advertiser_id}`);
  }

  async saveUserProfile(user) {
    await API.put('advertisers', `/update/${this.state.id}`, {body: user});
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
    //this.props.updateNewClientCreation(event.target.id, event.target.value);

  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // aws_cognito_id,
      // first_name,
      // middle_name,
      // last_name,
      // email,
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    } = this.state;
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        // first_name: first_name,
        // middle_name: middle_name,
        // last_name: last_name,
        tel_work,
        tel_home,
        tel_mobile,
        email_secondary
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveUserProfile({
          // aws_cognito_id: aws_cognito_id,
          //first_name: first_name,
          // middle_name: middle_name,
          //last_name: last_name,
          tel_work,
          tel_home,
          tel_mobile,
          email_secondary
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);
    const { classes } = this.props;

    // if (!this.props.app_state.current_client_id) {
    //   return null;
    // }

    return (
      <React.Fragment>
        <p>Billing Details Page Component</p>
        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
            </Button>
          </Col>
        </Row>
 
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewClientCreation: (key, value) => {
    //   dispatch(updateNewClientCreation(key, value))
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const BillingDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetails);

export default withStyles(styles, { injectTheme: true })(BillingDetailsContainer);

