import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getWorkplaceProfileListAPI = () => {
  const path = `/advertiser/v1/workplace-profile`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getWorkplaceProfileAPI = (id) => {
  const path = `/advertiser/v1/workplace-profile/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateWorkplaceProfileAPI = (id, params) => {
  const path = `/advertiser/v1/workplace-profile/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const createWorkplaceProfileAPI = (params) => {
  const path = `/advertiser/v1/workplace-profile/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export {
  getWorkplaceProfileAPI,
  updateWorkplaceProfileAPI,
  createWorkplaceProfileAPI,
  getWorkplaceProfileListAPI
};