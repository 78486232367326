import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageHeader } from 'antd';

import DashboardPageComponent from '../../components/pages/dashboard/dashboard/DashboardPageComponent';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title={(
            <div className="yhg">
              <FormattedMessage id="dashboard_page.page_title"/>
              <div>Welcome to Platinumjobseeker<sup>TM</sup></div>
            </div>
          )}
        />
        <DashboardPageComponent />
      </div>

    );
  }
}

export default DashboardPage;
