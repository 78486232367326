const SIGN_IN = 'LOGISIGN_INN';
const SIGN_UP = 'SIGN_UP';
const SIGN_OUT = 'SIGN_OUT';
const AUTH_VALID = 'AUTH_VALID';
const UPDATE_WORKPLACE_ID = 'UPDATE_WORKPLACE_ID';

export default {
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,
  AUTH_VALID,
  UPDATE_WORKPLACE_ID,
}
