import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Table, Spin} from 'antd';
import {NavLink} from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons'
import moment from 'moment';

const Applications = (props) => {

  const {applications, jobId} = props;
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (applications.length > 0) {
      setApplicationList(applications.filter(item => parseInt(item.job_id) === parseInt(jobId)) || []);
    }
  }, [applications]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Application Made',
      dataIndex: 'application_date_time',
      render: (text) => <div>{moment(text).format('DD-MM-YYYY')}</div>
    },
    {
      title: 'Applicant',
      render: (text, record) => (
        <span>{`${record.applicantId.id} - ${record.applicantId.first_name} ${record.applicantId.last_name}`}</span>
      )
    },
    
    {
      title: 'Application Viewed',
      dataIndex: 'viewed_by_advertiser_datetime',
      render: (text) => text ? (<div>{ moment(text).format('DD-MM-YYYY') }</div>) : <div />
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/applications/view/${record.id}`}><EyeOutlined /></NavLink>
        </span>
      )
    }
  ];

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={applicationList} rowKey='id' />
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    applications: state.application
  };
};

const mapDispatchToProps = ({});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
