import React from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import {
  Form, Input, Card, Button, notification
} from 'antd';
import { Redirect } from 'react-router-dom';
import { signUp } from '../../../redux/actions/auth';
import { updateAdvertiserRegisterRecordsAPI } from '../../../services/logging';

const styles = {
  root: {
    padding: 0,
    paddingTop: 24,
    background: '#fff',
    minHeight: 360,
    '@media (min-width: 800px)': {
      padding: 0,
      paddingTop: 24,
    },
    '@media (min-width: 1000px)': {
      padding: 0,
      paddingTop: 24,
    },
    '@media (min-width: 1200px)': {
      padding: 24,
    },
  },
  main_card: {
    width: '100%',
    '@media (min-width: 800px)': {
      width: '80%',
    },
    '@media (min-width: 1000px)': {
      width: '80%',
    },
    '@media (min-width: 1200px)': {
      width: '50%',
    },
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function RegisterPageComponent(props) {
  const { classes, signUpHandler } = props;
  const [form] = Form.useForm();

  const handleSubmit = async event => {
    const values = await form.validateFields();
    openNotificationWithIcon('info', 'Registration', 'Registration is in process')
    signUpHandler({
      email: values.email,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
    }).then(data => {
      if (data) {
        updateAdvertiserRegisterRecordsAPI(
          data.user.advertiser_id, {
            createdDatetime: new Date()
          }
        )
        openNotificationWithIcon('success', 'Record Created', 'New client has been created.');
        return <Redirect to='/login' />
      }
      openNotificationWithIcon('warning', 'Registration Failure', 'Registration has not been successful')

    }).catch(error => { });
  };

  return (
    <div className={classes.root}>
      <Card
        className={classes.main_card}
        actions={[
          <div style={{ float: 'right', marginRight: 24 }}>
            <Button
              type="primary"
              onClick={handleSubmit}
            >Register Now</Button></div>,
        ]}
      >
        <React.Fragment>
          <Form
            form={form}
            {...formItemLayout}
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'First Name is required',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Last Name is required',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Username is required',
                },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                () => ({
                  validator(_, value) {
                    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/;
                    if (!re.test(String(value))) {
                      return Promise.reject(
                        new Error(
                          '8 characters long, including at least 1 uppercase, lowercase and number',
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'Password and Confirm password has to be the same',
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
          </Form>
        </React.Fragment>
      </Card>
    </div>
  );
}

const mapDispatchToProps = ({
  signUpHandler: signUp,
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(RegisterPageComponent));
