import { API } from "aws-amplify";

export const LANGUAGE_SET_ID = "LANGUAGE_SET_ID ";

export function setLanguageSelectId(id) {
  return {
    type: LANGUAGE_SET_ID,
    value: id.toString().toLowerCase()
  };
}


