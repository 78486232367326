import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  Typography, Row, Col, Space, Tabs, Button, notification, Spin, Card, Modal,
} from 'antd';
import {useParams, useHistory} from 'react-router-dom';
import {
  viewApplicationAPI, updateApplicationAPI, getApplicationAPI
} from '../../../../services/application';
import {
  JobDetailTap, ViewApplicationTap, ApplicantProfileTap
} from './ApplicationViewJobTap';
import './style.css';
import { Editor } from 'react-draft-wysiwyg';
import {
  convertToRaw, EditorState, ContentState
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import withStyles from 'react-jss';

const {Text} = Typography;
const {TabPane} = Tabs;

const styles = {
  traffic: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 100,
    padding: 15,
    '& img': {
      zIndex: 10,
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  back: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  }
};

const ApplicationViewPageComponent = props => {
  const [active, setActive] = useState(false);
  const [application, setApplicationInfo] = useState({jobId: {}, applicantId: {}, applicantProfileId: {}});
  const {application_id} = useParams();
  const [loading, setLoading] = useState(false);
  const { applications, classes } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const history = useHistory();

  useEffect(() => {
    if (applications.length > 0 && application_id) {
      setLoading(true);
      viewApplicationAPI({application_id: application_id}).then(data => {
        setApplicationInfo(data.data);
        if (data.data.notes) {
          const contentBlock = htmlToDraft(data?.data?.notes);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        }
        setActive(true);
        setLoading(false);
      });
    }
  }, [application_id, applications]);

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message
    });
  };
  if (!application) {
    openNotificationWithIcon('error', 'Application View', 'That application ID does not exist!');
    history.push('/applications/list');
    return false;
  }
  const {jobId, applicantProfileId} = application;

  const handleNoteSave = () => {
    setLoading(true);
    updateApplicationAPI(application_id, application)
      .then(() => {
        openNotificationWithIcon('success', 'Save Notes', 'Notes saved successfully');
        setLoading(false);
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Save Notes', 'An error occurred in notes saving');
        setLoading(false);
      });
  };
  const confirmModal = (type) => {
    const getMessage = () => {
      if (type === 1) {
        return 'We will notify the jobseeker of your decision that their application was unsuccessful.';
      }
      if (type === 2) {
        return 'We will notify the jobseeker that their application will be responded to by you or by our site, depending on your further decision';
      }
      return 'Setting to “Green” de-links PlatinumJobSeeker™ from the process and requires that you contact the jobseeker directly with your process and with your final decision. We will notify the jobseeker that their application will receive a response from you.';
    };

    return Modal.confirm({
      title: 'Confirm',
      content: getMessage(),
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        updateApplicationAPI(application_id, { trafficLightId: type })
          .then(() => {
            getApplicationAPI(application_id).then(res => {
              setApplicationInfo(res.data);
            })
          })
      },
      onCancel() {
      }
    });
  };

  const onChangeTraffic = value => {
    confirmModal(value);
  };

  const onEditorStateChange = (edit) => {
    setEditorState(edit);

    const htmlText = draftToHtml(convertToRaw(edit.getCurrentContent()));
    setApplicationInfo((prev) => ({
      ...prev,
      notes: htmlText,
    }));
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        {active ? (
          <Row gutter={[40, 20]}>
            <Col span={9}>
              <div className={classes.wrapper}>
                <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                  <Space direction='vertical'>
                    <Text draggable="true" style={{fontSize: 24, color: '#21B466', fontWeight: 'bold'}}>{jobId.job_title}</Text>
                  </Space>

                  <Row span={24}>
                    <Col span={12}>
                      <Space direction='vertical' style={{marginTop: 30}}>
                        <Text style={{fontSize: 15, fontWeight: 'bold'}}>Applicant</Text>
                        <Text>{`${applicantProfileId?.first_name} ${applicantProfileId?.last_name}`}</Text>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space direction='vertical' style={{marginTop: 30}}>
                        <Text style={{fontSize: 15, fontWeight: 'bold'}}>Current Status</Text>
                        <Text style={{ color: application?.trafficLight ? '#' + application?.trafficLight?.color : '#000' }}>{application?.trafficLight ? application?.trafficLight.fileBy : ''}</Text>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </div>

              <Space direction='vertical' style={{marginTop: 30}}>
                <Text style={{fontSize: 15, fontWeight: 'bold'}}>Application Notes</Text>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    }
                  }}
                />
              </Space>
              <div style={{marginTop: 10, justifyContent: 'flex-end', display: 'flex'}}>
                <Button type='primary' onClick={handleNoteSave}>
                  Save
                </Button>
              </div>
            </Col>
            <Col span={15}>
              <Card>
                <Space>
                  <Tabs defaultActiveKey='1'>
                    <TabPane tab='Job Details' key='1'>
                      <JobDetailTap jobData={jobId} />
                    </TabPane>
                    <TabPane tab='Application' key='2'>
                      <ViewApplicationTap application={application} />
                    </TabPane>
                    <TabPane tab='Applicant Profile' key='3'>
                      <ApplicantProfileTap applicantProfile={applicantProfileId} />
                    </TabPane>
                  </Tabs>
                  <div className={classes.traffic}>
                    <img className={classes.back} src={require('../../../../assets/img/traffic/back.png')} width={100} alt='back' />
                    <img
                      onClick={() => onChangeTraffic(1)}
                      onDrop={() => onChangeTraffic(1)}
                      onDragOver={allowDrop}
                      src={require('../../../../assets/img/traffic/red.png')} width={60} alt='red'
                    />
                    <img
                      onClick={() => onChangeTraffic('yellow')}
                      onDrop={() => onChangeTraffic(2)}
                      onDragOver={allowDrop}
                      src={require('../../../../assets/img/traffic/yellow.png')} width={60} alt='yellow'
                    />
                    <img
                      onClick={() => onChangeTraffic('green')}
                      onDrop={() => onChangeTraffic(3)}
                      onDragOver={allowDrop}
                      src={require('../../../../assets/img/traffic/green.png')} width={60} alt='green'
                    />
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>
        ) : (
          <Text>No Active Application ID</Text>
        )}
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    applications: state.application
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationViewPageComponent));
