import APIUtils from '../../helpers/api/utils';
import { loginAPI, signupAPI, validateTokenAPI } from '../../services/auth';
import { authActionType } from '../type';

const signIn = (userData) => {
	return (dispatch) => {
		return loginAPI(userData)
			.then((data) => {
				const { token } = data;
				APIUtils.setAccessToken(token);

				if (data) {
					return dispatch({
						type: authActionType.SIGN_IN,
						user: data.user
					});
				} else throw new Error(data.message);
			})
			.catch(error => {
				throw new Error(error)
			})
	}
}

const signUp = (stateUser) => {
	return (dispatch) => {
		return signupAPI(stateUser)
			.then((data) => {
				const { token } = data;
				APIUtils.setAccessToken(token);

				if (data) {
					return dispatch({
						type: authActionType.SIGN_UP,
						user: data.user
					});
				} else throw new Error(data.message);
			})
			.catch(error => {
				throw new Error(error)
			})
	}
}

/* Token Validation Action */
const validateToken = () => {
	const token = APIUtils.getAccessToken();
	return (dispatch) => {
		return validateTokenAPI({token})
			.then((data) => {
				const { isvalid, user } = data;
				return dispatch({
					type: authActionType.AUTH_VALID,
					isAuthenticated: isvalid,
					user: user
				});
			})
			.catch(error => {
				APIUtils.setAccessToken(null);
				return false
			})
	}
}

const signOut = () => {
	APIUtils.setAccessToken(null);
	return {
		type: authActionType.SIGN_OUT,
		user: {}
	}
}

export {
	signIn,
	signOut,
	signUp,
	validateToken
}