// import { API } from 'aws-amplify';
import { Auth } from "aws-amplify";
import React, { Component } from 'react';
// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
import RegisterPageComponent from '../../components/pages/pages/RegisterPageComponent';




class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultStateSample: "",
      collapsed: false,
      email: "",
      password: "",
    }
    // this.onChange = this.onChange.bind(this); //if use arrow funtion, doesn't need this
  }

  // toggle = () => {
  //   this.setState({
  //     collapsed: !this.state.collapsed,
  //   });
  // };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  handleChange = event => {
    // console.log(event);
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    console.log(event);
    console.log(this.state.email)
    console.log(this.state.password);
    try {
      let auth = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      console.log(auth);
      this.props.userHasAuthenticated(true);

    } catch (e) {
      // alert(e.message);
      console.log(e);
    }
  }

  handleLogout = async event => {
    console.log(event);
    this.props.handleLogout();
  }

  render() {
    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <RegisterPageComponent />
      </div>
    );
  }
}

export default RegisterPage;
