import React, {
  useState, useEffect, useMemo
} from 'react';
import { connect } from 'react-redux';
import {
  Switch, Redirect, NavLink
} from 'react-router-dom';
import {
  Layout, Menu, Icon, Row, Col, Space, Steps
} from 'antd';

import dashboardRoutes from '../routes/dashboard.jsx';
import AuthenticatedRoute from '../components/routes/AuthenticatedRoute';
import DashboardHeaderContainer from '../components/layout/dashboard/DashboardHeader.jsx';
import {
  DashboardOutlined,
  BankOutlined,
  ContainerOutlined,
  MailOutlined,
  QuestionOutlined,
  SettingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import Welcome from '../components/welcome/welcome';
import '../style/sidebar.scss';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Step } = Steps;

const Dashboard = ({
  isAuthenticated,
  current_user,
  reload_user,
  userHasAuthenticated,
  handleLogout,
  user,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [hideWelcome, setHideWelcome] = useState(localStorage.getItem('STATUS_REQUIRE_WELCOME') !== 'true');

  useEffect(() => {
    if (!hideWelcome) {
      const timer = setTimeout(() => {
        setHideWelcome(true);
      }, 4 * 1000);

      return () => {
        clearTimeout(timer);
        localStorage.setItem('STATUS_REQUIRE_WELCOME', 'false');
      }
    }
  }, [hideWelcome]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onClick = () => {
    window.location.assign('/datePickerSample');
  };

  const switchRoutes = () => {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated,
                    current_user,
                    reload_user,
                  }}
                />
              );
            });
          }
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated,
                current_user,
                reload_user,
              }}
            />
          );
        })}
      </Switch>
    );
  };

  const generate_sidebar = () => {
    return (
      <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item key="1" icon={<DashboardOutlined />}>
          <NavLink to="/">
            <Icon type="user" />
            <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="workplace_profiles" icon={<BankOutlined />}>
          <NavLink to="/workplace-profiles/list">
            <Icon type="user" />
            <span> Workplace Profiles</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="headhunting" icon={<BankOutlined />}>
          <NavLink to="/headhunting/list">
            <Icon type="user" />
            <span>Head Hunting</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          key="jobs_sub"
          icon={<ContainerOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Jobs</span>
            </span>
          }
        >
          <Menu.Item key="jobs_current">
            <NavLink to="/jobs/current">Current Jobs</NavLink>
          </Menu.Item>
          <Menu.Item key="jobs_new">
            <NavLink to="/jobs/new">Create Job</NavLink>
          </Menu.Item>
          <Menu.Item key="jobs_closed_list">
            <NavLink to="/jobs/closed">Closed</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="applications_sub"
          icon={<MailOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Applications</span>
            </span>
          }
        >
          <Menu.Item key="application_current_list">
            <NavLink to="/applications/current">Current</NavLink>
          </Menu.Item>
          <Menu.Item key="application_red_list">
            <NavLink to="/applications/red">Red</NavLink>
          </Menu.Item>
          <Menu.Item key="application_saved_list">
            <NavLink to="/applications/archived">Archived</NavLink>
          </Menu.Item>
        </SubMenu>
        {/* <SubMenu
          key="applicants_sub"
          icon={<TeamOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Applicants</span>
            </span>
          }
        >
          <Menu.Item key="applicants_list">
            <NavLink to="/applicants/list">Search</NavLink>
          </Menu.Item>
          <Menu.Item key="applicants_saved_list">
            <NavLink to="/applicants/saved-list">Saved</NavLink>
          </Menu.Item>
        </SubMenu> */}
        <Menu.Item
          key="Placing a PlatinumTickTM ad"
          icon={<CheckCircleOutlined />}
        >
          <NavLink to="/placing-a-platinumtick-ad">
            <Icon type="user" />
            <span>Placing a Platinum Tick™ ad</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          key="settings_sub"
          icon={<SettingOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Settings</span>
            </span>
          }
        >
          {/* <Menu.Item key="advertisers_view">
            <NavLink to={'/settings/advertiser/?tab=advertiser_summary'}>
              Advertiser
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key="account_view">
            <NavLink to="/settings/account/1?tab=my_account">Account</NavLink>
          </Menu.Item>
          <Menu.Item key="user_profile_sub">
            <NavLink to="/settings/user-profile/?tab=summary">
              <Icon type="user" />
              <span>User Profile</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  const currentStep = useMemo(() => {
    let step = 0;
    if (user && user.advertiser) {
      if (user.advertiser.startup_step1) {
        step ++;
      }
      if (user.advertiser.startup_step2) {
        step ++;
      } else {
        return step;
      }

      if (user.advertiser.startup_step3) {
        step ++;
      } else {
        return step;
      }

      if (user.advertiser.startup_step4) {
        step ++;
      }

      return step;
    }
    return 1;
  }, [user]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!hideWelcome && (
        <Welcome />
      )}
      <Space direction="vertical" style={{ backgroundColor: 'white' }}>
        <div className="logo" />
        <img
          src={require(!collapsed
            ? '../assets/img/brand/PlatinumJobSeeker_300px.png'
            : '../assets/img/brand/TickWithBorder_50px.png')}
          alt=""
          target="_blank"
          style={{
            paddingTop: 16,
            marginBottom: 16,
            maxHeight: 64,
            maxWidth: 200,
            marginLeft: collapsed ? 15 : 0,
            width: collapsed ? 50 : '100%',
          }}
        />
        <Sider
          theme="light"
          style={{ minHeight: '100vh' }}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          {generate_sidebar()}
        </Sider>
      </Space>

      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <DashboardHeaderContainer
            isAuthenticated={isAuthenticated}
            userHasAuthenticated={userHasAuthenticated}
            handleLogout={handleLogout}
          />
        </Header>
        <Layout>
          <Content style={{ margin: '16px' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              <Steps current={currentStep} style={{ marginTop: 16 }}>
                <Step title="Register" />
                <Step title={<NavLink to="/workplace-profiles/new">Create Workplace Profile</NavLink>} />
                <Step title={<NavLink to="/settings/account/1?tab=purchase_adverts">Purchase Credits</NavLink>} />
                <Step title={<NavLink to="/jobs/new">Place a Job</NavLink>} />
              </Steps>
              <div>{switchRoutes()}</div>
            </div>
          </Content>
        </Layout>
        <Footer style={{ textAlign: 'left' }}>
          <img
            src={require('../assets/img/brand/TickWithBorder_20px.png')}
            alt="tick-border"
          />
          <strong>
            Platinumjobseeker<sup>TM</sup>
          </strong>{' '}
          Advertiser Portal © 2020-2022
          <br />
          <small>Version 1.0.0</small>
          <br/>
          <small style={{ textAlign: 'left' }}>
            <a
              href={'/legal/terms-conditions'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            |{' '}
            <a
              href={'/legal/privacy-policy'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href={'https://www.platinumjobseeker.com/faq'}
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQs
            </a>
          </small>{' '}
          <Row>
            <Col xs={24} style={{ textAlign: 'right' }}>
              <small>
                Created by{' '}
                <a
                  href="https://deliostech.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Delios Technology Inc
                </a>
              </small>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
    user: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

export default DashboardContainer;
