
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Form, Input, Typography } from 'antd';
import {
  updateNewClientCreation
} from '../../../../../redux/actions/applicants';
import CountrySelectComponent from '../../../../common/CountrySelectComponent';

const { Title } = Typography;
class NewClientWizard02 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({ [event.target.id]: event.target.value });
    this.props.updateNewClientCreation(event.target.id, event.target.value);

  };

  handleSelectChange_street_address_country = (value) => {
    this.setState({ 'street_address_country': value });
    this.props.updateNewClientCreation('street_address_country', value);
  };

  handleSelectChange_postal_address_country = (value) => {
    this.setState({ 'postal_address_country': value });
    this.props.updateNewClientCreation('postal_address_country', value);
  };

  render() {
    // console.log(this.state);
    if (!this.props.clients || !this.props.clients.new_client_data) {
      return null;
    }

    return (

      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Contact Details</Title>
              <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_home"
                  value={this.props.clients.new_client_data.tel_home}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_work"
                  value={this.props.clients.new_client_data.tel_work}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_mobile"
                  value={this.props.clients.new_client_data.tel_mobile}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email"
                  value={this.props.clients.new_client_data.email}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email_secondary"
                  value={this.props.clients.new_client_data.email_secondary}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Title level={4}>Street Address</Title>
              <Form.Item
                label="Street Address (Line 1)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="street_address_line_1"
                  value={this.props.clients.new_client_data.street_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Street Address (Line 2)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="street_address_line_2"
                  value={this.props.clients.new_client_data.street_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="street_address_suburb"
                      value={this.props.clients.new_client_data.street_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="State"
                    style={{ marginBottom: 0 }}>
                    <Input
                      id="street_address_state"
                      value={this.props.clients.new_client_data.street_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="street_address_postcode"
                      value={this.props.clients.new_client_data.street_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <CountrySelectComponent
                  id="street_address_country"
                  value={this.props.clients.new_client_data.street_address_country}
                  handleChange={this.handleSelectChange_street_address_country} />
              </Form.Item>
              <br />
              <Title level={4}>Postal Address</Title>
              <Form.Item
                label="Street Address (Line 1)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="postal_address_line_1"
                  value={this.props.clients.new_client_data.postal_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Street Address (Line 2)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="postal_address_line_2"
                  value={this.props.clients.new_client_data.postal_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="postal_address_suburb"
                      value={this.props.clients.new_client_data.postal_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="State"
                    style={{ marginBottom: 0 }}>
                    <Input
                      id="postal_address_state"
                      value={this.props.clients.new_client_data.postal_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="postal_address_postcode"
                      value={this.props.clients.new_client_data.postal_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <CountrySelectComponent
                  id="postal_address_country"
                  value={this.props.clients.new_client_data.postal_address_country}
                  handleChange={this.handleSelectChange_postal_address_country} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
  }
}

const NewClientWizard01Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClientWizard02);

export default NewClientWizard01Container;
