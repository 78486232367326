import React from 'react';

import {
  Card,
  PageHeader,
} from 'antd';

import HeadHuntingListPageComponent from '../../../components/pages/dashboard/headhunting/HeadHuntingListPageComponent';

const HeadHuntingListPage = (props) => {

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Head Hunting'
      />
      <Card
        style={{ minHeight: 360 }}
        title='Displaying Job Seeker Profiles'
      >
        <div style={{ paddingTop: 24 }}>
          <HeadHuntingListPageComponent />
        </div>
      </Card>
    </div>

  );
}

export default HeadHuntingListPage;
