import React from 'react';

import {
  Card,
  PageHeader,
} from 'antd';

import HeadHuntingPageComponent from '../../../components/pages/dashboard/headhunting/HeadHuntingPageComponent';

const HeadHuntingPage = (props) => {
  const { match } = props

  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Head Hunting Profile'
      />
      <Card
        style={{ minHeight: 360 }}
        title='Head Hunting Profile'
      >
        <HeadHuntingPageComponent id={match.params.id} />
      </Card>
    </div>

  );
}

export default HeadHuntingPage;
