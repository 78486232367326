import React, { useEffect, useState } from 'react';

import {
  Row, Col, Form
} from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import './styles.scss';

function NewJobWizard03Container(props) {
  const { newJob, setNewJob, jobClassifications, countries, workplaceProfileList, educationList, experienceList } = props;
  const jobCategory = jobClassifications?.find(
    (item) => item.id === newJob.job_classification_id,
  )?.file_by;
  const country = countries?.find(
    (item) => item.id === newJob.location_country_id,
  )?.full_name;
  const workplaceProfileName = workplaceProfileList?.find(
    (item) => item.id === newJob.workplace_profile_id,
  )?.workplace_name;
  const education = educationList?.find(
    (item) => item.id === newJob.mustHave_education,
  )?.fileBy;
  const experienceTime = experienceList?.find(
    (item) => item.id === newJob.mustHave_experienceTime,
  )?.fileBy;

  const initHtml = `
  <div style="display: flex; align-items: center;">
    <span style="font-size: 18px; color: #21B466; margin-right: 15px;">
      <strong>${newJob.job_title}</strong>
    </span>
    <img src='/platinum_tick.png' alt='' height="25" />  
  </div>
  <div >${jobCategory}</div>
  <br />
  <div style="display: flex; align-items: center">
    <em>${workplaceProfileName ? workplaceProfileName : ''}</em>  
    <a target="_blank" href="/companies/view/${newJob.workplace_profile_id}" style="margin-left: 10px;">
      <em>View advertiser profile</em>
    </a>
  </div>
  <div>
    ${newJob?.location_region}, ${country ? country : ''}
  </div>
  <br />
  <div style="font-weight: bold">
    Advertiser Contact:
  </div>
  <div>
    ${newJob?.contactName || ''}
  </div>
  <div style="color: #5B86CA; text-decoration: underline">
    ${newJob?.contactEmail || ''}
  </div>
  <div>
    Reference: ${newJob?.advertiser_reference || ''}
  </div>
  <br />
  <div style="font-weight: bold">
    Job Details:
  </div>
  <p>${newJob.short_description}</p>

  <table style="margin-top: 30px;">
    <tr>
      <td style="vertical-align: baseline;"><p>Job Ad starts on: </p></td>
      <td style="padding: 0 20px;">
        <p>${moment(newJob.job_start).format('DD/MM/YYYY')}</p>
      </td>
    </tr>
    <tr>
      <td style="vertical-align: baseline;"><p>Job Ad will expire on: </p></td>
      <td style="padding: 0 20px;">
        <p>${moment(newJob.job_expire).format('DD/MM/YYYY')}</p>
      </td>
    </tr>
  </table>
  <table style="margin-top: 10px;">
    <tr>
      <td><p>Part time work available: </p></td>
      <td style="padding: 5px 20px;"><p>${newJob.work_option_part_time ? 'Yes' : 'No'} </p></td>
    </tr>
    <tr>
      <td><p>Full time work available: </strong></p></td>
      <td style="padding: 5px 20px;"><p>${newJob.work_option_full_time ? 'Yes' : 'No'}</p></td>
    </tr>
    <tr>
      <td><p>Casual work available: </strong></p></td>
      <td style="padding: 5px 20px;"><p>${newJob.work_option_casual ? 'Yes' : 'No'}</p></td>
    </tr>
    <tr>
      <td><p>Work from home available:</p></td>
      <td style="padding: 5px 20px;"><p>${newJob.remote_work ? 'Yes' : 'No'}</p></td>
    </tr>
    <tr>
      <td><p>Salary Range: </p></td>
      <td style="padding: 5px 20px;"><p>${(newJob?.salary_low * 1000).toLocaleString()}-${(newJob?.salary_high * 1000).toLocaleString()} p/a</p></td>
    </tr>
    <tr>
      <td><p>Salary Currency: </p></td>
      <td style="padding: 5px 20px;"><p>${newJob?.salary_currency}</p></td>
    </tr>
  </table>
  
  <p>Tasks</p>
  <ul>
  ${newJob.jobTasks.map(
    (task, index) => `<li key=${task + index}>${task}</li>`
  ).join('')}
  </ul>
  <p>Responsibilities</p>
  <ul>
  ${newJob.responsibilities.map(
    (responsibility, index) =>
      `<li key=${responsibility + index}>${responsibility}</li>`
  ).join('')}
  </ul>
  <p>Benefits</p>
  <ul>
  ${newJob.benefits.map(
    (benefit, index) => `<li key=${benefit + index}>${benefit}</li>`
  ).join('')}
  </ul>
  <h3>The jobseeker must have</h3>
  <ul>
    ${education ? `<li style="margin: 5px 0">Education - ${education}</li>` : ''}
    ${experienceTime ? `<li style="margin: 5px 0">Experience Time - ${experienceTime}</li>` : ''}
    ${newJob.mustHave_rightToWork ? '<li style="margin: 5px 0">Right To Work</li>' : ''}
    ${newJob.mustHave_driversLicence ? '<li style="margin: 5px 0">Drivers Licence</li>' : ''}
    ${!newJob.mustHave_rightToWork ? '<li style="margin: 5px 0">No criminal history</li>' : ''}
    ${!newJob.mustHave_rightToWork ? '<li style="margin: 5px 0">Clear Bankruptcy Check</li>' : ''}
    <li style="margin: 5px 0">Specialist Certifications - ${newJob.mustHave_specialistCertifications ? newJob.mustHave_specialistCertifications : 'N/A'}</li>
  </ul>
`;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    setNewJob((prev) => ({
      ...prev,
      advertisement_text: initHtml,
    }));
  }, []);

  const onEditorStateChange = (edit) => {
    setEditorState(edit);

    const htmlText = draftToHtml(convertToRaw(edit.getCurrentContent()));
    setNewJob((prev) => ({
      ...prev,
      advertisement_freetext: htmlText,
      advertisement_text: initHtml + htmlText,
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item
              label="Body of Ad"
              style={{ height: '100%' }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Preview">
              <div
                className="advertisement_text"
                dangerouslySetInnerHTML={{
                  __html: newJob.advertisement_text,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard03Container;
