import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Spin } from 'antd';
import { getAdvertisementProductsAPI } from '../../../../../services/jobs';
import { types as jobTypes } from '../../account/account_view/PurchaseAdverts';

const { Option } = Select;

function JobType(props) {
  const { newJob, setNewJob, fieldStatus } = props;
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAdvertisementProductsAPI()
      .then((data) => {
        setLoading(false);
        setProductsList(data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const hanldeChnageJobType = (event) => {
    setNewJob((prev) => ({
      ...prev,
      job_type: event,
    }));
  };

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Job Type (*)">
                <Select
                  showSearch
                  style={{ width: 400 }}
                  placeholder="Select a Job Type"
                  optionFilterProp="children"
                  onChange={hanldeChnageJobType}
                  value={newJob.job_type}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {productsList?.map((item) => (
                    <Option value={item.id} key={item.advert_name}>
                      {item.advert_name}
                    </Option>
                  ))}
                </Select>
                {fieldStatus.job_type === 'error' ? (
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    Job Type is required
                  </div>
                ) : (
                  ''
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <p>
                {
                  productsList?.find((item) => item.id === newJob.job_type)
                    ?.advert_name
                }
              </p>
              <p>
                {newJob.job_type && '$'}
                {
                  productsList?.find((item) => item.id === newJob.job_type)
                    ?.retail_price
                }
              </p>
              <>
                {
                  jobTypes?.find((item) => item.id === newJob.job_type)
                    ?.description
                }
              </>
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
}

export default JobType;
