import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Select,
  Radio,
  Button, notification, Spin
} from 'antd';

import { useHistory } from 'react-router-dom';

import {updateJob} from '../../../../../redux/actions/jobs';

import moment from 'moment';
import { getStatusList } from '../../../../../redux/actions/jobs';
import CountrySelectComponent from '../../../../common/CountrySelectComponent';
const { Option } = Select;

const dateFormat = 'YYYY/MM/DD';

const JobDescriptionComponent = props => {

  const {
    updateJobsHandler,
    id,
    fieldStatus,
    currentJob,
    jobClassifications,
    getStatusListHandler,
    statusList
  } = props;

  const [job, setJob] = useState(props.currentJob);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleChange = event => {
    const {value} = event.target;
    setJob(prev => ({
      ...prev,
      ['status_id']: value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    if (id, job) {
      try {
        updateJobsHandler(id, job).then(data => {
          if (data) {
            openNotificationWithIcon('success', 'Save Success', 'Job Info Updated.');
          } else {
            openNotificationWithIcon('error', 'Save Failed', 'An error occurred in Job Info Updating.');
          }
        }).catch(error => {
          openNotificationWithIcon('error', 'Save Failed', 'An error occurred in Job Info Updating.');
          history.push('/jobs/current');
        });
      } catch (e) {
        console.log(e)
        history.push('/jobs/current');
      }
    } else {
      history.push('/jobs/current');
    }
    setIsLoading(false);
    history.push('/jobs/current');
  };

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message
    });
  };

  useEffect(() => {
    getStatusListHandler();
  }, []);

  const jobClassification = jobClassifications?.map((d, index) => <Option key={`jobClassifications-${index}`} value={d.id} >{d.file_by}</Option>);
  const jobStatusOptions = statusList.map(status => ({ label: status.file_by, value: status.id }));
  return (
    <React.Fragment>
    <Spin spinning={isLoading}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item
              label="Job Title"
              style={{ marginBottom: 0 }}
              validateStatus={fieldStatus.job_title}
              hasFeedback
              help={fieldStatus.job_title === 'error' ? 'Job Title is required' : ''}
            >
              <Input
                id="job_title"
                value={currentJob.job_title}
              />
            </Form.Item>
            <Form.Item
              label="Short Description"
              validateStatus={fieldStatus.short_description}
              hasFeedback
              help={fieldStatus.short_description === 'error' ? 'Short Description is required' : ''}
            >
              <Input
                id="short_description"
                value={currentJob.short_description}
              />
            </Form.Item>
            <Form.Item
              label="Job Category"
            >
              <Select
                id="job_classification_id"
                showSearch
                value={currentJob.job_classification_id}
                placeholder="Select Country"
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {jobClassification}
              </Select>
              {
                fieldStatus.job_classification_id === 'error'
                  ? <div className="ant-form-item-explain ant-form-item-explain-error">Job Category is required</div>
                  : ''
              }
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Job Status"
            >
              <Radio.Group
                options={jobStatusOptions}
                id="status_id"
                value={job?.status_id ? job.status_id  : currentJob.status_id }
                optionType="button"
                buttonStyle="solid"
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="Location Region"
            >
              <Input
                id="location_region"
                value={currentJob.location_region}
              />
            </Form.Item>
            <Form.Item
              label="Location Country"
            >
              <CountrySelectComponent
                id="location_country_id"
                value={currentJob.location_country_id}
              />
            </Form.Item>
            <Row>
              <Form.Item label="Job start">
                <DatePicker
                  value={moment(currentJob.job_start, dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>
              <Form.Item label="Job expire" style={{ marginLeft: 30 }}>
                <DatePicker
                  value={moment(currentJob.job_expire, dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <br />{' '}
        <Row>
          <Col span={24} style={{textAlign: 'left', paddingLeft: 0}}>
            <Button type='primary' htmlType='submit' onClick={handleSubmit}>
            Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>

    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    jobs: state.jobs,
    statusList: state.jobs.statusList
  }
};

const mapDispatchToProps = ({
  getStatusListHandler: getStatusList,
  updateJobsHandler: updateJob
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDescriptionComponent);

