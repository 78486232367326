import React, { useMemo, useState } from 'react';
import {
  Button, Card, Modal, Select, Typography
} from 'antd';
import StripeCheckout from 'react-stripe-checkout';
import { checkoutProductAPI } from '../../../../../services/payment';
import { types } from '../../account/account_view/PurchaseAdverts';

const { Title, Text } = Typography;
const { Option } = Select;
const credits = [1, 5, 10, 25, 50];

const PurchaseModal = ({ isOpen, onClose, jobType, user, onSubmit }) => {
  const [currentCredits, setCurrentCredits] = useState();
  const [loading, setLoading] = useState(false);

  const product = useMemo(() => types.find(item => item.id === jobType), [jobType]);

  const gridStyle = {
    width: '100%',
    textAlign: 'center',
  };

  const successModal = () => {
    Modal.success({
      title: 'Purchase Adverts Success',
      content: 'Payment Successful!',
      onOk() {
        onSubmit();
      },
    });
  };
  const failedModal = () => {
    Modal.error({
      title: 'Purchase Adverts Failed',
      content: 'Sorry, Payment failed!',
      onOk() {},
    });
  };

  const handleCalculatePrice = () => {
    const calPrice =
      parseInt(product.price, 10) * parseInt(currentCredits, 10);
    switch (parseInt(currentCredits, 10)) {
      case 1:
        return calPrice;
      case 5:
        return calPrice * 0.925;
      case 10:
        return calPrice * 0.9;
      case 25:
        return calPrice * 0.875;
      case 50:
        return calPrice * 0.8;
      default:
        return 0;
    }
  };

  const handleToken = () => (token) => {
    setLoading(true);
    const params = {
      description: product.name,
      source: token.id,
      currency: 'AUD',
      amount: handleCalculatePrice() * 100,
      info: {
        advertiser_id: user.advertiser_id,
        advertisement_product_id: product.id,
        balance: parseInt(currentCredits, 10),
      },
    };
    checkoutProductAPI(params)
      .then((data) => {
        successModal();
        setLoading(false);
      })
      .catch((error) => {
        failedModal();
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={false}
    >
      {
        product && (
          <div style={{ paddingTop: 16 }}>
            <Card
              title={product.name}
              style={gridStyle}
              actions={[
                <React.Fragment>
                  <Select
                    placeholder="Select Item"
                    value={currentCredits}
                    style={{ minWidth: 100 }}
                    onChange={setCurrentCredits}
                  >
                    {credits.map((credit) => (
                      <Option key={`${credit}`}>{credit}</Option>
                    ))}
                  </Select>
                  <StripeCheckout
                    stripeKey="pk_live_51HbkmJKjq9qvI530AaTruUGawufsfjxTMicZtbLvyXkiH8by5xWivNle64yzWUyYqDtpSHkPEYAVxN6kULKG3YeK00uEEBFqWk"
                    token={handleToken()}
                    amount={handleCalculatePrice() * 100}
                    description={product.name}
                    currency="AUD"
                  >
                    <Button
                      style={{ marginLeft: 20 }}
                      disabled={handleCalculatePrice() === 0}
                      type="primary"
                    >
                      Purchase Now
                    </Button>
                  </StripeCheckout>
                </React.Fragment>,
              ]}
            >
              <Text style={{ textAlign: 'left', display: 'block' }}>{product.description}</Text>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: 20,
                }}
              >
                <li>{`Purchase 1 for $${product.price}`}</li>
                <li>Purchase 5 for a 7.5% discount</li>
                <li>Purchase 10 for a 10% discount</li>
                <li>Purchase 25 for a 12.5% discount</li>
                <li>Purchase 50 for a 20% discount</li>
              </ul>
              <Title level={4}>${handleCalculatePrice()}</Title>
            </Card>
          </div>
        )
      }
    </Modal>
  );
};

export default PurchaseModal;
