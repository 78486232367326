
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { Skeleton } from 'antd';

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
import { PageHeader, Card } from 'antd';
import UserProfilePageComponent from '../../components/pages/dashboard/user_profile/UserProfilePageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);

    let main_component = <UserProfilePageComponent />;
    // if (!this.props.app_state.current_client_id) main_component = <Skeleton active />;

    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="User Profile"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your User Details"
        >
          <div style={{ paddingTop: 24 }}>
            {main_component}
            {/*<UserProfilePageComponent />*/}
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const UserProfilePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilePage);

export default UserProfilePageContainer;