import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const createLoggingAPI = (params) => {
  const path = '/advertiser/logging/create';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendRequest(params);
};

const updateAdvertiserLoggingRecordsAPI = (id, params) => {
  const path = `/advertiser/logging/advertiser-records/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
}

const updateAdvertiserRegisterRecordsAPI = (id, params) => {
  const path = `/advertiser/register/records/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
}


export {
  createLoggingAPI,
  updateAdvertiserLoggingRecordsAPI,
  updateAdvertiserRegisterRecordsAPI
};