import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setLanguageSelectId } from '../../../redux/actions/language';
import {
  DashboardOutlined, UserOutlined, LogoutOutlined
} from '@ant-design/icons';

// import { API, Auth } from "aws-amplify";
import { FormattedMessage } from 'react-intl';

import { Tooltip , Select } from 'antd';

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
import {
  PageHeader,
  Button,
  // Descriptions
} from 'antd';

import ReactCountryFlag from 'react-country-flag'

const { Option } = Select;

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handleDashboard = async event => {
    // console.log(event);
    this.props.history.push('/dashboard');
    //this.props.handleDashboard();
  }

  handleUser = async event => {
    // console.log(event);
    this.props.history.push('/settings/user-profile');
    //this.props.handleUser();
  }

  handleLogout = async event => {
    console.log(event);
    this.props.handleLogout();
  }

  render() {
    // console.log(this.state);
    // if (!this.props.app_state.current_client_record) return null;
    return (
      <React.Fragment>
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title={<div>Welcome - and thanks for using Platinumjobseeker<sup>TM</sup></div>}
          // subTitle="This is a subtitle"
          extra={this.props.isAuthenticated ? [
            
            /*
            //Comment it out in task #213 Hide language select
            <Tooltip title={<FormattedMessage id="tooltip.title_switch_language" />}>
              <Select
                defaultValue={this.props.language.language_current_ui}
                style={{ width: 180 }}
                onChange={this.handle_language_select}>
                <Option value="en">
                  <ReactCountryFlag
                    className="emojiFlag"
                    countryCode="GB"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="English Language"
                  /> English
                </Option>
                <Option value="zh">
                  <ReactCountryFlag
                    // className="emojiFlag"
                    countryCode="CN"
                    style={{
                      fontSize: '1.5em',
                      lineHeight: '1.5em',
                    }}
                    aria-label="Chinese Traditional Language"
                  /> 繁體中文
                </Option>
              </Select>
            </Tooltip>,*/
            
            <Tooltip title={<FormattedMessage id="tooltip.title_dashboard" />}>
              <Button
                key="1"
                type="primary"
                onClick={this.handleDashboard}
                icon={<DashboardOutlined />}
              >
              </Button>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_user_profile" />}>
              <Button
                key="2"
                type="primary"
                onClick={this.handleUser}
                icon={<UserOutlined />}
              >
              </Button>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_logout" />}>
              <Button
                key="3"
                type="primary"
                onClick={this.handleLogout}
                icon={<LogoutOutlined />}
              >
              </Button>
            </Tooltip>] : []}
        >
        </PageHeader>
      </React.Fragment>
    );
  }
}

// export default DashboardHeader;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const DashboardHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader);

export default withRouter(DashboardHeaderContainer);
