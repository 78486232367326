import React from 'react';
import { Card, PageHeader } from 'antd';

import ApplicationViewPageComponent from '../../../components/pages/dashboard/applications/ApplicationViewPageComponent';

function ApplicationViewPage() {
  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Application Management'
      />
      <Card
        style={{ minHeight: 360 }}
        title='View Application'
      >
        <div style={{ paddingTop: 24 }}>
          <ApplicationViewPageComponent />
        </div>
      </Card>
    </div>
  );
}

export default ApplicationViewPage;
