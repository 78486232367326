import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Steps, Button, notification
} from 'antd';
import moment from 'moment';
import {
  createJob,
  getAdminMustHaveEducationList,
  getAdminMustHaveExperienceList
} from '../../../../redux/actions/jobs';
import CreateJobDescription from './new_job_wizard/CreateJobDescription';
import CreateJobDetails from './new_job_wizard/CreateJobDetails';
import CreateJobAdvertisement from './new_job_wizard/CreateJobAdvertisement';
import CreateJobConfirm from './new_job_wizard/CreateJobConfirm';
import JobType from './new_job_wizard/JobType';
import { getWorkplaceProfileList } from '../../../../redux/actions/workplace';
import { validateToken } from '../../../../redux/actions/auth';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right',
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

function JobNewPageComponent(props) {
  const {
    user,
    classes,
    history,
    countries,
    jobClassifications,
    workplaceProfileList,
    getWorkplaceProfileList,
    getAdminMustHaveEducationList,
    getAdminMustHaveExperienceList,
    educationList,
    experienceList,
    validateTokenHandler,
  } = props;
  const [newJob, setNewJob] = useState({
    status_id: 1,
    record_created: '',
    job_start: moment(),
    job_expire: moment().add(30, 'days'),
    expireDays: 30,
    job_title: '',
    short_description: '',
    long_description: '',
    work_option_full_time: 0,
    work_option_part_time: 0,
    work_option_casual: 0,
    remote_work: 0,
    location_country_id: null,
    location_region: null,
    salary_low: 0,
    salary_exact: 0,
    salary_high: 0,
    salary_currency: '',
    contactName: '',
    contactEmail: '',
    advertiser_reference: '',
    advertisement_text: '',
    advertisement_freetext: '',
    job_tasks: '', //
    job_responsibilities: '',
    job_benefits: '',
    job_classification_id: null,
    workplace_profile_id: null,
    platinum_tick_job: true,
    job_type: null,
    jobTasks: [],
    responsibilities: [],
    benefits: [],
    mustHave_education: null,
    mustHave_experienceTime: null,
    mustHave_rightToWork: false,
    mustHave_wwcc: false,
    mustHave_driversLicence: false,
    mustHave_cleanCriminalHistory: false,
    mustHave_notBankrupt: false,
    mustHave_certificateCompliance: false,
    mustHave_specialistCertifications: '',
  });

  const [fieldStatus, setFieldStatus] = useState({
    job_title: '',
    short_description: '',
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatusError, setStepStatusError] = useState(false);

  useEffect(() => {
    getWorkplaceProfileList();
    getAdminMustHaveEducationList();
    getAdminMustHaveExperienceList();
  }, []);

  const steps = [
    {
      title: 'Job Type',
      content: (
        <JobType
          newJob={newJob}
          setNewJob={setNewJob}
          fieldStatus={fieldStatus}
        />
      ),
    },
    {
      title: 'Description',
      content: (
        <CreateJobDescription
          newJob={newJob}
          setNewJob={setNewJob}
          countries={countries}
          workplaceProfileList={workplaceProfileList}
          jobClassifications={jobClassifications}
          fieldStatus={fieldStatus}
        />
      ),
    },
    {
      title: 'Details',
      content: (
        <CreateJobDetails
          newJob={newJob}
          setNewJob={setNewJob}
          fieldStatus={fieldStatus}
          educationList={educationList}
          experienceList={experienceList}
        />
      ),
    },
    {
      title: 'Advertisement',
      content: (
        <CreateJobAdvertisement
          newJob={newJob}
          setNewJob={setNewJob}
          countries={countries}
          jobClassifications={jobClassifications}
          workplaceProfileList={workplaceProfileList}
          educationList={educationList}
          experienceList={experienceList}
        />
      ),
    },
    {
      title: 'Confirm & Pay',
      content: (
        <CreateJobConfirm
          job={newJob}
          setNewJob={setNewJob}
        />
      ),
    },
  ];

  useEffect(() => {
    setFieldStatus({});
  }, [newJob]);

  const validateStep0 = () => {
    setFieldStatus((prev) => ({
      ...prev,
      job_type: newJob.job_type ? '' : 'error',
    }));
    return Boolean(newJob.job_type);
  };

  const validateStep1 = () => {
    setFieldStatus((prev) => ({
      ...prev,
      job_title: newJob.job_title ? '' : 'error',
      short_description: newJob.short_description ? '' : 'error',
      job_classification_id: newJob.job_classification_id ? null : 'error',
      workplace_profile_id: newJob.workplace_profile_id ? null : 'error',
      location_region: newJob.location_region ? '' : 'error',
      location_country_id: newJob.location_country_id ? null : 'error',
      contactName: newJob.contactName ? '' : 'error',
      contactEmail: newJob.contactEmail ? '' : 'error',
    }));
    return (
      newJob.job_title !== '' &&
      newJob.short_description !== '' &&
      newJob.job_classification_id !== null &&
      newJob.workplace_profile_id !== null &&
      newJob.location_region !== '' &&
      newJob.location_country_id !== null &&
      newJob.contactName !== '' &&
      newJob.contactEmail !== ''
    );
  };

  const validateStep2 = () => {
    setFieldStatus((prev) => ({
      ...prev,
      salary: newJob.salary_low === 0 && newJob.salary_high === 0 ? 'error' : '',
      salary_currency: !newJob.salary_currency ? 'error' : '',
    }));
    return !(newJob.salary_low === 0 && newJob.salary_high === 0) && newJob.salary_currency;
  };

  const nextStep = () => {
    let validate = true;
    switch (currentStep) {
      case 0:
        validate = validateStep0();
        break;
      case 1:
        validate = validateStep1();
        break;
      case 2:
        validate = validateStep2();
      default:
    }

    if (!validate) {
      setStepStatusError(true);
    } else {
      setStepStatusError(false);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prev_step = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = () => {
    openNotificationWithIcon(
      'info',
      'In Process',
      'Creating new job in database.',
    );

    createJob({
      ...newJob,
      advertiser_id: user.advertiser_id,
      advertisement_product_id: newJob.job_type
    }).then((data) => {
      openNotificationWithIcon('success', 'Job', 'Success creating new Job!');
      validateTokenHandler();
      history.push('/jobs/current');
    }).catch(error => {
      openNotificationWithIcon('error', 'Job', 'Unable to create new job! ');
    })

  };

  return (
    <React.Fragment>
      <Steps current={currentStep} status={stepStatusError ? 'error' : ''}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={classes.stepsContent}>{steps[currentStep].content}</div>
      <div className={classes.stepsAction}>
        {currentStep > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={() => prev_step()}>
            Previous
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={() => nextStep()}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button type="primary" onClick={handleSubmit}>
            Done
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    jobs: state.jobs.jobs,
    language: state.language,
    countries: state.countries,
    jobClassifications: state.jobClassifications,
    workplaceProfileList: state.workplaceProfile.workplaceProfileList,
    educationList: state.jobs.educationList,
    experienceList: state.jobs.experienceList,
    user: state.auth.currentUser,
  };
};

const mapDispatchToProps = {
  createJobHandler: createJob,
  getWorkplaceProfileList: getWorkplaceProfileList,
  getAdminMustHaveEducationList: getAdminMustHaveEducationList,
  getAdminMustHaveExperienceList: getAdminMustHaveExperienceList,
  validateTokenHandler: validateToken,
};

const JobNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(JobNewPageComponent)));

export default JobNewPageComponentContainer;
