// import { API } from "aws-amplify";

export const ADVERTISERS_START_NEW_ADVERTISER_CREATION = 'ADVERTISERS_START_NEW_ADVERTISER_CREATION';
export const ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION = 'ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION';

export function startNewClientCreation() {
  return {type: ADVERTISERS_START_NEW_ADVERTISER_CREATION};
}

export function updateNewClientCreation(key, value) {
  // console.log(key, value);
  return {
    type: ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION,
    value: {
      key,
      value
    }
  };
}
