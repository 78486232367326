import React, { useState } from 'react';
import { Row, Col, Layout, Spin, Button, Typography, Input, Form } from 'antd';
import { confirmPasswordAPI } from '../../services/auth';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const { Content } = Layout;

function ResetPassword(props) {
  const { location } = props;

  const params = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    samePasswordStatus: '',
    confirmPasswordStatus: '',
    newPasswordStatus: ''
  });
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [result, setResult] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setResetPasswordInfo(prev => ({
      ...prev,
      [id]: value
    }));
  }

  const handleSubmit = () => {
    if (!validate_form()) return;
    setLoading(true);
    confirmPasswordAPI({ token: params.t, newPassword: resetPasswordInfo.newPassword }).then(data => {
      setLoading(false);
      setResult(true);
    }).catch(error => {
      setLoading(false);
      setResult(false);
    });
  }

  const validate_form = () => {
    let valid = true;
    setStatus(prev => ({
      ...prev,
      samePasswordStatus: 'success',
      newPasswordStatus: 'success'
    }));
    if (resetPasswordInfo.newPassword !== resetPasswordInfo.confirmPassword) {
      valid = false;
      setStatus(prev => ({
        ...prev,
        samePasswordStatus: 'error'
      }));
    }
    if (!resetPasswordInfo.newPassword) {
      valid = false;
      setStatus(prev => ({
        ...prev,
        newPasswordStatus: 'error'
      }));
    }
    if (!resetPasswordInfo.confirmPassword) {
      valid = false;
      setStatus(prev => ({
        ...prev,
        confirmPasswordStatus: 'error'
      }));
    }
    return valid;
  }

  return (
    <Content>
      {
        loading ?
          <Row style={{ justifyContent: 'center', height: '100vh', display: 'flex', alignItems: 'center' }}>
            <Spin tip="Loading..." size='large' />
          </Row> : result === null ?
            <Row>
              <Col span={24} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 100 }}>
                <Form
                  {...layout}
                >
                  <Form.Item
                    label='New Password:'
                    hasFeedback
                    help={status.newPasswordStatus === "error" ? "Please input new password" : ""}
                    validateStatus={status.newPasswordStatus}
                  >
                    <Input.Password
                      placeholder='Password'
                      id='newPassword'
                      value={status.newPassword}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Confirm Password:'
                    hasFeedback
                    help={status.samePasswordStatus === "error" ? "Password and Confirm password has to be the same" : ""}
                    validateStatus={status.samePasswordStatus}
                  >
                    <Input.Password
                      placeholder='Confirm Password'
                      id='confirmPassword'
                      value={resetPasswordInfo.confirmPassword}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  onClick={handleSubmit}>
                  Reset Password
              </Button>
              </Col>
            </Row> : <Row>
              <Col span={24} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 100 }}>
                <img
                  src={result ? require('../../assets/email_verification.png') : require('../../assets/email_verification_failed.png')}
                  alt=''
                  target='_blank'
                  style={{ paddingTop: 16, marginBottom: 16, maxWidth: 200 }}
                />
                <Title level={2}>{result ? 'Password reset success' : 'Password reset failed'}</Title>
                <Text>{result ? 'You password is updated.' : 'Password reset link is invalid or has expired.'}</Text>
                <Button style={{ marginTop: 20 }}>
                  <NavLink to='/login'>
                    Go to the website</NavLink>
                </Button>
              </Col>
            </Row>
      }

    </Content>
  )
}

export default ResetPassword;