import React from 'react';
import {
  Card, PageHeader, Button
} from 'antd';

import JobViewPageComponentContainer from '../../../components/pages/dashboard/jobs/JobViewPageComponent';

const JobViewPage = (props) => {

  const onClickShowAll = () => {
    props.history.push('/jobs/current/');
  };

  const onClickNewJob = () => {
    props.history.push('/jobs/new');
  };

  // console.log(this.props.match.params.job_id)

  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="Job Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="View and update your Job Details"
        extra={[
          <Button key="3" onClick={onClickNewJob}>Create New</Button>,
          <Button key="1" onClick={onClickShowAll} type="primary">
                    Show All
          </Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <JobViewPageComponentContainer/>
        </div>
      </Card>
    </div>

  );
};

export default JobViewPage;
