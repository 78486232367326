
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input } from 'antd';

function AdvertiserSummaryContainer(props) {
  const { updateAdvertiserHandler, advertiser, classes } = props;
  const [summaryInfo, setSummaryInfo] = useState({
    company_name: '',
    company_acn: '',
    business_name: '',
    business_abn: '',
  });

  useEffect(() => {
    setSummaryInfo(prev => ({
      ...prev,
      ...advertiser
    }));
  }, [advertiser]);

  const handleSubmit = async event => {
    event.preventDefault();
    updateAdvertiserHandler(summaryInfo);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setSummaryInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Company Name">
              <Input
                id="company_name"
                value={summaryInfo.company_name}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item label="Company ACN" >
              <Input
                id="company_acn"
                value={summaryInfo.company_acn}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item label="Business Name">
              <Input
                id="business_name"
                value={summaryInfo.business_name}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item label="Business ABN" >
              <Input
                id="business_abn"
                value={summaryInfo.business_abn}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
                </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default AdvertiserSummaryContainer;
