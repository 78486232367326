export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'com.forexworldwide.crm.documents.01'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://9s3zyajdba.execute-api.ap-southeast-2.amazonaws.com/dev'
  },
  cognito_advertisers: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_hbd1XEphh',
    APP_CLIENT_ID: '2nm884mg20r7dpq44qfbia2hu1',
    IDENTITY_POOL_ID: 'us-east-1:a1a8fddf-c8b0-45d4-84b0-072013f6f356'
  }
};
