import { combineReducers } from 'redux';

import app_state from './app_state';
import applicants from './applicants';
import advertisers from './advertisers';
import language from './language';
import auth from './auth';
import jobs from './jobs';
import advertiser from './advertiser';
import countries from './countries';
import workplaceProfile from './workplace';
import jobClassifications from './job_classifications';
import payment from './payment';
import application from './application';
import applicant_profiles from './applicant_profiles';

const rootReducer = combineReducers({
  app_state,
  language,
  auth,
  applicants,
  advertisers,
  jobs,
  advertiser,
  countries,
  workplaceProfile,
  jobClassifications,
  payment,
  application,
  applicant_profiles
});

export default rootReducer;
