import {
  ADVERTISERS_START_NEW_ADVERTISER_CREATION,
  ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION
} from '../actions/advertisers';

const initialState = {
  new_advertiser_data: null,
  new_advertiser_submitted: false,
  new_advertiser_created: false
};

function advertisers(state = initialState, action) {
  switch (action.type) {
    case ADVERTISERS_START_NEW_ADVERTISER_CREATION:
      return {
        ...state, // staff_detail: action.staff_detail,
        new_advertiser_data: {
          first_name: '',
          last_name: '',
          email: '',
        },
        new_advertiser_submitted: false,
        new_advertiser_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION:
      return {
        ...state,
        new_advertiser_data: {
          ...state.new_advertiser_data,
          [action.value.key]: action.value.value
        },
      };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default advertisers;
