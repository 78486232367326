import React, { useMemo } from 'react';
import { Row, Col, Form, DatePicker, Input, Select, Typography, Radio, Space, Tooltip } from 'antd';
import moment from 'moment';
import CountrySelectComponent from '../../../../common/CountrySelectComponent';

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

function NewJobWizard01Container(props) {
  const {
    fieldStatus,
    newJob,
    setNewJob,
    countries,
    jobClassifications,
    workplaceProfileList,
  } = props;
  const handleChange = (event) => {
    const { id, value } = event.target;
    setNewJob((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const handleJobStartChange = (date, dateString) => {
    if (date && dateString)
      setNewJob((prev) => ({
        ...prev,
        job_start: dateString,
      }));
  };

  const handleChangeWorkplaceProfile = (value) => {
    setNewJob((prev) => ({
      ...prev,
      workplace_profile_id: value,
    }));
  };

  const handleChangeCountryId = (value) => {
    setNewJob((prev) => ({
      ...prev,
      location_country_id: value,
    }));
  };

  const handleChangeJobClassificationId = (value) => {
    setNewJob((prev) => ({
      ...prev,
      job_classification_id: value,
    }));
  };

  const handleJobExpireChange = (date, dateString) => {
    if (date && dateString)
      setNewJob((prev) => ({
        ...prev,
        job_expire: dateString,
      }));
  };

  const handleJobExpireDaysChanges = (event) => {
    setNewJob((prev) => ({
      ...prev,
      expireDays: event.target.value,
      job_expire: moment(prev.job_start).add(event.target.value, 'day')
    }))
  };

  const options = countries?.map((d, index) => (
    <Option key={`countries-${index}`} value={d.id}>
      {d.full_name}
    </Option>
  ));
  const jobClassification = jobClassifications?.map((d, index) => (
    <Option key={`jobClassifications-${index}`} value={d.id}>
      {d.file_by}
    </Option>
  ));
  const workplaceProfiles = workplaceProfileList?.map((workplaceProfile) => (
    <Option key={workplaceProfile} value={workplaceProfile.id}>
      {Boolean(workplaceProfile?.private_fileBy)
        ? `${workplaceProfile?.workplace_name} [${workplaceProfile?.private_fileBy}]`
        : `${workplaceProfile?.workplace_name}`}
    </Option>
  ));

  const expireDaysOptions = useMemo(() => {
    return [
      { label: 7, value: 7 },
      { label: 30, value: 30 },
      { label: 60, value: 60 },
      { label: 90, value: 90, disabled: !newJob.job_type || newJob.job_type === 1 },
      { label: 365, value: 365, disabled: newJob.job_type !== 3 }
    ]
  }, [newJob.job_type]);

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Title level={5}>Workplace:</Title>
            <Tooltip
              placement="topRight"
              title="Select the workplace profile that you have already created"
            >
              <Form.Item label="Select the Workplace Profile (*)">
                <Select
                  id="workplace_profile_id"
                  showSearch
                  value={newJob.workplace_profile_id}
                  placeholder="Select Workplace Profile"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option?.children?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={handleChangeWorkplaceProfile}
                >
                  {workplaceProfiles}
                </Select>
                {fieldStatus.workplace_profile_id === 'error' ? (
                  <div class="ant-form-item-explain ant-form-item-explain-error">
                    Workplace Profile is required
                  </div>
                ) : (
                  ''
                )}
              </Form.Item>
            </Tooltip>
            <Title level={5}>Job:</Title>
            <Form.Item
              label="Job Title (*)"
              style={{ marginBottom: 0 }}
              validateStatus={fieldStatus.job_title}
              hasFeedback
              help={
                fieldStatus.job_title === 'error' ? 'Job Title is required' : ''
              }
            >
              <Input
                id="job_title"
                value={newJob.job_title}
                rows={3}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Short Description (*)"
              validateStatus={fieldStatus.short_description}
              hasFeedback
              help={
                fieldStatus.short_description === 'error'
                  ? 'Short Description is required'
                  : ''
              }
            >
              <TextArea
                id="short_description"
                value={newJob.short_description}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item label="Job Category (*)">
              <Select
                id="job_classification_id"
                showSearch
                value={newJob.job_classification_id}
                placeholder="Select Category"
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={handleChangeJobClassificationId}
              >
                {jobClassification}
              </Select>
              {fieldStatus.job_classification_id === 'error' ? (
                <div class="ant-form-item-explain ant-form-item-explain-error">
                  Job Category is required
                </div>
              ) : (
                ''
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Title level={5}>Job Location:</Title>
            <Form.Item
              label="City, State (*)"
              validateStatus={fieldStatus.location_region}
              hasFeedback
              help={
                fieldStatus.location_region === 'error'
                  ? 'Location is required'
                  : ''
              }
            >
              <Input
                id="location_region"
                value={newJob.location_region}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Country (*)"
              validateStatus={fieldStatus.location_country_id}
              hasFeedback
              help={
                fieldStatus.location_country_id === 'error'
                  ? 'Country is required'
                  : ''
              }
            >
              <CountrySelectComponent
                id="location_country_id"
                value={newJob.location_country_id}
                handleChange={handleChangeCountryId}
              />
            </Form.Item>
            <Title level={5}>Advertiser Contact:</Title>
            <Form.Item
              label="Name (*)"
              validateStatus={fieldStatus.contactName}
              hasFeedback
              help={
                fieldStatus.contactName === 'error'
                  ? 'Advertiser Contact is required'
                  : ''
              }
            >
              <Input
                id="contactName"
                value={newJob.contactName}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Email (*)"
              validateStatus={fieldStatus.contactEmail}
              hasFeedback
              help={
                fieldStatus.contactEmail === 'error'
                  ? 'Advertiser Email is required'
                  : ''
              }
            >
              <Input
                id="contactEmail"
                value={newJob.contactEmail}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>

            <Form.Item label="Advertiser Job Reference">
              <Input
                id="advertiser_reference"
                value={newJob.advertiser_reference}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Row>
              <Form.Item label="Job Ad starts on">
                <DatePicker
                  value={moment(newJob.job_start, dateFormat)}
                  format={dateFormat}
                  onChange={handleJobStartChange}
                />
              </Form.Item>
              <Form.Item
                label="Job Ad will expire on"
                style={{ marginLeft: 30 }}
              >
                <Space>
                  <Radio.Group
                    options={expireDaysOptions}
                    id="expireDays"
                    value={newJob.expireDays}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={handleJobExpireDaysChanges}
                  />

                  <DatePicker
                    value={moment(newJob.job_expire, dateFormat)}
                    format={dateFormat}
                    onChange={handleJobExpireChange}
                  />
                </Space>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard01Container;
