import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Tag } from 'antd';

const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const columns = [
  {
    title: 'Date / Time',
    dataIndex: 'date',
    sorter: {
      compare: (a, b) => a.date > b.date,
      multiple: 2,
    },
    multiple: 3,
  },
  {
    title: 'Product',
    dataIndex: 'product',
    sorter: {
      compare: (a, b) => a.product > b.product,
      multiple: 2,
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: {
      compare: (a, b) => a.status - b.status,
      multiple: 1,
    },
    render: status => (
      <span>
        {
          <Tag color={status === 1 ? 'green' : 'volcano'}>
            {status === 1 ? 'SUCCESS' : 'FAILED'}
          </Tag>
        }
      </span>
    ),
  }
];

function BillingHistory(props) {
  const { billingHistory } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (billingHistory.length > 0) {
      const listData = billingHistory.map((item, index) => ({
        ...item,
        key: index
      }));
      setData(listData);
    }
  }, [billingHistory])
  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data} rowKey="id"/>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    billingHistory: state.payment.billingHistory
  };
};

const mapDispatchToProps = ({
});


const BillingHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingHistory);

export default withStyles(styles, { injectTheme: true })(BillingHistoryContainer);

