import Constants from './constants';

const APIUtils = {
  convertQueryString: (queryParams = {}) => {
    const esc = encodeURIComponent;
    const query = Object.keys(queryParams)
      .map(key => `${esc(key)}=${esc(queryParams[key])}`)
      .join('&');

    return query ? `?${query}` : '';
  },

  getAccessToken: () => {
    return localStorage.getItem(Constants.IDENTIFIERS.ACCESS_TOKEN);
  },

  setAccessToken: (token) => {
    localStorage.setItem(Constants.IDENTIFIERS.ACCESS_TOKEN, token);
  },

};

export default APIUtils;
