import React from 'react';
import { Select } from 'antd';
import withStyles from 'react-jss';
import { connect } from 'react-redux';

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const { Option } = Select;
function CountrySelectComponent(props) {
  const { value, allowClear, handleChange, countries, id } = props;
  const priorityCount = countries?.filter((item) => item.priority).length;
  const priorityStyle = {
    margin: '0 10px',
    marginBottom: 20,
    borderBottom: '2px solid rgb(123 123 123)',
    paddingBottom: 10,
  };
  const normalStyle = {
    margin: '0 10px',
  };

  const options = countries?.map((d, index) => (
    <Option
      key={d.id}
      value={parseInt(d.id)}
      style={index === priorityCount - 1 ? priorityStyle : normalStyle}
    >
      {d.full_name}
    </Option>
  ));

  return (
    <Select
      id={id}
      showSearch
      value={parseInt(value) || null}
      placeholder="Select Country"
      showArrow={true}
      allowClear={allowClear}
      optionFilterProp="children"
      dropdownClassName="common-country-select-dropdown"
      filterOption={(input, option) =>
        option.children
          .toString()
          .toLowerCase()
          .indexOf(input.toString().toLowerCase()) >= 0
      }
      onChange={handleChange}
    >
      {options}
    </Select>
  );
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
  };
};

export default withStyles(styles, { injectTheme: true })(
  connect(mapStateToProps, null)(CountrySelectComponent),
);
