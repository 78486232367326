
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { Table } from 'antd';

const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const columns = [
  {
    title: 'Advertisement Type',
    dataIndex: 'type',
  },
  {
    title: 'Current Balance',
    dataIndex: 'balance',
    sorter: {
      compare: (a, b) => a.balance - b.balance,
      multiple: 1,
    },
  }
];

function MyAccount(props) {
  const { balanceList } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (balanceList.length > 0) {
      const listData = balanceList.map((item, index) => ({
        ...item,
        key: index
      }));
      setData(listData);
    }
  }, [balanceList])
  return (
    <React.Fragment>
      <Table columns={columns} dataSource={data} rowKey="id"/>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    balanceList: state.payment.balanceList
  };
};

const mapDispatchToProps = ({
});


const MyAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount);

export default withStyles(styles, { injectTheme: true })(MyAccountContainer);

