import React, {
  useState, useEffect, useMemo
} from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Spin,
} from 'antd';

import {getApplicantProfile} from '../../../../redux/actions/applicant_profiles';
import { validateToken } from '../../../../redux/actions/auth';

const HeadHuntingPageComponent = (props) => {
  const {
    getApplicantProfileHandler,
    applicantProfileStore,
    id,
  } = props;
  const countries = useSelector((state) => state.countries);
  const history = useHistory();
  const [applicantProfile, setApplicantProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      getApplicantProfileHandler(id)
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [getApplicantProfileHandler, id]);

  useEffect(() => {
    if (!applicantProfileStore) {
      return;
    }

    const { pitch_video_file_id } = applicantProfileStore;
    if (id) {
      setApplicantProfile(applicantProfileStore);
    }
    if (id && applicantProfileStore && pitch_video_file_id) {
      setVideoList([
        {
          ...JSON.parse(pitch_video_file_id),
          uid: 'applicant-uv-1',
        },
      ]);
    }
  }, [applicantProfileStore, id]);

  const getCountryFullName = (country_id) => {
    return (
      countries?.filter((country) => country.id === country_id)[0].full_name ||
      ''
    );
  };
  const onSubmit = () => {
    setLoading(true);
    setLoading(false);
  };

  const concatFullName = (firstName, secondName) => {
    return ((firstName || '') + ' ' + (secondName || ''));
  };

  const initHtml = useMemo(() => {
    const address = `
      ${
        applicantProfile?.street_address_line_1
          ? `<div>${applicantProfile?.street_address_line_1}</div>`
          : ''}
      ${
        applicantProfile?.street_address_line_2
          ? `<div>${applicantProfile?.street_address_line_2}</div>`
          : ''}
      ${
        applicantProfile?.street_address_suburb
          ? applicantProfile?.street_address_suburb + '. '
          : ''}
      ${
        applicantProfile?.street_address_state
          ? applicantProfile?.street_address_state + '. '
          : ''}
      ${
        applicantProfile?.street_address_postcode
          ? applicantProfile?.street_address_postcode
          : ''}
      ${
        applicantProfile?.street_address_country_id
          ? `<div>${getCountryFullName(
            applicantProfile?.street_address_country_id,
          )}</div>`
          : ''}
    `;

    return `
      <div>
        <span style="font-size: 18px; margin-right: 15px;">
          <strong>${
            applicantProfile?.file_by || ''}
          </strong>
        </span>  
      </div>
      <div>
      <span style="font-size: 18px; margin-right: 15px;">
        <strong>${
          applicantProfile?.public_fileBy || ''}
        </strong>
      </span>  
    </div>
      <table style="margin-top: 15px;">
        <tr>
          <td>Name</td>
          <td style="padding: 5px 20px;">${
            applicantProfile.first_name || applicantProfile.last_name
              ? concatFullName(applicantProfile.first_name, applicantProfile.last_name)
              : 'None Provided'}
          </td>
        </tr>
        <tr>
          <td>Email</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.email || 'None Provided'}
          </td>
        </tr>
        <tr>
          <td style="padding: 5px 0px; vertical-align: baseline">Address</td>
          <td style="padding: 5px 20px;">
            ${address.trim() ? address : 'None Provided'}
          </td>
        </tr>
        <tr>
          <td>Telephone Mobile</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.telephone_mobile || 'None Provided'}
          </td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my profile visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.visible_search ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.visible_applications ? 'YES' : 'NO'}
          </td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my Pitch Video visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.video_visible_search ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.video_visible_applications ? 'YES' : 'NO'}
          </td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Preferred Work Options</h3>
      <table>
        <tr>
          <td>Work from home</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.available_from_home ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>Full time</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.available_full_time ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>Part time</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.available_part_time ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>Flexible hours</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.available_flexible ? 'YES' : 'NO'}
          </td>
        </tr>
        <tr>
          <td>Willing to Relocate</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.available_willing_relocate ? 'YES' : 'NO'}
          </td>
        </tr>
      </table>
      <table style="margin-top: 15px;">
        <tr>
          <td>Salary Range:</td>
          <td style="padding: 5px 20px;">${(
    (applicantProfile?.target_salary_min || 0) * 1000
  ).toLocaleString()}-${(
  (applicantProfile?.target_salary_max || 0 ) * 1000
).toLocaleString() || 0} p/a</td>
        </tr>      
        <tr>
          <td>Platinum Tick™Jobs Only</td>
          <td style="padding: 5px 20px;">${
            applicantProfile?.platinum_tick_only ? 'YES' : 'NO'}
          </td>
        </tr>
      </table>
      <div style="margin-top: 15px;">
        ${
  videoList.length > 0
    ? '<video src="' +
              videoList[0].path +
              '"' +
              ' controls="true"></video>'
    : '<p>No Pitch Video has been uploaded</p>'
}
      </div>
    `;
  }, [applicantProfile]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onSubmit}>
          <Row gutter={[96, 24]}>
            <Col span={12}>
              <div dangerouslySetInnerHTML={{ __html: initHtml }} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log('state:', state)
  return {
    countries: state.countries,
    applicantProfileStore: state.applicant_profiles,
  };
};

const mapDispatchToProps = {
  getApplicantProfileHandler: getApplicantProfile,
  validateTokenHandler: validateToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeadHuntingPageComponent);
