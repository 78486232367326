import jobsType from '../type/jobs';

const initialState = {
  jobs: [],
  updateJobs: [],
  statusList: [],
  educationList: [],
  experienceList: [],
};

function jobs(state = initialState, action) {
  switch (action.type) {
    case jobsType.GET_JOBS:
      return {
        ...state,
        jobs: action.jobs
      };
      break;
    case jobsType.GET_JOB_STATUS_LIST:
      return {
        ...state,
        statusList: action.data
      };
      break;
    case jobsType.UPDATE_JOB:
      return {
        ...state,
        updateJobs: action.data
      };
      break;
    case jobsType.GET_JOB_MUST_HAVE_EDUCATION_LIST:
      return {
        ...state,
        educationList: action.data
      };
      break;
    case jobsType.GET_JOB_MUST_HAVE_EXPERIENCE_LIST:
      return {
        ...state,
        experienceList: action.data
      };
      break;
    default:
      return state;
  }
}

export default jobs;
