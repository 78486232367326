
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateNewClientCreation

} from '../../../../../redux/actions/applicants';

import { API } from 'aws-amplify';
// import { Auth } from "aws-amplify";
import { Row, Col, Select, Form, Input } from 'antd';

class NewClientWizard01 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  async componentDidMount() {
    const { Option } = Select;

    let account_list_select_options = {};
    API.get('accounts', '/list').then(
      response => {
        // console.log(response);
        account_list_select_options = response.payload.map(d => <Option key={d.id} >{d.file_by}</Option>);
        this.setState({account_list_select_options: account_list_select_options});
      }
    )
  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
    this.props.updateNewClientCreation(event.target.id, event.target.value);
    this.props.updateNewClientCreation(`${event.target.id}_status`, 'success');
  
    //#37 refresh the email existed error message
    if (event.target.id === 'email') {
      this.props.updateNewClientCreation('email_error_message', ' ');
    }

    //#46 rename clients nickname to file_by
    if (event.target.id === 'first_name') {
      this.props.updateNewClientCreation('file_by', `${this.state.last_name}, ${event.target.value}`);
      this.props.updateNewClientCreation('file_by_status', 'success');
    } else if (event.target.id === 'last_name') {
      this.props.updateNewClientCreation('file_by', `${event.target.value}, ${this.state.first_name}`);
      this.props.updateNewClientCreation('file_by_status', 'success');
    }
  };

  handleSelectChange_account = (value) => {
    this.setState({'account_id': value});
    this.props.updateNewClientCreation('account_id', value);
    this.props.updateNewClientCreation('account_id_status', 'success');
    this.props.updateNewClientCreation('account_error_message', ' ');
  };

  render() {
    // console.log(this.state);
    if (!this.props.applicants || !this.props.applicants.new_applicant_data) {
      return null;
    }

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item
                    label="First Name"
                    style={{ marginBottom: 0 }}
                    validateStatus={this.props.applicants.new_client_data.first_name_status}
                    hasFeedback
                    help={this.props.applicants.new_client_data.first_name_status === 'error' ? 'First Name is required' : ''}
                  >
                    <Input
                      id="first_name"
                      value={this.props.applicants.new_client_data.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)" style={{ marginBottom: 0 }}>
                    <Input
                      id="middle_name"
                      value={this.props.applicants.new_client_data.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Last Name"
                validateStatus={this.props.applicants.new_client_data.last_name_status}
                hasFeedback
                help={this.props.applicants.new_client_data.last_name_status === 'error' ? 'Last Name is required' : ''}
              >
                <Input
                  id="last_name"
                  value={this.props.applicants.new_client_data.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Account"
                style={{ marginBottom: 0 }}
                validateStatus={this.props.applicants.new_client_data.account_id_status === 'success' ? 'success' : 'error'}
                hasFeedback
                help={this.props.applicants.new_client_data.account_error_message}
                //help={this.props.applicants.new_client_data.account_id_status === "error" ? "Account User ID is required" : ""}
              >
                <Select
                  id="account_id"
                  showSearch
                  value={
                    typeof this.state.account_id === 'number'
                      ? this.state.account_id.toString()
                      : this.state.account_id
                  }
                  placeholder="Select Account"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={this.handleSelectChange_account}
                >
                  {this.state.account_list_select_options}

                </Select>
              </Form.Item>
              {!this.props.applicants.new_client_data.account_error_message && <br />}
              <Form.Item
                label="E-mail"
                validateStatus={this.props.applicants.new_client_data.email_status}
                hasFeedback
                help={this.props.applicants.new_client_data.email_error_message}
              >
                <Input
                  id="email"
                  value={this.props.applicants.new_client_data.email}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.applicants,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewClientWizard01Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClientWizard01);

export default NewClientWizard01Container;
