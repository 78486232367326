import { applicationType } from '../type';
import {
  getApplicationsAPI,
  getApplicationAPI,
  updateApplicationAPI,
} from '../../services/application';

const getApplications = (params) => {
  return (dispatch) => {
    return getApplicationsAPI(params)
      .then((data) => {
        return dispatch({
          type: applicationType.GET_APPLICATIONS,
          applications: data.data
        });
      }).catch(error => {})
  }
};

const getApplication = (id) => {
  return getApplicationAPI(id)
    .then((data) => {
      return data.data
    }).catch(error => { })
}

const updateApplication = (id, param) => {
  return updateApplicationAPI(id, param)
    .then((data) => {
      return data.data
    }).catch(error => {})
}

export {
  getApplications,
  getApplication,
  updateApplication
}
