import { workplaceType } from '../type';

const initialState = {
  workplaceProfileList: []
};

function workplaceProfile(state = initialState, action) {
  switch (action.type) {
    case workplaceType.GET_WORKPLACE:
      return {
        ...state,
        ...action.workplaceProfile
      };
    case workplaceType.CREATE_WORKPLACE:
      return {
        ...state,
        ...action.workplaceProfile
      };
    case workplaceType.UPDATE_WORKPLACE:
      return {
        ...state,
        ...action.workplaceProfile
      };
    case workplaceType.GET_WORKPLACE_LIST:
      return {
        ...state,
        workplaceProfileList: action.workplaceProfileList
      }
    default:
      return state;
  }
}

export default workplaceProfile;
