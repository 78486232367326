// import { API } from "aws-amplify";

export const APPLICANTS_START_NEW_APPLICANT_CREATION = 'APPLICANTS_START_NEW_APPLICANT_CREATION';
export const APPLICANTS_UPDATE_NEW_APPLICANT_CREATION = 'APPLICANTS_UPDATE_NEW_APPLICANT_CREATION';

export function startNewClientCreation() {
  return {type: APPLICANTS_START_NEW_APPLICANT_CREATION};
}

export function updateNewClientCreation(key, value) {
  // console.log(key, value);
  return {
    type: APPLICANTS_UPDATE_NEW_APPLICANT_CREATION,
    value: {
      key,
      value
    }
  };
}
