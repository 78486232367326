
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input, Typography } from 'antd';

const { Title } = Typography;

function AdvertiserContactContainer(props) {
  const { updateAdvertiserHandler, advertiser, classes } = props;
  const [contactInfo, setContactInfo] = useState({
    tel_home: '',
    tel_mobile: '',
    email: '',
    email_secondary: '',
  });

  useEffect(() => {
    setContactInfo(prev => ({
      ...prev,
      ...advertiser
    }));
  }, [advertiser]);

  const handleSubmit = async event => {
    event.preventDefault();
    updateAdvertiserHandler(contactInfo);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setContactInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Title level={4}>Contact Details</Title>
            <Form.Item label="Telephone (Home)">
              <Input
                id="tel_home"
                value={contactInfo.tel_home}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Telephone (Mobile)">
              <Input
                id="tel_mobile"
                value={contactInfo.tel_mobile}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Email (main)">
              <Input
                id="email"
                value={contactInfo.email}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Second Email">
              <Input
                id="email_secondary"
                value={contactInfo.email_secondary}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button disabled type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
              </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>

  );
}

export default AdvertiserContactContainer;
