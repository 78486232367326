import paymentType from '../type/payment';

const initialState = {
  balanceList: [],
  billingHistory: []
};

function payment(state = initialState, action) {
  switch (action.type) {
    case paymentType.GET_BALANCE_LIST:
      return {
        ...state,
        balanceList: action.balanceList
      };
    case paymentType.GET_BILLING_HISTORY_LIST:
      return {
        ...state,
        billingHistory: action.billingHistory
      }
    default:
      return state;
  }
}

export default payment;
