import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getJobAPI } from '../../../../services/jobs';
import JobSummary from './job_view/JobSummary';
import JobDetails from './job_view/JobDetails';
import Advertisement from './job_view/Advertisement';
import Applications from './job_view/Applications';
import ApplicationListPageComponent from '../applications/ApplicationListPageComponent';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const JobViewPageComponent = (props) => {
  const { countries, jobClassifications } = props;
  const [currentJob, setCurrentJob] = useState({});
  const [loading, setLoading] = useState(false);
  const [fieldStatus, setFieldStatus] = useState({
    job_title: '',
    short_description: ''
  });
  const { job_id } = useParams();

  useEffect(() => {
    setLoading(true);
    getJobAPI(job_id).then(data => {
      setCurrentJob(data.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Spin
        spinning={loading}
        tip="Loading...">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Job Description" key="description">
            <JobSummary
              currentJob={currentJob}
              countries={countries}
              setFieldStatus={setFieldStatus}
              fieldStatus={fieldStatus}
              jobClassifications={jobClassifications}
              id={job_id}
            />
          </TabPane>
          <TabPane tab="Details" key="details">
            <JobDetails currentJob={currentJob} />
          </TabPane>
          <TabPane tab="Advertisement" key="advertisement">
            <Advertisement currentJob={currentJob} />
          </TabPane>
          <TabPane tab="Applications" key="application">
            <Applications jobId={job_id} />
          </TabPane>
          {/*<TabPane tab="Applications" key="application">
            <ApplicationListPageComponent jobId={job_id} />
          </TabPane>*/}
        </Tabs>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    advertiser: state.advertiser,
    user: state.auth.currentUser,
    countries: state.countries,
    jobClassifications: state.jobClassifications
  }
};

const mapDispatchToProps = ({
});

export default connect(mapStateToProps, mapDispatchToProps)(JobViewPageComponent);
