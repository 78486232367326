import React from 'react';
import {NavLink, Switch, Redirect} from 'react-router-dom';

// import PropTypes from "prop-types";

// import { API, Auth } from "aws-amplify";
import {Layout, Menu, Icon} from 'antd';
// import { Input } from 'antd';
// import { Button } from 'antd';

import pagesRoutes from '../routes/pages.jsx';
// import AuthenticatedRoute from "../components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from '../components/routes/UnauthenticatedRoute';
import PagesHeaderContainer from '../components/layout/pages/PagesHeader.jsx';
// import logo from '../logo.svg';
import {
  UserOutlined,
} from '@ant-design/icons';
const {Header, Content, Footer, Sider} = Layout;
// const { SubMenu } = Menu;

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false
    };
    // this.resizeFunction = this.resizeFunction.bind(this);
  }
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", this.resizeFunction);
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", this.resizeFunction);
  // }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen });
  // };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  // sidebarMinimize() {
  //   this.setState({ miniActive: !this.state.miniActive });
  // }
  // resizeFunction() {
  //   if (window.innerWidth >= 960) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({collapsed});
  };
  
  switchRoutes() {
    return (
      <Switch>
        {pagesRoutes.map((prop, key) => {
          if (prop.collapse) {
            return null;
          }
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          return (
            <UnauthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                userHasAuthenticated: this.props.userHasAuthenticated,
                set_current_user: this.props.set_current_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  generate_sidebar = () => {
    return (
      <Menu theme='light' defaultSelectedKeys={['1']} mode='inline'>
        <Menu.Item key='1' icon={<UserOutlined />}>
          <NavLink to='/'>
            <span>Login</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='2'icon={<UserOutlined />}>
          <NavLink to='/pages/register'>
            <span>Register</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    // const { classes, ...rest } = this.props;
    // console.log(this.props);
    // const mainPanel =
    //   classes.mainPanel +
    //   " " +
    //   cx({
    //     [classes.mainPanelSidebarMini]: this.state.miniActive,
    //     [classes.mainPanelWithPerfectScrollbar]:
    //       navigator.platform.indexOf("Win") > -1
    //   });
    //   // console.log(this.props);
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider theme ='light' breakpoint="lg" style={{minHeight: '100vh'}} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className='logo' />
          <img
            src={this.state.collapsed ? require('../assets/img/brand/TickWithBorder_50px.png') : require('../assets/img/brand/PlatinumJobSeeker_300px.png')}
            alt=''
            target='_blank'
            style={{width: '100%', paddingTop: 16, marginBottom: 16, maxHeight: 64, maxWidth: 200}}
          />
          {this.generate_sidebar()}
        </Sider>
        <Layout>
          <Header style={{background: '#fff', padding: 0}}>
            <PagesHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
          <Layout>
            <Content style={{margin: '16px'}}>
              <div style={{padding: 24, background: '#fff', minHeight: 360}}>
                {/* <DatePickerSample/> */}

                <Switch>
                  {pagesRoutes.map((prop, key) => {
                    if (prop.collapse) {
                      return null;
                    }
                    if (prop.redirect) {
                      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    }
                    return (
                      <UnauthenticatedRoute
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        props={{
                          isAuthenticated: this.props.isAuthenticated,
                          userHasAuthenticated: this.props.userHasAuthenticated,
                          set_current_user: this.props.set_current_user
                        }}
                      />
                    );
                  })}
                </Switch>
              </div>
            </Content>
          </Layout>
          <Footer style={{textAlign: 'left'}}>
            <img src={require('../assets/img/brand/TickWithBorder_20px.png')} />
            <strong>
              Platinumjobseeker<sup>TM</sup>
            </strong>{' '}
            Advertiser Portal © 2020-2022
            <br />
            <small>Version 1.0.0</small>
            <br/>
            <small>
              Created by{' '}
              <a href='https://deliostech.com/' target='_blank'>
                Delios Technology Inc
              </a>
            </small>
          </Footer>
        </Layout>
      </Layout>

      // <div className={classes.wrapper} style={{backgroundImage:`url(${background_image})`}}>
      //   <SidebarContainer
      //     routes={dashboardRoutes}
      //     logoText={"Forex Worldwide"}
      //     logo={logo}
      //     image={image}
      //     handleDrawerToggle={this.handleDrawerToggle}
      //     open={this.state.mobileOpen}
      //     color="blue"
      //     bgColor="black"
      //     miniActive={this.state.miniActive}
      //     {...rest}
      //   />
      //   <div className={mainPanel} ref="mainPanel">
      //     <HeaderContainer
      //       sidebarMinimize={this.sidebarMinimize.bind(this)}
      //       miniActive={this.state.miniActive}
      //       routes={dashboardRoutes}
      //       handleDrawerToggle={this.handleDrawerToggle}
      //       {...rest}
      //     />
      //     {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      //     {this.getRoute() ? (
      //       <div className={classes.content}>
      //         <div className={classes.container}>{this.switchRoutes()}</div>
      //       </div>
      //     ) : (
      //       <div className={classes.map}>{this.switchRoutes()}</div>
      //     )}
      //     {this.getRoute() ? <Footer fluid /> : null}
      //   </div>
      // </div>
    );
  }
}

// Pages.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default Pages;
