import React, {
  useState,
  useEffect
} from 'react';

import withStyles from 'react-jss';

import { Tabs, notification } from 'antd';
import { connect } from 'react-redux';
import {
  withRouter, useLocation, useHistory
} from 'react-router-dom';

import { getAdvertiser, updateAdvertiser } from '../../../../redux/actions/advertiser';

import AdvertiserSummary from './advertiser_view/AdvertiserSummary';
import AdvertiserContact from './advertiser_view/AdvertiserContact';
import AdvertiserAddress from './advertiser_view/AdvertiserAddress';

import queryString from 'query-string';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

const AdvertiserViewPageComponent = (props) => {

  const { getAdvertiserHandler, advertiser, user, updateAdvertiserHandler, countries } = props;
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setState] = useState('');

  useEffect(() => {
    if (user && user.id) {
      getAdvertiserHandler(user.advertiser_id);
    }
  }, []);

  useEffect(() => {
    const {tab: activeTab} = queryString.parse(location.search);
    setState(activeTab);
  }, [location.search]);

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message
    });
  };

  const updateAdvertiserInfo = (updateData) => {
    updateAdvertiserHandler(user.id, updateData)
      .then(data => {
        if (data) {
          openNotificationWithIcon('success', 'Save Success', 'User Info Updated.');
        } else {
          openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
        }
      }).catch(error => {
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in User Info Updating.');
      })
  };

  const onTabClick = e => {
    history.push(`/settings/user-profile/?tab=${e}`);
  };

  return (
    <React.Fragment>
      <Tabs activeKey={activeTab} onTabClick={onTabClick} defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Advertiser Summary" key="advertiser_summary">
          <AdvertiserSummary
            advertiser={advertiser}
            updateAdvertiserHandler={updateAdvertiserInfo}
          />
        </TabPane>
        <TabPane tab="Contact" key="advertiser_contact">
          <AdvertiserContact
            advertiser={advertiser}
            updateAdvertiserHandler={updateAdvertiserInfo}
          />
        </TabPane>
        <TabPane tab="Address" key="advertiser_address">
          <AdvertiserAddress
            advertiser={advertiser}
            countries={countries}
            updateAdvertiserHandler={updateAdvertiserInfo} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    advertiser: state.advertiser,
    user: state.auth.currentUser,
    countries: state.countries
  }
}

const mapDispatchToProps = ({
  getAdvertiserHandler: getAdvertiser,
  updateAdvertiserHandler: updateAdvertiser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AdvertiserViewPageComponent)));