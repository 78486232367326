import { applicationType } from '../type';

const initialState = [];

function application(state = initialState, action) {
  switch (action.type) {
    case applicationType.GET_APPLICATIONS:
      return action.applications;
      break;
    default:
      return state;
  }
}

export default application;
