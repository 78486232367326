import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getApplicantProfileListAPI = () => {
  const path = `/advertiser/v1/applicant-profiles`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getHeadhuntingAPI = (params) => {
  const path = '/advertiser/v1/advertiser-headhunting';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getApplicantProfileAPI = (id) => {
  const path = `/advertiser/v1/applicant-profiles/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  getApplicantProfileAPI,
  getApplicantProfileListAPI,
  getHeadhuntingAPI
};