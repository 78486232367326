import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Advertiser Endpoint */
const getCurrentJobListAPI = () => {
  const path = '/advertiser/v1/jobs/current-job-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};
const getClosedJobListAPI = () => {
  const path = '/advertiser/v1/jobs/closed-job-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

// const getJobsAPI = () => {
//   const path = '/advertiser/v1/jobs';
//   const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
//   const client = new APIClient(url, APIConstants.HTTPMethod.GET);

//   return client.sendAuthenticatedRequest(
//     APIConstants.AuthenticationType.BEARER,
//   );
// };

const getJobsStatusList = () => {
  const path = '/advertiser/v1/jobs/status-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const getJobAPI = (id) => {
  const path = `/advertiser/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const updateJobAPI = (id, params) => {
  const path = `/advertiser/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
    params,
  );
};

const createJobAPI = (params) => {
  const path = `/advertiser/v1/jobs/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
    params,
  );
};

const getAdvertisementProductsAPI = () => {
  const path = '/advertiser/v1/jobs/advertisement_products';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const getMustEducationList = () => {
  const path = '/advertiser/v1/jobs/must-education-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const getMustExperienceList = () => {
  const path = '/advertiser/v1/jobs/must-experience-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

export {
  getCurrentJobListAPI,
  getClosedJobListAPI,
  getJobAPI,
  updateJobAPI,
  createJobAPI,
  getJobsStatusList,
  getAdvertisementProductsAPI,
  getMustEducationList,
  getMustExperienceList,
};
