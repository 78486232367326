import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { Row, Col, Button, Form, Input, Typography } from 'antd';
const { Title } = Typography;

const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
})

function UserContactContainer(props) {
  const { updateAdvertiserHandler, advertiser, classes } = props;
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    setContactInfo(prev => ({
      ...prev,
      ...advertiser
    }))
  }, [advertiser]);

  const handleSubmit = async event => {
    event.preventDefault();
    updateAdvertiserHandler(contactInfo);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setContactInfo(prev => ({
      ...prev,
      [id]: value
    }));
  };


  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Title level={4}>Contact Details</Title>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item
              label="Telephone (home)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="tel_home"
                value={contactInfo.tel_home}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Telephone (work)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="tel_work"
                value={contactInfo.tel_work}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Telephone (mobile)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="tel_mobile"
                value={contactInfo.tel_mobile}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Primary Email"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="email"
                value={contactInfo.email}
                className={classes.disabled_input}
                disabled
                onChange={event => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Secondary Email"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="email_secondary"
                value={contactInfo.email_secondary}
                onChange={event => handleChange(event)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[96, 24]} >
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
              </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default withStyles(styles, { injectTheme: true })(UserContactContainer);

