import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
  Table, Spin, Button, Col
} from 'antd';
import { NavLink } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons'
import { getWorkplaceProfileList } from '../../../../redux/actions/workplace';

const JobProfileListViewComponent = (props) => {
  const { workplaceProfileList, countries, noAction, getWorkplaceProfileListHandler } = props;
  const [workplaceList, setWorkplaceProfileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWorkplaceProfileListHandler();
  }, []);

  useEffect(() => {
    if (workplaceProfileList.length > 0) {
      setWorkplaceProfileList(workplaceProfileList);
    }
  }, [workplaceProfileList]);

  const columns = [
    {
      title: 'Workplace Name',
      dataIndex: 'workplace_name',
      render: (text, record) => {
        return text ? text : 'No workplace name provided'
      }
    },
    {
      title: 'Private Profile Name',
      dataIndex: 'private_fileBy',
      render: (text, record) => {
        return text ? text : 'No private profile name provided'
      }
    },
    {
      title: 'Country',
      dataIndex: 'country_id',
      render: country_id => {
        let contry = 'None';
        if (countries) {
          contry = countries.find(item => item.id === country_id);
        }
        return (
          <p>{contry?.full_name}</p>
        )
      }
    },
    {
      title: 'Employee Count',
      dataIndex: 'employee_count',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/workplace-profiles/profile/${record.id}`}><EyeOutlined /></NavLink>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={workplaceList}
          rowKey="id"
        />
        {
          !noAction && (
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type='primary' style={{ marginTop: 30 }}>
                <NavLink to='/workplace-profiles/new'>Create New</NavLink>
              </Button>
            </Col>
          )
        }
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    workplaceProfileList: state.workplaceProfile.workplaceProfileList,
    countries: state.countries
  }
};

const mapDispatchToProps = ({
  getWorkplaceProfileListHandler: getWorkplaceProfileList,
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileListViewComponent);
