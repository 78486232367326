import React, { useMemo, useState } from 'react';
import {
  Row, Col, Form, Input, Slider, Switch, Typography, Space, Radio, Tooltip, Select, Checkbox
} from 'antd';
import './styles.scss';

const { Text, Title } = Typography;
const { Option } = Select;

function NewJobWizard02Container(props) {
  const { newJob, setNewJob, fieldStatus, educationList, experienceList } = props;
  const [jobTasksInput, setJobTasksInput] = useState('');
  const [responsibilitiesInput, setResponsibilitiesInput] = useState('');
  const [benefitsInput, setBenefitsInput] = useState('');

  const marks = useMemo(() => {
    const result = {};
    for (let i = 0; i <= 40; i ++) {
      result[i * 5000] = i % 5 === 0 ? `${5 * i}${i > 0 ? ',000' : ''}` : '';
    }

    return result;
  }, []);
  const handleParttimeCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_part_time: checked,
    }));
  };

  const handleFulltimeCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_full_time: checked,
    }));
  };

  const handleCasualCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      work_option_casual: checked,
    }));
  };

  const handleRemoteWorkCheckbox = (checked) => {
    setNewJob((prev) => ({
      ...prev,
      remote_work: checked,
    }));
  };

  const handleChangeSalary = (value) => {
    setNewJob((prev) => ({
      ...prev,
      salary_low: value[0],
      salary_high: value[1],
    }));
  };

  const handleChangeSalaryCurrency = (e) => {
    setNewJob((prev) => ({
      ...prev,
      salary_currency: e.target.value
    }));
  };

  const handleAddJobTasks = () => {
    setNewJob((prev) => ({
      ...prev,
      jobTasks: [...prev.jobTasks, jobTasksInput ],
    }));
    setJobTasksInput('');
  };

  const handleChangeJobTasks = (event) => {
    const { value } = event.target;
    setJobTasksInput(value);
  };

  const handleRemoveJobTasks = (index) => {
    const array = [...newJob.jobTasks];
    if (index !== -1) {
      array.splice(index, 1);
      setNewJob((prev) => ({
        ...prev,
        jobTasks: array,
      }));
    }
  };

  const handleAddResponsibilities = () => {
    setNewJob((prev) => ({
      ...prev,
      responsibilities: [...newJob.responsibilities, responsibilitiesInput],
    }));
    setResponsibilitiesInput('');
  };

  const handleChangeResponsibilities = (event) => {
    const { value } = event.target;
    setResponsibilitiesInput(value);
  };

  const handleRemoveResponsibilities = (index) => {
    const array = [...newJob.responsibilities];

    if (index !== -1) {
      array.splice(index, 1);
      setNewJob((prev) => ({
        ...prev,
        responsibilities: array,
      }));
    }
  };

  const handleAddBenefits = () => {
    setNewJob((prev) => ({
      ...prev,
      benefits: [...newJob.benefits, benefitsInput],
    }));
    setBenefitsInput('');
  };

  const handleChangeBenefits = (event) => {
    const { value } = event.target;
    setBenefitsInput(value);
  };

  const handleRemoveBenefits = (index) => {
    const array = [...newJob.benefits];
    if (index !== -1) {
      array.splice(index, 1);
      setNewJob((prev) => ({
        ...prev,
        benefits: array,
      }));
    }
  };

  const handleJobChange = (event) => {
    const { id, checked } = event.target;
    setNewJob((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleChangeMustEducation = value => {
    setNewJob((prev) => ({
      ...prev,
      mustHave_education: value,
    }))
  };

  const handleChangeCertification = event => {
    const { value } = event.target;
    setNewJob((prev) => ({
      ...prev,
      mustHave_specialistCertifications: value,
    }))
  };

  const handleChangeMustExperience = value => {
    setNewJob((prev) => ({
      ...prev,
      mustHave_experienceTime: value,
    }))
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Form.Item label="Part Time work available (*)">
              <Space size={8}>
                <Switch
                  defaultChecked
                  onChange={handleParttimeCheckbox}
                  checked={newJob.work_option_part_time}
                />
                <Text>{newJob.work_option_part_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Full Time work available (*)">
              <Space size={8}>
                <Switch
                  defaultChecked
                  onChange={handleFulltimeCheckbox}
                  checked={newJob.work_option_full_time}
                />
                <Text>{newJob.work_option_full_time ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Casual work available (*)">
              <Space size={8}>
                <Switch
                  defaultChecked
                  onChange={handleCasualCheckbox}
                  checked={newJob.work_option_casual}
                />
                <Text>{newJob.work_option_casual ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>
            <Form.Item label="Work from home option available (*)">
              <Space size={8}>
                <Switch
                  defaultChecked
                  onChange={handleRemoteWorkCheckbox}
                  checked={newJob.remote_work}
                />
                <Text>{newJob.remote_work ? 'Yes' : 'No'}</Text>
              </Space>
            </Form.Item>

            <Form.Item label="Salary range (*)">
              <Slider
                marks={marks}
                step={5000}
                range
                max={200000}
                defaultValue={[0, 0]}
                value={[newJob?.salary_low, newJob?.salary_high]}
                onChange={handleChangeSalary}
              />
              {
                fieldStatus.salary === 'error' ? (
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    Job Salary Range is required
                  </div>
                ) : ''
              }
            </Form.Item>
            <Form.Item label="Salary currency (*)">
              <Radio.Group
                options={[{ label: 'AUD', value: 'AUD' }, { label: 'USD', value: 'USD' }, { label: 'GBP', value: 'GBP' }]}
                value={newJob.salary_currency}
                optionType="button"
                buttonStyle="solid"
                onChange={handleChangeSalaryCurrency}
              />
              {
                fieldStatus.salary_currency === 'error' ? (
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    Job Salary Currency is required
                  </div>
                ) : ''
              }
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Tooltip
              placement="top"
              title="For each key task, click add and then insert the next key task. The tasks will appear as a list in the advertisement."
            >
              <Form.Item label="Job Tasks">
                <Space
                  direction="horizontal"
                  size={10}
                  className="job_tasks_container"
                >
                  <Input
                    id="job_tasks"
                    value={jobTasksInput}
                    onChange={handleChangeJobTasks}
                  />
                  <Text
                    className="task_add_button"
                    onClick={handleAddJobTasks}
                    style={
                      jobTasksInput.length > 0
                        ? {
                          pointerEvents: 'all',
                        }
                        : {
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }
                    }
                  >
                    Add
                  </Text>
                </Space>
                {newJob.jobTasks.map((item, index) => (
                  <Space key={index} style={{ width: '100%' }} size={10}>
                    <Text>{item}</Text>
                    <Text
                      className="task_add_button"
                      onClick={() => handleRemoveJobTasks(index)}
                    >
                      Remove
                    </Text>
                  </Space>
                ))}
              </Form.Item>
            </Tooltip>
            <Tooltip
              placement="top"
              title="For each key responsibility , click add and then insert the next key responsibility. The responsibilities will appear as a list in the advertisement."
            >
              <Form.Item label="Job Responsibilities">
                <Space
                  direction="horizontal"
                  size={10}
                  className="job_tasks_container"
                >
                  <Input
                    id="job_responsibilities"
                    value={responsibilitiesInput}
                    onChange={handleChangeResponsibilities}
                  />
                  <Text
                    className="task_add_button"
                    onClick={handleAddResponsibilities}
                    style={
                      responsibilitiesInput.length > 0
                        ? {
                          pointerEvents: 'all',
                        }
                        : {
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }
                    }
                  >
                    Add
                  </Text>
                </Space>
                {newJob.responsibilities.map((item, index) => (
                  <Space key={index} style={{ width: '100%' }} size={10}>
                    <Text>{item}</Text>
                    <Text
                      className="task_add_button"
                      onClick={() => handleRemoveResponsibilities(index)}
                    >
                      Remove
                    </Text>
                  </Space>
                ))}
              </Form.Item>
            </Tooltip>

            <Tooltip
              placement="top"
              title="For each key benefit, click add and then insert the next key benefit. The benefits will appear as a list in the advertisement."
            >
              <Form.Item label="Job Benefits">
                <Space
                  direction="horizontal"
                  size={10}
                  className="job_tasks_container"
                >
                  <Input
                    id="job_benefits"
                    value={benefitsInput}
                    onChange={handleChangeBenefits}
                  />
                  <Text
                    className="task_add_button"
                    onClick={handleAddBenefits}
                    style={
                      benefitsInput.length > 0
                        ? {
                          pointerEvents: 'all',
                        }
                        : {
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }
                    }
                  >
                    Add
                  </Text>
                </Space>
                {newJob.benefits.map((item, index) => (
                  <Space key={index} style={{ width: '100%' }} size={10}>
                    <Text>{item}</Text>
                    <Text
                      className="task_add_button"
                      onClick={() => handleRemoveBenefits(index)}
                    >
                      Remove
                    </Text>
                  </Space>
                ))}
              </Form.Item>
            </Tooltip>

            <Tooltip
              placement="top"
              title="If a jobseeker does not have these skills, you instruct the site to decline their application"
            >
              <Title level={4}>The jobseeker must have</Title>
            </Tooltip>

            <Tooltip
              placement="top"
              title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
            >
              <Form.Item label="Select education">
                <Select
                  id='mustHave_education'
                  value={newJob.mustHave_education}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option?.children?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  placeholder="Select Education"
                  onChange={handleChangeMustEducation}
                >
                  {
                    educationList.map(item => (
                      <Option value={item.id}>{item.fileBy}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Tooltip>
            <Tooltip
              placement="top"
              title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
            >
              <Form.Item label="Select the experience time">
                <Select
                  id='mustHave_experienceTime'
                  value={newJob.mustHave_experienceTime}
                  showSearch
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option?.children?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  placeholder="Select Experience Time"
                  onChange={handleChangeMustExperience}
                >
                  {
                    experienceList.map(item => (
                      <Option value={item.id}>{item.fileBy}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Tooltip>

            <Form.Item>
              <Row span={24} gutter={[16, 16]}>
                <Col span={8}>
                  <Tooltip
                    placement="top"
                    title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
                  >
                    <Checkbox
                      id='mustHave_rightToWork'
                      checked={newJob.mustHave_rightToWork}
                      onChange={handleJobChange}
                    >
                      Right To Work
                    </Checkbox>
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Tooltip
                    placement="top"
                    title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
                  >
                    <Checkbox
                      id='mustHave_driversLicence'
                      checked={newJob.mustHave_driversLicence}
                      onChange={handleJobChange}
                    >
                      Drivers Licence
                    </Checkbox>
                  </Tooltip>
                </Col>
              </Row>
              <Row span={24} gutter={[16, 16]}>
                <Col span={8}>
                  <Tooltip
                    placement="top"
                    title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
                  >
                    <Checkbox
                      id='mustHave_cleanCriminalHistory'
                      checked={newJob.mustHave_cleanCriminalHistory}
                      onChange={handleJobChange}
                    >
                      Clean Criminal History
                    </Checkbox>
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Tooltip
                    placement="top"
                    title='Leave blank if you do not want to pre-screen any jobseeker based on your "must haves". All applications will be sent to your inbox.'
                  >
                    <Checkbox
                      id='mustHave_notBankrupt'
                      checked={newJob.mustHave_notBankrupt}
                      onChange={handleJobChange}
                    >
                      Clear bankruptcy check
                    </Checkbox>
                  </Tooltip>
                </Col>
              </Row>
            </Form.Item>

            <Tooltip
              placement="top"
              title="For each key task, click add and then insert the next key task. The tasks will appear as a list in the advertisement."
            >
              <Form.Item label="Specialist Certifications">
                <Space
                  direction="horizontal"
                  size={10}
                  className="job_tasks_container"
                >
                  <Input
                    id="specialist_certification"
                    value={newJob.mustHave_specialistCertifications}
                    onChange={handleChangeCertification}
                  />
                </Space>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default NewJobWizard02Container;
