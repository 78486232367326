import { jobClassificationsType } from '../type';
import { getListAPI, getJobClassificationAPI } from '../../services/job_classifications';

/* Define New action  */
const getJobClassifications = () => {
  return (dispatch) => {
    return getListAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: jobClassificationsType.GET_JOB_CLASSIFICATIONS,
            job_classifications: data.data
          });
        } // TODO Error Action Calling
				
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

const getJobClassification = (id) => {
  return getJobClassificationAPI(id)
    .then((data) => {
      if (data) {
        return data.data
      } // TODO Error Action Calling
			
    })
    .catch(error => {
      // TODO Error Action Calling
    })
}

export {
  getJobClassifications,
  getJobClassification
}
