import React, {
  useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import {
  Space, Row, Col, Typography, Button
} from 'antd';
import { getBalancelist } from '../../../../../redux/actions/payment';
import { getAdvertisementProductsAPI } from '../../../../../services/jobs';
import PurchaseModal from './PurchaseModal';

const { Text } = Typography;

function CreateJobConfirm({ job, getBalanceListHandler, user, balanceList, setNewJob }) {
  const [productsList, setProductsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setNewJob((prev) => ({
      ...prev,
      status_id: 2,
    }));
  }, []);

  useEffect(() => {
    getAdvertisementProductsAPI()
      .then((data) => {
        setProductsList(data.data);
      })
  }, []);

  useEffect(() => {
    getBalanceListHandler(user.advertiser_id)
  }, []);

  const product = useMemo(() => {
    return productsList.find(item => item.id === job.job_type);
  }, [productsList, job]);

  const balance = useMemo(() => {
    if (!product) {
      return null;
    }
    return balanceList.find(item => item.type === product.advert_name);
  }, [product, balanceList]);

  const onPurchase = () => {
    getBalanceListHandler(user.id);
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <Space direction="vertical" size={15}>
            <Space direction="vertical" size={5}>
              <Text style={{ fontWeight: 'bold' }}>Please confirm the advertisement details below</Text>
            </Space>
            <Space direction="vertical" size={5}>
              <div
                id="advertisement_text"
                dangerouslySetInnerHTML={{
                  __html: job.advertisement_text,
                }}
              />
            </Space>
          </Space>
        </Col>
        <Col span={6}>
          <div style={{ marginBottom: 10 }}>
            <Text style={{ fontWeight: 'bold' }}>Available Credits:</Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Text>{product?.advert_name}:</Text>
            <Text>{balance?.balance || 0} credits</Text>
          </div>
          {
            (!balance?.balance || balance?.balance === 0) && (
              <Button type='primary' onClick={() => setIsOpen(true)}>Purchase credits</Button>
            )
          }
        </Col>
      </Row>
      <PurchaseModal
        isOpen={isOpen}
        jobType={job.job_type}
        user={user}
        onSubmit={onPurchase}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    balanceList: state.payment.balanceList,
  };
};

const mapDispatchToProps = {
  getBalanceListHandler: getBalancelist,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobConfirm);
