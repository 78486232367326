import React, {
  useEffect, useState, useMemo
} from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Table,
  Spin,
  Row,
  Col,
  Button,
  Typography,
  Checkbox,
  Slider
} from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import listHOC from '../../../common/listHOC';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { getApplicantProfileList, getHeadhuntingList } from '../../../../redux/actions/applicant_profiles';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import useDebounce from '../../../../hooks/useDebounce';

const { Text } = Typography;
const HeadHuntingListPageComponent = (props) => {
  const {
    searchApplicantProfiles, applicantProfileList, countries, noAction, getApplicantProfileListHandler
  } = props;

  const [loading, setLoading] = useState(false);

  const { location } = useHistory();
  const history = useHistory();
  const [applicantProfiles, setApplicantProfileList] = useState([]);
  const [pjsPage, setPjsPage] = useState(1);
  const [sliderValue, setSliderValue] = useState([0, 200000]);

  const marks = useMemo(() => {
    const result = {};
    for (let i = 0; i <= 40; i ++) {
      result[i * 5000] = i % 5 === 0 ? `${5 * i}${i > 0 ? ',000' : ''}` : '';
    }

    return result;
  }, []);
  const [searchParams, setSearchParams] = useState({
    userinput_country: '',
    userinput_region: '',
    userinput_target_salary_min: 0,
    userinput_target_salary_max: 200000,
  });
  const debounceRegion = useDebounce(searchParams.userinput_region, 300);

  useEffect(() => {
    const params = queryString.parse(location.search);

    const searchParams = {
      userinput_country: params?.country || '',
      userinput_region: params?.region || '',
      userinput_target_salary_min: params?.target_salary_min || 0,
      userinput_target_salary_max: params?.target_salary_max || 200000,
    };
    setSearchParams(searchParams);
    handlerSearchApplicantProfiles(searchParams);
  }, []);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setSearchParams((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeCountry = (value) => {
    if (!value) {
      setSearchParams((prev) => ({
        ...prev,
        userinput_country: '',
      }))
      getApplicantProfileListHandler();
    } else {

      setSearchParams((prev) => ({
        ...prev,
        userinput_country: countryName(value),
      }));
    }
  };

  const handlerSearchApplicantProfiles = async (params) => {
    setLoading(true);

    await searchApplicantProfiles(params).then(
      res => {
        console.log('payload:', res.applicantProfileList)
        console.log('payload length:', res.applicantProfileList.length)

        if (res.applicantProfileList.length === 0) {
          console.log('No data returns, run reset')
          onReset();
        }
      }
    );

    setPjsPage(1);
    setLoading(false);
  };

  // const countryIOS = (countryName) =>
  //   countries.length
  //     ? countries.find((item) => item.full_name === countryName)?.iso_alpha_2
  //     : 'au';
  const countryName = (countryId) =>
    countries.length
      ? countries.find((item) => item.id === countryId)?.full_name
      : '';
  const getCountryId = (name) =>
    countries.length
      ? countries.find((item) => item.full_name === name)?.id
      : '';

  useEffect(() => {
    history.push(`/headhunting/list?country=${searchParams.userinput_country}&region=${searchParams.userinput_region}&target_salary_min=${searchParams.userinput_target_salary_min}&target_salary_max=${searchParams.userinput_target_salary_max}`);
  }, [history, searchParams]);

  const onCheckboxChange = (e) => {
    if (e.target.checked){
      const profileHasPitchVideo = applicantProfiles.filter(element => isNaN(element.pitch_video_file_id) && (typeof element.pitch_video_file_id !== 'undefined'))
      setApplicantProfileList(profileHasPitchVideo);
    } else {
      onReset();
    }
  };

  const onSliderChange = (sliderValue) => {
    setSliderValue([sliderValue[0], sliderValue[1]])
    setSearchParams((prev) => ({
      ...prev,
      userinput_target_salary_min: sliderValue[0],
      userinput_target_salary_max: sliderValue[1],
    }));
  };

  const onReset = () => {
    const searchParams = {
      userinput_country: '',
      userinput_region: '',
      userinput_target_salary_min: 0,
      userinput_target_salary_max: 200000,
    };
    setSliderValue([0, 200000])
    setSearchParams(searchParams);
    getApplicantProfileListHandler();
  };

  useEffect(() => {
    getApplicantProfileListHandler();
  }, []);

  useEffect(() => {
    if (applicantProfileList.length > 0) {
      setApplicantProfileList(applicantProfileList);
    }
  }, [applicantProfileList]);

  const getCountryName = (countryId) => {
    return countries?.find(
      (item) => item.id === countryId,
    )?.full_name;
  };

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'public_fileBy',
      render: (text, record) => {
        return text ? text : 'No profile name provided'
      }
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
    },
    {
      title: 'Suburb/Region',
      dataIndex: 'street_address_suburb',
    },
    {
      title: 'Country',
      dataIndex: 'street_address_country_id',
      render: (text, record) => {
        return text ? getCountryName(record.street_address_country_id) : ''
      }
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/headhunting/profile/${record.id}`}><EyeOutlined /></NavLink>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Form layout={'vertical'}>
        <Row span={24} gutter={[20]}>
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button onClick={onReset} type="primary">
              Show All
            </Button>
          </Col>
        </Row>
        <Row justify="start" span={24} style={{ marginBottom: 30 }} gutter={[24]}>
          <Col xs={24} md={6}>
            <Form.Item label={'Region'}>
              <Input
                id="userinput_region"
                placeholder="Enter Region"
                allowClear
                value={searchParams?.userinput_region ? searchParams.userinput_region : ''}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label={'Country'}>
              <CountrySelectComponent
                id="street_address_country"
                style={{ width: '100%' }}
                value={getCountryId(searchParams?.userinput_country)}
                allowClear
                handleChange={handleChangeCountry}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label={' '}>
              <Checkbox onChange={onCheckboxChange}>Only show profiles with a pitch video</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label={' '}>
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                icon={<SearchOutlined />}
                onClick={() => handlerSearchApplicantProfiles(searchParams)}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} gutter={[24,0]}>
          <Col xs={24}>
            <Form.Item
              label="Salary range"
              style={{ marginBottom: 0 }}
            >
              <Slider
                marks={marks}
                step={5000}
                range
                max={200000}
                defaultValue={[50000, 200000]}
                value={sliderValue}
                onChange={onSliderChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <br/>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={applicantProfiles}
            rowKey="id"
          />
        </Spin>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    applicantProfileList: state.applicant_profiles.applicantProfileList,
    countries: state.countries
  }
};

const mapDispatchToProps = ({
  getApplicantProfileListHandler: getApplicantProfileList,
  searchApplicantProfiles: getHeadhuntingList
});

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(HeadHuntingListPageComponent));
