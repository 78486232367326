import { applicantProfileType } from '../type';

const initialState = {
  applicantProfileList: []
};

function applicant_profiles(state = initialState, action) {
  switch (action.type) {
    case applicantProfileType.GET_APPLICANTPROFILES:
      return {
        ...state,
        ...action.applicantProfile
      };

    case applicantProfileType.GET_APPLICANTPROFILES_LIST:
      return {
        ...state,
        applicantProfileList: action.applicantProfileList
      }
    default:
      return state;
  }
}

export default applicant_profiles;
