import React from 'react';

import './welcome.scss';

const Welcome = () => {
  return (
    <div className="welcome-message">
        Welcome
    </div>
  );
};

export default React.memo(Welcome);