import {
  APPLICANTS_START_NEW_APPLICANT_CREATION,
  APPLICANTS_UPDATE_NEW_APPLICANT_CREATION
} from '../actions/applicants';

const initialState = {
  new_applicant_data: null,
  new_applicant_submitted: false,
  new_applicant_created: false
};

function applicants(state = initialState, action) {
  switch (action.type) {
    case APPLICANTS_START_NEW_APPLICANT_CREATION:
      return {
        ...state, // staff_detail: action.staff_detail,
        new_applicant_data: {
          first_name: '',
          last_name: '',
          email: '',
        },
        new_applicant_submitted: false,
        new_applicant_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case APPLICANTS_UPDATE_NEW_APPLICANT_CREATION:
      return {
        ...state,
        new_applicant_data: {
          ...state.new_applicant_data,
          [action.value.key]: action.value.value
        },
      };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default applicants;
