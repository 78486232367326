import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Layout, Spin, Button } from 'antd';
import { confirmEmailAPI } from '../../services/auth';
import queryString from 'query-string';
import {NavLink} from 'react-router-dom';

const { Title, Text } = Typography;
const { Content } = Layout;

function ConfirmEmailAddress(props) {
  const { location } = props;
  const params = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (params.t) {
      confirmEmailAPI({ token: params.t }).then(data => {
        setLoading(false);
        setResult(true);
      }).catch(error => {
        setLoading(false);
        setResult(false);
      });
    } else {
      setLoading(false);
      setResult(false);
    }

  }, []);
  return (
    <Content>
      {
        loading ?
          <Row style={{ justifyContent: 'center', height: '100vh', display: 'flex', alignItems: 'center' }}>
            <Spin tip="Loading..." size='large'/>
          </Row> :
          <Row>
            <Col span={24} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 100 }}>
              <img
                src={result ? require('../../assets/email_verification.png') : require('../../assets/email_verification_failed.png')}
                alt=''
                target='_blank'
                style={{ paddingTop: 16, marginBottom: 16, maxWidth: 200 }}
              />
              <Title level={2}>{result ? 'Email Verified' : 'Email Verification Failed!'}</Title>
              <Text>{ result ? 'Your email address successfully verified!' : 'The code is incorrect!'}</Text>
              <Button style={{ marginTop: 20 }}>
                <NavLink to='/login'>
                Go to the website</NavLink>
              </Button>
            </Col>
          </Row>
      }

    </Content>
  )
}

export default ConfirmEmailAddress;