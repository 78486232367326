import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Typography, Table } from 'antd';
import { getDocumentById } from '../../../../../services/application_documents';
const { Text } = Typography;

function ViewApplicationTap(props) {
  const { application } = props;
  const {
    application_date_time,
    viewed_by_advertiser_datetime,
    viewed_by_advertiser_id,
  } = application;
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (application.id) {
      getDocumentById(application.id).then((data) => {
        if (data.data) {
          setDocuments(data.data);
        }
      });
    }
  }, [application]);

  const columns = [
    {
      title: 'Category',
      key: 'category',
      width: '33%',
      render: (text, record) => (
        <span>{`${record.document_id?.documentCategorySub?.fileBy}`}</span>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      width: '33%',
      render: (text, record) => (
        <span>{`${record.document_id?.documentTitle}`}</span>
      ),
    },
    {
      title: 'Link',
      key: 'link',
      width: '33%',
      render: (text, record) => (
        <a
          href={JSON.parse(record.document_id?.fileId)?.path}
          download
          target="_blank"
        >
          {JSON.parse(record.document_id?.fileId)?.name || '-'}
        </a>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Date</Text>
            <Text>{application_date_time}</Text>
          </Space>

          <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
              Viewed By Advertiser Id
            </Text>
            <Text>{viewed_by_advertiser_id}</Text>
          </Space>

          <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
              Viewed By Advertiser Date
            </Text>
            <Text>{viewed_by_advertiser_datetime}</Text>
          </Space>

          <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
              Documents Attached
            </Text>
          </Space>
        </Space>
        <Table
          columns={columns}
          dataSource={documents}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  );
}

export default ViewApplicationTap;
