import React from 'react';
import { Card, PageHeader, Collapse, Space } from 'antd';
const { Panel } = Collapse;

const panelContent = {
  'How do I advertise on PlatinumJobSeeker™?': [
    '1.	Make sure you have confirmed your account by clicking the link in the email we sent you when you registered.',
    '2. Make sure you have created a Workplace Profile.',
    '3.	Under the "Jobs" menu click "Create Job".',
    '4.	The site will guide you through the process of creating a job.',
    '5.	Add information about your job, select a package, and your job will be created.',
  ],
  // 'How much does it cost to advertise on PlatinumJobSeeker?': [
  //   "Advertising costs are in our <a href='https://www.betterteam.com/seek#pricing'>rate chart</a>. ",
  //   "What you spend depends on your budget and the results you're looking for. If you've got a job that's critical, time sensitive and hard to fill, for example, you may want to spend more.",
  //   'Your ad will run until the run time expires, or if you have had success with your jobseeker search and the right candidate has been selected before the end of the run time, advertisers can delete the ad - ',
  // ],
  'How long do job ads stay on PlatinumJobSeeker™?': [
    'Platinum, Platinum Plus and Platinum Premium ads run for 30, 60 or 90 consecutive days. The ruin time can be chosen by the advertiser.',
  ],
  'Is a PlatinumJobSeeker™ ad worth paying for?': [
    'While there are many places to advertise jobs free these days, it may be worth it for you to run a paid PlatinumJobSeeker™ ad, especially if you know a lot of your potential top jobseekers search for jobs there.',
  ],
  'Will PlatinumJobSeeker™ advertise my jobs on other boards too?': [
    "There are many job boards that aggregate jobs from around the internet, including from other job boards. But there's nothing that explicitly says that if you post a job on PlatinumJobSeeker™ it will be picked up anywhere else. Wherever possible, PlatinumJobSeeker™ will force the “other” advertising site to acknowledge PlatinumJobSeeker™ as the source of the advertisement, and a click on any “apply” or “apply now” button for a Platinum Tick™™ job will redirect the inquiry back to this site.",
  ],
  'Can I advertise on PlatinumJobSeeker™ for free': [
    'PlatinumJobSeeker™ may, on a case by case basis and from time to time offer limited free trials, coupons, or promos that offer the use of PlatinumJobSeeker™ website services free to certain long term and high volume ad placement advertisers.',
  ],
  'How to advertise on PlatinumJobSeeker™ cost-effectively?': [
    '1.	Try a standard Platinum ad job post without any of the extra cost add-ons.',
    '2.	Consider upgrading to a Platinum Plus or Platinum Premium job ad.',
    'A poorly written job ad is not usually cost-effective. See the PlatinumJobSeeker™   advertisement template here for tips and hints.',
  ],
};
const PlacingAPlatinumTickTMAdPage = () => {
  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360,
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16,
        }}
        title="Placing a Platinum Tick™ Ad"
      />
      <Card style={{ minHeight: 360 }}>
        <div style={{ paddingTop: 24 }}>
          <Collapse defaultActiveKey={['0']} onChange={() => {}}>
            {Object.keys(panelContent).map((item, index) => (
              <Panel
                header={<span style={{ fontWeight: 600 }}>{item}</span>}
                key={index}
              >
                <Space size={10} direction="vertical">
                  {panelContent[item].map((content) => (
                    <div
                      key={content}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  ))}
                </Space>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Card>
    </div>
  );
};

export default PlacingAPlatinumTickTMAdPage;
