
import React from 'react';
import withStyles from 'react-jss'
import { Typography, Row, Col } from 'antd';
import JobListPageComponent from '../jobs/JobListPageComponent';
import WorkPlaceProfileListComponent from '../workplace/WorkPlaceProfileListComponent';
import ApplicationListPageComponent from '../applications/ApplicationListPageComponent';

const { Title } = Typography;

const styles = {
  root: {
    minHeight: 320,
  },
  wrapper: {
    maxHeight: 500,
    overflow: 'auto',
    marginBottom: 30,
    marginTop: 15,
  },
};

function DashboardPageComponent({ classes }) {
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Title level={4}>Current Jobs</Title>
        <div className={classes.wrapper}>
          <JobListPageComponent />
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Title level={4}>Workplace Profiles</Title>
            <div className={classes.wrapper}>
              <WorkPlaceProfileListComponent noAction />
            </div>
          </Col>
          <Col span={12}>
            <Title level={4}>Current Applications</Title>
            <div className={classes.wrapper}>
              <ApplicationListPageComponent />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(DashboardPageComponent);
