import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import withStyles from 'react-jss';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MyAccount from './account_view/MyAccount';
import BillingDetails from './account_view/BillingDetails';
import BillingHistory from './account_view/BillingHistory';
import PurchaseAdverts from './account_view/PurchaseAdverts';
import { getBalancelist, getBillingHistoryList } from '../../../../redux/actions/payment';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

const SubscriptionViewPageComponent = (props) => {
  const { location, getBalanceListHandler, user, getBillingHistoryListHandler } = props;
  const [tabKey, setTabKey] = useState('my_account');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (tab) {
      if (tab === 'my_account')
        loadingBalanceData();
      else if (tab === 'billing_history')
        loadingBillingHistoryData();
      setTabKey(tab);
    }
  }, [location.search])
  useEffect(() => {
    loadingBalanceData();
    loadingBillingHistoryData();
  }, [])

  const loadingBalanceData = () => {
    setLoading(true);
    getBalanceListHandler(user.advertiser_id).then(data => {
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  };

  const loadingBillingHistoryData = () => {
    setLoading(true);
    getBillingHistoryListHandler(user.advertiser_id).then(data => {
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  };

  const history = useHistory();
  const handleTabClick = e => {
    if (e === 'my_account')
      loadingBalanceData();
    else if (e === 'billing_history')
      loadingBillingHistoryData();
    history.push(`/settings/account/${1}?tab=${e}`);
  };

  return (
    <React.Fragment>
      <Tabs onChange={callback} onTabClick={handleTabClick} activeKey={tabKey}>
        <TabPane tab="My Account" key="my_account">
          <Spin spinning={loading}>
            <MyAccount />
          </Spin>
        </TabPane>
        {/* <TabPane tab="Billing Details" key="billing_details">
          <BillingDetails />
        </TabPane> */}
        <TabPane tab="Billing History" key="billing_history">
          <Spin spinning={loading}>
            <BillingHistory />
          </Spin>
        </TabPane>
        <TabPane tab="Purchase Adverts" key="purchase_adverts">
          <PurchaseAdverts />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};


const mapStateToProps = state => {
  return {
    user: state.auth.currentUser
  };
};

const mapDispatchToProps = ({
  getBalanceListHandler: getBalancelist,
  getBillingHistoryListHandler: getBillingHistoryList
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SubscriptionViewPageComponent)));