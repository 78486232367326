import React from 'react';
import { Space } from 'antd';

function JobDetailTap(props) {
  const { jobData } = props;
  const { advertisement_text } = jobData;
  return (
    <Space direction='vertical' size={0}>
      <div dangerouslySetInnerHTML={{__html: advertisement_text }} />
    </Space>
  );
}

export default JobDetailTap;
