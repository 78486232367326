import { API } from 'aws-amplify';

// export const UPDATE_CURRENT_BRAND = "UPDATE_CURRENT_BRAND";
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_NEW_CLIENT = 'UPDATE_NEW_CLIENT';
export const DATA_CLIENT_STATUS = 'DATA_CLIENT_STATUS';
export const DATA_COUNTRIES = 'DATA_COUNTRIES';
// export const UPDATE_CURRENT_USER_ID = "UPDATE_CURRENT_USER_ID";
// export const UPDATE_CURRENT_USER_LOG_OUT = "UPDATE_CURRENT_USER_LOG_OUT";
// export const UPDATE_ECONOMIC_CALENDAR = "UPDATE_ECONOMIC_CALENDAR";

export function onLoginSuccess(user) {
  return async function (dispatch) {
    try {
      // await update_client_database(aws_cognito_id, {
      //   portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      // })
      // console.log(user);
      // const client_record = await get_current_client_details(user);
      const { advertiser_record, user_record, advertiser_access } = await get_current_client_details(user);
      // const client_status_list = await get_client_status_list()
      const countries_list = await get_countries();
      // const division_details = await get_current_division_details(client_record.division_id)
      console.log(advertiser_record)
      // console.log(division_details)
      if (advertiser_record) {
        dispatch(updateCurrentClientIntoStore(advertiser_record, user_record, advertiser_access));
      }
      if (countries_list) {
        dispatch(updateCountriesIntoStore(countries_list));
      }
      // if (client_status_list)
      //   dispatch(updateClientStatusListIntoStore(client_status_list));
    } catch (e) {
      // alert(e.message);
      console.log(e);
    }
  };
}

async function get_current_client_details(user) {
  console.log(user);
  if (user.username) {
    return API.get(
      'users',
      `/get/aws-cognito/${user.username}`
    )
      .then(response => {
        console.log(response.payload);
        // console.log(response.payload);
        if (!response.payload.user_advertiser) {
          console.error('Error: No matching authentication details.')
          // window.location.href = "/";
        }
        return {
          advertiser_record: response.payload.advertiser,
          user_record: response.payload.user_advertiser,
          advertiser_access: response.payload.advertiser_access
        };
      })
      .catch(error => {
        console.error(error);
        return API.get(
          'users',
          `/get/aws-cognito/${user.username}`
        )
          .then(response => {
            // console.log(response.payload);
            // console.log(response.payload);
            if (!response.payload.user_advertiser) {
              console.error('Error: No matching authentication details.')
              // window.location.href = "/";
            }
            return {
              advertiser_record: response.payload.advertiser,
              user_record: response.payload.user_advertiser,
              advertiser_access: response.payload.advertiser_access
            };
          })
          .catch(error => {
            console.log(error);
          })
      })
  } else if (user.email) {
    return API.get(
      'users',
      `/get-by-email/${user.email}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
  }
}
// async function get_client_status_list() {
//   return API.get(
//     "clients",
//     `/get-status-list`
//   )
//     .then(response => {
//       // console.log(response.payload);
//       return response.payload;
//     })
//     .catch(error => {
//       console.log(error);
//     })
// }
async function get_countries() {
  // console.log(user);
  return API.get(
    'countries',
    '/list'
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}


export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}
export function updateCurrentClientIntoStore(client_record, user_record, client_access) {
  return {
    type: UPDATE_CURRENT_USER,
    user_id: user_record.id,
    // user_id2: user_record.id,
    client_id: client_record.id,
    // aws_cognito_id: user_record.aws_cognito_id,
    current_client_record: client_record,
    current_user_record: user_record,
    client_access: client_access
    // brand: brand,
    // current_division: division_details
  };
}
export function updateClientStatusListIntoStore(data_client_status_list) {
  return {
    type: DATA_CLIENT_STATUS,
    data_client_status_list: data_client_status_list,
  };
}
